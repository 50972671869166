import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { IProjectConfig } from "../../store/selectedProject/actions";
import { store } from "../../store/store";
import {
  acquireTokenSilentAndSetLocal,
  getAccount,
  isAuthenticated,
} from "../auth";

const httpLink = new HttpLink({
  uri: (store.getState().selectedProject as IProjectConfig)?.graphql?.url,
});

const authLink = setContext(async (_, { headers }) => {
  if (!isAuthenticated()) await acquireTokenSilentAndSetLocal(getAccount());
  return {
    headers: {
      ...headers,
      Authorization: `Bearer azuread-oauth2 ${
        localStorage.getItem("idToken") || null
      }`,
    },
    // needs this in order to actually work, not sure what the purpose of having this and the httpLink
    uri: (store.getState().selectedProject as IProjectConfig)?.graphql?.url,
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
