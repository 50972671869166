import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const Hint: React.FC<PropsType> = (props) => {
  const { children } = props;
  return (
    <Typography variant="body1" color="textPrimary">
      {children}
    </Typography>
  );
};

export default withStyles(styles)(Hint);
