import React, { useEffect } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { MapEvent } from "react-map-gl";
import { CreateOption } from "./CreateEntry";

interface IProps {
  contextMenuEvent: MapEvent;
  setIsOpenNewEntry: (isOpenNewEntry: boolean) => void;
  setCreateMode: (flag: CreateOption) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    contextMenuTitle: {
      fontWeight: "bold",
      pointerEvents: "none",
    },
  });

type ClassKey = "root" | "contextMenuTitle";
type PropsType = IProps & WithStyles<ClassKey>;

const ContextMenu: React.FC<PropsType> = (props) => {
  const { contextMenuEvent, setIsOpenNewEntry, setCreateMode, classes } = props;

  const [contextMenuPosition, setContextMenuPosition] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  useEffect(() => {
    setContextMenuPosition(
      contextMenuPosition === null
        ? {
            mouseX: contextMenuEvent.center.x - 2,
            mouseY: contextMenuEvent.center.y - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null,
    );
  }, [contextMenuEvent]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeContext = (open) => {
    setContextMenuPosition(null);
    setIsOpenNewEntry(open);
  };

  const handleUseLocation = () => {
    changeContext(true);
    setCreateMode(CreateOption.Location);
  };

  // const handleUseObjectPolygon = () => {
  //   // TODO: Use existing object is not priority
  //   setCreateMode(createOption.None);
  // };

  const handleDrawOnMap = () => {
    changeContext(true);
    setCreateMode(CreateOption.Draw);
  };

  return (
    <>
      <Menu
        id="basic-menu"
        open={contextMenuPosition !== null}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenuPosition !== null
            ? {
                top: contextMenuPosition.mouseY,
                left: contextMenuPosition.mouseX,
              }
            : undefined
        }
        onClose={() => changeContext(false)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem className={classes.contextMenuTitle}>
          Create new Object
        </MenuItem>
        <MenuItem onClick={handleUseLocation}>Use location</MenuItem>
        {/* <MenuItem onClick={handleUseObjectPolygon}>Use object polygon</MenuItem> */}
        <MenuItem onClick={handleDrawOnMap}>Draw on map</MenuItem>
      </Menu>
    </>
  );
};

export default withStyles(styles)(ContextMenu);
