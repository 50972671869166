import React, { useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { useMutation, MutationHookOptions } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import {
  UPDATE_PARAMETERVALUES,
  CREATE_PARAMETERVALUES,
} from "../../../../data/parameterQueries";
import i18n from "../../../../i18n";
import { EModalTypes } from "../../../../store/interfaces";
import { modalActions } from "../../../../store/modal";
import { RootState } from "../../../../store";

interface IProps {
  isEditable: boolean;
  setIsEditToggledOn: (isEditToggledOn: boolean) => void;
  isEditToggledOn: boolean;
  allParameters: any[];
  setIsReset: (isReset: boolean) => void;
  isSaved: boolean;
  setIsSaved: (isSaved: boolean) => void;
  setSelectAll: (flag: boolean) => void;
  sources: any[];
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      flex: 1,
      maxHeight: 35,
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
      justifyContent: "flex-end",
      "& > * + *": {
        marginLeft: theme.spacing(),
      },
      "& > button": {
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.light,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        "&:disabled": {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const ControlBar: React.FC<PropsType> = (props) => {
  const {
    classes,
    isEditable,
    setIsEditToggledOn,
    setIsReset,
    setIsSaved,
    isEditToggledOn,
    allParameters,
  } = props;

  const dispatch = useDispatch();
  const selectedSources = useSelector(
    (state: RootState) => state.selectedSources,
  );
  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state: any) => state.user);

  const options: MutationHookOptions<any, Record<string, any>> = {
    errorPolicy: "ignore",
  };

  const [updateParamVals, updatedParamVals] = useMutation(
    UPDATE_PARAMETERVALUES,
    options,
  );

  const [createParamVals, createdParamVals] = useMutation(
    CREATE_PARAMETERVALUES,
    options,
  );

  const [isFeedbackProcessed, setIsFeedbackProcessed] = useState<boolean>(true);

  const processPendingChanges = () => {
    if (user.isAdmin || user.hasWriteAccess) {
      allParameters.forEach((param) => {
        if (param.hasPendingChange) {
          // Erased enums are stored as `[""]` - causing an empty string to remain in an updated list

          let value = param.editParamValue;
          if (Array.isArray(value)) {
            let nonEmptyValues: any[] = [];
            value.forEach((item) => {
              if (item.length) nonEmptyValues.push(item);
            });
            value = nonEmptyValues;
          }

          if (param.parametervaluesId) {
            // Update previously assigned parameterValue
            updateParamVals({
              variables: {
                parametervaluesId: param.parametervaluesId,
                parametervaluesValue: JSON.stringify(value),
              },
            });
          } else {
            // Assign a parameter to object and source
            if (param.editParamValue) {
              // Only assign a parameter if a ParamValue was submitted
              // Flag/Qualifier don't mean anything without a ParamValue
              createParamVals({
                variables: {
                  parametervaluesValue: JSON.stringify(value),
                  parametervaluesFlag: param.editFlag ? param.editFlag : 0,
                  parametervaluesQualifier: param.editQualifier
                    ? param.editQualifier
                    : 0,
                  parametersId: param.parametervaluesParameter.parametersId,
                  objectId: selectedObjects[0].objectId,
                  sourceId: selectedSources[0].sourcesId,
                },
              });
            }
          }
        }
      });
      setIsFeedbackProcessed(false);
    }
  };

  if (
    !isFeedbackProcessed &&
    (updatedParamVals?.data || createdParamVals?.data)
  ) {
    if (
      !(
        updatedParamVals?.data?.updateParameterValue?.errors ||
        createdParamVals?.data?.updateParameterValue?.errors
      ) &&
      !(updatedParamVals?.error || createdParamVals?.error)
    ) {
      enqueueSnackbar(i18n.t("success.paramValsSaved"), {
        variant: "success",
      });
      setIsSaved(true);
    } else {
      enqueueSnackbar(
        i18n.t("error.mutateGraphqlError", {
          error:
            updatedParamVals?.data?.updateParameterValue?.errors[0]
              ?.messages[0] ||
            createdParamVals?.data?.updateParameterValue?.errors[0]
              ?.messages[0],
        }),
        { variant: "error" },
      );
    }
    setIsFeedbackProcessed(true);
  }

  const numberOfPendingChanges = allParameters.filter(
    (p) => p?.hasPendingChange,
  ).length;

  const hasPendingChange = allParameters.some((p) => p.hasPendingChange);

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Switch
            data-testid="edittoggle"
            checked={isEditToggledOn}
            color="primary"
            disabled={!isEditable}
            onChange={() => {
              if (!isEditToggledOn) {
                if (selectedObjects.length > 1 || selectedSources.length > 1) {
                  dispatch(
                    modalActions.openModal(
                      EModalTypes.selectSingleSourceObject,
                      {
                        modalProps: {
                          setIsEditToggledOn: setIsEditToggledOn,
                          setSelectAll: props.setSelectAll,
                          currentSources: props.sources,
                        },
                      },
                    ),
                  );
                  return;
                }
              }

              setIsEditToggledOn(!isEditToggledOn);
            }}
          />
        }
        label={
          !isEditToggledOn ? i18n.t("enableEditing") : i18n.t("disableEditing")
        }
      />

      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          processPendingChanges();
        }}
        disabled={!hasPendingChange}
      >
        {`${i18n.t("saveChanges")}${
          !!numberOfPendingChanges ? ` (${numberOfPendingChanges})` : ``
        }`}
        {updatedParamVals.loading ||
          (createdParamVals.loading && <CircularProgress size={20} />)}
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setIsReset(true);
        }}
        disabled={!hasPendingChange}
      >
        {i18n.t("discardChanges")}
      </Button>
    </div>
  );
};

export default withStyles(styles)(ControlBar);
