import { IProjectConfig } from "../../store/selectedProject/actions";

export default {
  name: "tofas",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja2lnNXV2bWowOGlnMnBxamhjbWh0bXBsIn0.ltl6wWjVVnxbdhCqw9MtYw",
  },
  graphql: {
    url: "https://public.dwh-api.arup.com/tofas/graphql/",
  },
  coordinates: {
    latitude: 39.563,
    longitude: 34.53,
    zoom: 6,
  },
  map: {
    zoomTreshold: 15,
    layerSymbols: [
      {
        objectlayerIds: [6],
        symbol: "borehole",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [4, 5, 6],
        minZoom: 7,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 0,
  air: {
    enabled: false,
    url: "",
  },
} as IProjectConfig;
