import { Action, Reducer } from "redux";
import { IObject } from "../../data/types/typeStitches";

import { KnownAction } from "./actions";

export type TFilteredObjects = IObject[];

export const filteredObjectsReducer: Reducer<TFilteredObjects> = (
  state = [],
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "RESET_FILTERED_OBJECTS":
      return [];
    case "INSERT_FILTERED_OBJECTS":
      return [...state, ...action.filteredObjects];
    default:
      return state;
  }
};
