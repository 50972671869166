import { layerColours, getLayerColourByIndex } from "../../../common/colors";
import { octoTheme } from "../../../common/mui";

export const mapTheme: {
  fillColours: string[];
  outlineColours: string[];
  filter: { colour: string; labelColour: string };
  fixedStyles: any;
} = {
  fillColours: layerColours.map((c) => c.fill),
  outlineColours: layerColours.map((c) => c.outline),
  filter: {
    colour: octoTheme.palette.primary.main,
    labelColour: octoTheme.palette.primary.contrastText,
  },
  fixedStyles: {
    borehole: (layerIndex: number) => {
      return bboxObjectBorehole(layerIndex);
    },
    cpt: (layerIndex: number) => {
      return bboxObjectCpt(layerIndex);
    },
    radius: (layerIndex: number, radius: number) => {
      return activeObjectRadius(layerIndex, radius);
    },
    label: {
      fill: layerColours[7].outline,
      stroke: layerColours[7].outline,
    },
    nlto: {
      fill: octoTheme.palette.primary.main,
      stroke: octoTheme.palette.primary.contrastText,
    },
  },
};

export const bboxObjectFill = (layerIndex: number) => ({
  "fill-color": getLayerColourByIndex(layerIndex)?.fill,
  "fill-opacity": [
    "case",
    ["boolean", ["feature-state", "hover"], false],
    0.8,
    0.5,
  ],
});

export const bboxObjectLine = (layerIndex: number) => ({
  "line-color": getLayerColourByIndex(layerIndex).outline,
  "line-width": ["case", ["boolean", ["feature-state", "hover"], false], 5, 3],
});

export const bboxObjectCircle = (layerIndex: number) => ({
  "circle-color": getLayerColourByIndex(layerIndex).outline,
  "circle-radius": [
    "case",
    ["boolean", ["feature-state", "hover"], false],
    12,
    10,
  ],
});

export const bboxObjectBorehole = (layerIndex: number) => ({
  type: "symbol",
  paint: bboxObjectBoreholePaint(layerIndex),
  layout: bboxObjectBoreholeLayout(),
});

const bboxObjectBoreholePaint = (layerIndex: number) => ({
  "icon-color": getLayerColourByIndex(layerIndex).outline,
  "icon-halo-color": getLayerColourByIndex(layerIndex).outline,
  "icon-halo-width": [
    "case",
    ["boolean", ["feature-state", "hover"], false],
    1,
    0,
  ],
});

const bboxObjectBoreholeLayout = () => ({
  "icon-image": "borehole",
  "icon-size": 0.3,
  "icon-allow-overlap": true,
});

export const bboxObjectCpt = (layerIndex: number) => ({
  type: "symbol",
  paint: bboxObjectCptPaint(layerIndex),
  layout: bboxObjectCptLayout(),
});

const bboxObjectCptPaint = (layerIndex: number) => ({
  "icon-color": getLayerColourByIndex(layerIndex).outline,
  "icon-halo-color": getLayerColourByIndex(layerIndex).outline,
  "icon-halo-width": [
    "case",
    ["boolean", ["feature-state", "hover"], false],
    1,
    0,
  ],
});

const bboxObjectCptLayout = () => ({
  "icon-image": "cpt",
  "icon-size": 0.4,
  "icon-allow-overlap": true,
});

export const filterSymbolsLine = () => ({
  "line-color": mapTheme.filter.colour,
  "line-width": 6,
  "line-blur": 3,
});

export const filterSymbolsCircle = () => ({
  "circle-color": mapTheme.filter.colour,
  "circle-radius": 16,
  "circle-opacity": 0.8,
  "circle-blur": 0.5,
});

export const filterSymbolsCircleLabelLayout = (theme, label) => ({
  "text-field": label,
  "text-font": ["Roboto Black", "Arial Unicode MS Bold"],
  "text-size": theme.typography.button.fontSize,
});

export const filterSymbolsCluster = () => ({
  "circle-color": mapTheme.filter.colour,
  "circle-radius": ["step", ["get", "point_count"], 20, 10, 40, 30, 50],
  "circle-opacity": 0.8,
  "circle-blur": 0.5,
});

export const filterSymbolsClusterLabelLayout = (theme, label_field) => ({
  "text-field": `{${label_field}}`,
  "text-font": ["Roboto Black", "Arial Unicode MS Bold"],
  "text-size": theme.typography.button.fontSize,
});

export const filterSymbolsClusterLabel = () => ({
  "text-color": mapTheme.filter.labelColour,
});

export const activeFeaturesPolygonsFill = (
  layerNames: string[],
  groupOrderArr: number[],
) => ({
  "fill-color": [
    "to-color",
    [
      "at",
      ["index-of", ["get", "objectlayerName"], ["literal", layerNames]],
      ["literal", mapTheme.fillColours],
    ],
  ],
  "fill-opacity": [
    "match",
    [
      "at",
      ["index-of", ["get", "objectlayerName"], ["literal", layerNames]],
      ["literal", groupOrderArr],
    ],
    0,
    0.01,
    0.5,
  ],
});

export const activeFeaturesPolygonsLine = (
  layerNames: string[],
  groupOrderArr: number[],
) => ({
  "line-color": [
    "to-color",
    [
      "at",
      ["index-of", ["get", "objectlayerName"], ["literal", layerNames]],
      ["literal", mapTheme.outlineColours],
    ],
  ],
  "line-width": [
    "match",
    [
      "at",
      ["index-of", ["get", "objectlayerName"], ["literal", layerNames]],
      ["literal", groupOrderArr],
    ],
    0,
    5,
    1,
    3,
    1,
  ],
  "line-offset": [
    "match",
    [
      "at",
      ["index-of", ["get", "objectlayerName"], ["literal", layerNames]],
      ["literal", groupOrderArr],
    ],
    0,
    -3,
    0,
  ],
});

export const activeFeaturesPointsCircle = (layerNames: string[]) => ({
  "circle-color": [
    "to-color",
    [
      "at",
      ["index-of", ["get", "objectlayerName"], ["literal", layerNames]],
      ["literal", mapTheme.outlineColours],
    ],
  ],
});

export const childFill = (layerNames: string[]) => ({
  "fill-color": [
    "to-color",
    [
      "at",
      ["index-of", ["get", "objectlayerName"], ["literal", layerNames]],
      ["literal", mapTheme.fillColours],
    ],
  ],
  "fill-opacity": 0.01,
});

export const childLine = (layerNames: string[]) => ({
  "line-width": ["case", ["boolean", ["feature-state", "hover"], false], 2, 0],
  "line-color": [
    "to-color",
    [
      "at",
      ["index-of", ["get", "objectlayerName"], ["literal", layerNames]],
      ["literal", mapTheme.outlineColours],
    ],
  ],
});

export const activeObjectRadius = (layerIndex: number, radius: number) => ({
  type: "symbol",
  paint: activeObjectRadiusPaint(layerIndex),
  layout: activeObjectRadiusLayout(radius),
});

const activeObjectRadiusPaint = (layerIndex: number) => ({
  "icon-color": getLayerColourByIndex(layerIndex).outline,
  "icon-opacity": 0.3,
});

const activeObjectRadiusLayout = (radius: number) => ({
  "icon-image": "radius",
  "icon-size": radius,
  "icon-allow-overlap": true,
});

export const filterSymbolsFill = () => ({
  "fill-pattern": "hatch-rgb-23,76,87",
});
