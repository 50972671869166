import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      padding: theme.spacing(2),
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const ModalContent: React.FC<PropsType> = (props) => {
  const { classes, children } = props;
  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(ModalContent);
