import { EModalTypes, IModalProps } from "../interfaces";

interface ModalOptions {
  modalProps?: IModalProps;
}

export const modalActions = {
  openModal: (modalType: EModalTypes, options: ModalOptions = {}) => {
    const { modalProps = {} } = options;

    return {
      type: "OPEN_MODAL",
      payload: {
        modalType,
        modalProps,
      },
    };
  },
  modifyModalProps: (modalProps: IModalProps = {}) => {
    return {
      type: "MODIFY_MODAL_PROPS",
      modifiedProps: modalProps,
    };
  },
  closeModal: () => ({ type: "CLOSE_MODAL" }),
};
