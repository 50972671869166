import { ILayerGroup } from "../../data/types/typeStitches";
import { AppThunk } from "../interfaces";

export const layerGroupActions = {
  resetLayerGroups: (): AppThunk => (dispatch) => {
    dispatch({ type: "RESET_LAYER_GROUPS" });
  },
  insertLayerGroups: (layerGroups: ILayerGroup[]): AppThunk => (dispatch) => {
    dispatch({ type: "INSERT_LAYER_GROUPS", layerGroups });
  },
};
