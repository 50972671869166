export const createHatch = (rgb: number[], alpha: number = 255) => {
  const width = 8;
  const bytesPerPixel = 4; // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
  const data = new Uint8Array(width * width * bytesPerPixel);

  // Draw a diagonal line across the image by adding colour value to a single identified cell
  for (let x = 0; x < width; x++) {
    for (let y = 0; y < width; y++) {
      const offset = (y * width + x) * bytesPerPixel;
      if ([0, 36, 72, 108, 144, 180, 216, 252].includes(offset)) {
        data[offset + 0] = rgb[0];
        data[offset + 1] = rgb[1];
        data[offset + 2] = rgb[2];
        data[offset + 3] = alpha;
      }
    }
  }

  return { width: width, height: width, data: data };
};

export const createHatchFromImageId = (imageId: string) => {
  const prefix = "hatch-rgb-";
  if (imageId.indexOf(prefix) !== 0) return;

  const rgb = imageId.replace(prefix, "").split(",").map(Number);
  return createHatch(rgb);
};
