import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    "@global": {
      "*": {
        boxSizing: "border-box",
      },
      "html, body, #root": {
        height: "100%",
      },
      body: {
        margin: 0,
      },
    },
    root: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    notice: {
      width: "80%",
      margin: "auto",
    },
  });

type ClassKey = "@global" | "root" | "notice";
type PropsType = IProps & WithStyles<ClassKey>;

const Mobile: React.FC<PropsType> = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.notice}>
        <p>Octo is currently not supported on mobile devices.</p>
        <p>Please view this app in a modern desktop browser instead.</p>
      </div>
    </div>
  );
};

export default withStyles(styles)(Mobile);
