import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ILayerGroup } from "../../../../data/types/typeStitches";

import i18n from "../../../../i18n";
import { RootState } from "../../../../store";
import { visibleLayerIdsActions } from "../../../../store/visibleLayerIds";

import SelectFilter from "../SelectFilter";

interface IProps {
  selectedLayerGroups: ILayerGroup[];
  setLayerGroups: (layerGroups: any) => void;
  setSelectedLayerGroups: (selectedLayerGroups: any) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      border: "1px solid #bebebe",
      marginTop: theme.spacing(1),
      "&:nth-child(2)": {
        borderLeft: "none",
      },
    },
    label: {
      width: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#000000",
      borderRight: "1px solid #bebebe",
    },
    menu: { width: "50%" },
  });

type ClassKey = "root" | "label" | "menu";
type PropsType = IProps & WithStyles<ClassKey>;

const FeatureLayers: React.FC<PropsType> = (props) => {
  const {
    selectedLayerGroups,
    setSelectedLayerGroups,
    setLayerGroups,
    classes,
  } = props;

  const dispatch = useDispatch();
  const layerGroups = useSelector((state: RootState) => state.layerGroups);

  const handleSelectedItems = (layerGroup, items) => {
    const copy = { ...selectedLayerGroups };
    copy[layerGroup.objectlayergroupName] = items;
    setSelectedLayerGroups(copy);
  };

  useEffect(() => {
    if (layerGroups.length) {
      setSelectedLayerGroups(
        layerGroups.reduce(
          (o, layerGroup) => ({
            ...o,
            [layerGroup.objectlayergroupName]: [],
          }),
          {},
        ),
      );
      setLayerGroups([
        ...layerGroups.flatMap((lg) =>
          lg.objectlayers.map((ol) => ol.objectlayerId),
        ),
      ]);

      // Activate default layer
      handleSelectedItems(layerGroups[0], [layerGroups[0]?.objectlayers[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerGroups]);

  useEffect(() => {
    if (selectedLayerGroups) {
      let selectedLayerIds: any[] = [];
      for (const [, objectLayers] of Object.entries(selectedLayerGroups)) {
        if (objectLayers)
          selectedLayerIds.push(
            ...(objectLayers as any).map((layer) => layer.objectlayerId),
          );
      }
      dispatch(visibleLayerIdsActions.resetVisibleLayerIds());
      dispatch(visibleLayerIdsActions.insertVisibleLayerIds(selectedLayerIds));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayerGroups]);

  if (!layerGroups.length) return null;

  return (
    <>
      {layerGroups?.map((layerGroup, index) => (
        <div className={classes.root} key={index}>
          <Typography variant="h2" className={classes.label}>
            {i18n.t(layerGroup.objectlayergroupName.toLowerCase())}{" "}
          </Typography>
          <div className={classes.menu}>
            <SelectFilter
              key={layerGroup.objectlayergroupId}
              list={
                layerGroup.objectlayergroupIshierarchical
                  ? [layerGroup.objectlayers[0]]
                  : layerGroup.objectlayers
              }
              selectedItems={
                (selectedLayerGroups &&
                  selectedLayerGroups[layerGroup.objectlayergroupName]) ||
                []
              }
              setSelectedItems={(items) => {
                handleSelectedItems(layerGroup, items);
              }}
              gridColumn={`${(index + 1) % 2 === 0 ? "2" : "1"}`}
              disabledOption={false}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default withStyles(styles)(FeatureLayers);
