import { gql } from "@apollo/client";

export const GET_HIERARCHIC_TREE_3 = gql`
  query GET_HIERARCHIC_TREE_3($objectLongId: String, $objectlayerName: String) {
    allObjects(
      first: 1
      objectLayer_ObjectlayerName: $objectlayerName
      objectLongId: $objectLongId
      objectLayer_ObjectlayerSearch: true
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          objectId
          objectLongId
          objectName
          objectLayer {
            objectlayerName
            objectlayerId
          }
          objectParents(objectLayer_ObjectlayerSearch: true) {
            edges {
              cursor
              node {
                objectParents(objectLayer_ObjectlayerSearch: true) {
                  edges {
                    cursor
                    node {
                      objectId
                      objectLongId
                      objectName
                      objectGeom
                      objectSearchLongStr
                      objectLayer {
                        objectlayerName
                        objectlayerId
                      }
                      objectChildren(objectLayer_ObjectlayerSearch: true) {
                        edges {
                          cursor
                          node {
                            objectId
                            objectLongId
                            objectName
                            objectGeom
                            objectSearchLongStr
                            objectLayer {
                              objectlayerName
                              objectlayerId
                            }
                            objectChildren(
                              objectLayer_ObjectlayerSearch: true
                            ) {
                              edges {
                                cursor
                                node {
                                  objectId
                                  objectLongId
                                  objectName
                                  objectGeom
                                  objectSearchLongStr
                                  objectLayer {
                                    objectlayerName
                                    objectlayerId
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_HIERARCHIC_TREE_2 = gql`
  query GET_HIERARCHIC_TREE_2($objectLongId: String, $objectlayerName: String) {
    allObjects(
      first: 1
      objectLayer_ObjectlayerName: $objectlayerName
      objectLongId: $objectLongId
      objectLayer_ObjectlayerSearch: true
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          objectId
          objectLongId
          objectName
          objectSearchLongStr
          objectLayer {
            objectlayerName
            objectlayerId
          }
          objectParents(objectLayer_ObjectlayerSearch: true) {
            edges {
              cursor
              node {
                objectId
                objectLongId
                objectName
                objectGeom
                objectSearchLongStr
                objectLayer {
                  objectlayerName
                  objectlayerId
                }
                objectChildren(objectLayer_ObjectlayerSearch: true) {
                  edges {
                    cursor
                    node {
                      objectId
                      objectLongId
                      objectName
                      objectGeom
                      objectSearchLongStr
                      objectLayer {
                        objectlayerName
                        objectlayerId
                      }
                      objectChildren(objectLayer_ObjectlayerSearch: true) {
                        edges {
                          cursor
                          node {
                            objectId
                            objectLongId
                            objectName
                            objectGeom
                            objectSearchLongStr
                            objectLayer {
                              objectlayerName
                              objectlayerId
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_HIERARCHIC_TREE_ROOT = gql`
  query GET_HIERARCHIC_TREE_ROOT(
    $objectLongId: String
    $objectlayerName: String
  ) {
    allObjects(
      first: 1
      objectLayer_ObjectlayerName: $objectlayerName
      objectLongId: $objectLongId
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          objectId
          objectLongId
          objectName
          objectGeom
          objectSearchLongStr
          objectLayer {
            objectlayerName
            objectlayerId
          }
          objectChildren(objectLayer_ObjectlayerSearch: true) {
            edges {
              cursor
              node {
                objectId
                objectLongId
                objectName
                objectGeom
                objectSearchLongStr
                objectLayer {
                  objectlayerName
                  objectlayerId
                }
                objectChildren(objectLayer_ObjectlayerSearch: true) {
                  edges {
                    cursor
                    node {
                      objectId
                      objectLongId
                      objectName
                      objectGeom
                      objectSearchLongStr
                      objectLayer {
                        objectlayerName
                        objectlayerId
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
