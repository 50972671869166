import cloneDeep from "lodash.clonedeep";
import { featureCollection, polygon } from "@turf/helpers";
import area from "@turf/area";
import centerOfMass from "@turf/center-of-mass";

export const convertToCentroids = (ftrCollection) => {
  // Recalculate the data to produce centorids for polygons
  if (ftrCollection) {
    const newFtrs: any[] = [];

    ftrCollection.features?.forEach((ftr) => {
      // Clone the geojson feature (so we don't modify the original data)
      const newFtr = cloneDeep(ftr);

      if (
        newFtr.geometry.type === "Polygon" ||
        newFtr.geometry.type === "MultiPolygon"
      ) {
        const centroid = getCentroid(
          newFtr.geometry.coordinates,
          newFtr.geometry.type === "MultiPolygon",
        );
        newFtr.geometry = centroid.geometry;
        newFtrs.push(newFtr);
      } else {
        newFtrs.push(newFtr);
      }
    });

    const newFtrCollection: any = featureCollection(newFtrs);
    return newFtrCollection;
  } else {
    return ftrCollection;
  }
};

const getCentroid = (coordinateList, multiPolygon = false) => {
  // Get the centre of the largest part of the polygon feature
  let maxArea = 0;
  let largestPoly: any;

  // Find the largest ring of the polygon
  if (multiPolygon) {
    for (const plyIdx in coordinateList) {
      const poly = polygon(coordinateList[plyIdx]);
      const polyArea = area(poly);

      if (polyArea > maxArea) {
        maxArea = polyArea;
        largestPoly = poly;
      }
    }
  } else {
    const poly = polygon(coordinateList);
    largestPoly = poly;
  }

  return centerOfMass(largestPoly);
};
