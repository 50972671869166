import React, { useState, useEffect, useMemo } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import debounce from "lodash.debounce";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { GET_SEARCH_RESULTS } from "../../../../data/searchQueries";
import i18n from "../../../../i18n";
import { rootObjectActions } from "../../../../store/rootObjects";
import { useApolloClient } from "@apollo/client";
import { RootState } from "../../../../store";
import { sortList } from "../../../../common/components/SortBy/utils/utils";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    autocomplete: {
      width: "100%",
      height: "100%",
      "& .MuiAutocomplete-inputRoot": {
        padding: 0,
        height: "100%",
      },
      "& .MuiAutocomplete-endAdornment": {
        top: "initial",
      },
      "& > div": {
        height: "inherit",
      },
    },
    textFieldInput: {
      height: "100%",
      "& > fieldset": {
        border: "none",
      },
      "& > input": {
        padding: "1em",
        height: "100%",
      },
    },
    suggestionOption: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& > p": {
        flex: 1,
      },
      "& > span": {
        width: "20%",
      },
    },
  });

type ClassKey = "root" | "autocomplete" | "textFieldInput" | "suggestionOption";
type PropsType = IProps & WithStyles<ClassKey>;

const QueryPanel: React.FC<PropsType> = (props: PropsType) => {
  const { classes } = props;

  const dispatch = useDispatch();
  const rootObject = useSelector((state: RootState) => state.rootObjects);
  const layerGroups = useSelector((state: RootState) => state.layerGroups);
  const objectLayers = layerGroups?.flatMap((lg) => lg.objectlayers);
  const [isQuerySearch, setIsQuerySearch] = useState<boolean>(true);
  const [multiResults, setMultiResults] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  const client = useApolloClient();

  const queryMulti = async (text) => {
    if (objectLayers.length) {
      setLoading(true);
      let queriesFinished = 0;
      let queryResults: any[] = [];
      objectLayers?.forEach(async (objectLayer) => {
        try {
          // client.query is used bc useLazyQuery won't make multiple calls
          // see https://github.com/apollographql/react-apollo/issues/3355
          const response = await client.query({
            query: GET_SEARCH_RESULTS,
            variables: {
              objectType: objectLayer?.objectlayerName,
              term: text,
            },
            fetchPolicy: "network-only",
          });
          if (response.data.allObjects.edges.length) {
            const top3 = response.data.allObjects.edges.slice(0, 3);
            queryResults = [...queryResults, ...top3];

            setMultiResults(
              sortList(
                queryResults,
                "node[objectLayer][objectlayerName]",
                true,
              ),
            );
          }
          queriesFinished++;
          if (queriesFinished === objectLayers.length) {
            setLoading(false);
          }
        } catch (errors) {
          enqueueSnackbar(i18n.t("error.queryGraphqlError"), {
            variant: "error",
          });
        }
      });
    }
  };

  // Don't recreate this, otherwise debounce is pointless (lose timer info)
  const queryApi = useMemo(
    () => {
      return debounce(async (text) => {
        const validators: Array<(string) => boolean> = [(t) => t.length >= 3];

        // If a single validator fails, return early without making API call
        const failedValidation = validators.some(
          (validator) => !validator(text),
        );

        if (failedValidation) return;

        await queryMulti(text);
      }, 400);
    },
    [layerGroups], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (inputValue !== "" && isQuerySearch) {
      queryApi(inputValue);
    }
  }, [inputValue, queryApi]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    queryMulti("");
  }, [queryApi]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (rootObject.rootObject?.objectName) {
      // Rootobject changed due to interaction elsewhere
      setIsQuerySearch(false);
      setInputValue(
        `${rootObject.rootObject.objectLongId} - ${rootObject.rootObject.objectName}`,
      );
    }
  }, [rootObject]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (_, newValue: any | null) => {
    if (newValue) {
      setIsQuerySearch(true);
      setInputValue("");
      dispatch(rootObjectActions.insertEntryObject(newValue.node));
    }
  };

  return (
    <Autocomplete
      className={classes.autocomplete}
      options={multiResults.length ? multiResults : []}
      getOptionLabel={(option) => {
        return `${option?.node?.objectLongId} - ${option?.node?.objectName}`;
      }}
      autoComplete
      includeInputInList
      filterSelectedOptions
      filterOptions={(x) => x}
      loading={loading}
      loadingText={i18n.t("searchHint.loading")}
      noOptionsText={i18n.t("searchHint.noResults")}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        if (event?.nativeEvent.type === "input") {
          setIsQuerySearch(true);
          setInputValue(newInputValue);
        }
      }}
      inputValue={inputValue}
      renderInput={(params) => (
        // TODO: extract
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            className: classes.textFieldInput,
            endAdornment: (
              <InputAdornment position="end">
                {loading ? <CircularProgress size={18} /> : <SearchIcon />}
              </InputAdornment>
            ),
          }}
          placeholder={i18n.t("typeAtLeast3Char")}
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderOption={(option) => {
        return (
          <div className={classes.suggestionOption}>
            <Typography variant="body1">
              {`${option?.node?.objectName}`}
            </Typography>
            <span>
              <Typography variant="body1" align={"right"}>
                {`${option?.node?.objectLayer?.objectlayerName
                  .substring(0, 3)
                  .toUpperCase()}`}
              </Typography>
            </span>
          </div>
        );
      }}
    />
  );
};

export default withStyles(styles)(QueryPanel);
