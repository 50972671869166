import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { Card, CardTitle } from "../../../common/components/Card";
import { selectedObjectsActions } from "../../../store/selectedObjects";
import i18n from "../../../i18n";
import Tree from "./Tree";
import { RootState } from "../../../store";
import ToggleAll from "../../../common/components/SortBy/ToggleAll";

interface IProps {
  setIsTabularViewOpen: (isTabularViewOpen: boolean) => void;
  setSelectAll: (flag: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    awaiting: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.primary,
      fontSize: theme.spacing(1.6),
      minHeight: theme.spacing(20),
    },
    title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: -6,
    },
    treeCardActions: {
      display: "flex",
      alignItems: "center",
    },
  });

type ClassKey = "awaiting" | "title" | "treeCardActions";
type PropsType = IProps & WithStyles<ClassKey>;

const TreeCard: React.FC<PropsType> = (props) => {
  const { classes, setIsTabularViewOpen } = props;

  const dispatch = useDispatch();

  const tree = useSelector((state: RootState) => state.treeObjects);
  const rootObjects = useSelector((state: RootState) => state.rootObjects);
  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );

  const getObjectsFromTree = () => {
    const objects: any[] = [];
    objects.push(tree?.root[0]);
    tree?.root[0].children.forEach((child) => {
      if (child) {
        objects.push(child);
        if (child.grandChildren.length)
          objects.push(...child.grandChildren.map((a) => a));
      }
    });
    return objects;
  };

  const treeObjects = getObjectsFromTree();

  return (
    <Card overflowY="hidden">
      <CardTitle>
        <div className={classes.title}>
          {i18n.t("searchResults")}
          <div className={classes.treeCardActions}>
            {tree && rootObjects.rootObject && (
              <ToggleAll
                noBoldTitle={true}
                selectableItems={treeObjects}
                selectedItems={selectedObjects}
                selectAllItems={() => {
                  dispatch(
                    selectedObjectsActions.insertSelectedObjects(treeObjects),
                  );
                }}
                deselectAllItems={() => {
                  dispatch(selectedObjectsActions.resetSelectedObjects());
                }}
              />
            )}
          </div>
        </div>
      </CardTitle>
      {rootObjects.entryObject ? (
        <Tree
          setIsTabularViewOpen={setIsTabularViewOpen}
          setSelectAll={props.setSelectAll}
        />
      ) : (
        <div className={classes.awaiting}>{i18n.t("waitingUserInput")}</div>
      )}
    </Card>
  );
};

export default withStyles(styles)(TreeCard);
