import React from "react";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { Router, Route, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { SnackbarProvider } from "notistack";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import * as Sentry from "@sentry/react";

import i18n from "./i18n";
import Frame from "./common/components/Frame/Frame";
import Mobile from "./common/components/Mobile";
import PageNotFound from "./common/components/PageNotFound";
import { octoTheme } from "./common/mui";
import { apolloClient } from "./common/graphql/apollo";
import "./index.css";
import config from "./config";
import { store } from "./store/store";

if (config.sentry.enabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
    tracesSampleRate: config.sentry.tracesSampleRate ?? 0,
  });
}

const App = () => {
  const browserHistory = createBrowserHistory();

  const isMobile = window.innerWidth <= 600;

  if (isMobile) return <Mobile />;

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={octoTheme}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={1}
            preventDuplicate
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            <Provider store={store}>
              <ApolloProvider client={apolloClient as any}>
                {/* Pass the client again for compatibility w/ @apollo/react-hooks
              https://github.com/apollographql/apollo-client/issues/2042#issuecomment-509041949*/}
                <ApolloHooksProvider client={apolloClient as any}>
                  <Router history={browserHistory}>
                    <Switch>
                      <Route component={Frame} />
                      <Route path="/404" component={PageNotFound} />
                    </Switch>
                  </Router>
                </ApolloHooksProvider>
              </ApolloProvider>
            </Provider>
          </SnackbarProvider>
        </ThemeProvider>
      </I18nextProvider>
    </>
  );
};

export default App;
