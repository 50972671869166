import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { useDispatch } from "react-redux";

import SourceCard from "./SourceCard";
import { modalActions } from "../../../../store/modal";
import { EModalTypes } from "../../../../store/interfaces";

interface IProps {
  sources: any[];
  selectedSources: any[];
  insertSelectedSource: (selectedSource) => void;
  removeSelectedSource: (selectedSource) => void;
  setIsAssigningSource: (flag: boolean) => void;
  setIsEditToggledOn: (flag: boolean) => void;
  setSelectAll: (flag: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "grid",
      overflowY: "auto",
      gridGap: theme.spacing(),
      padding: theme.spacing(), // to show box shadow
      gridTemplateColumns: "repeat(auto-fill, minmax(108px, 1fr))",
    },
    sourceCard: {
      width: 105,
      height: 105,
      padding: theme.spacing(),
      overflow: "hidden",
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      cursor: "pointer",
      boxShadow: "0px 0px 5px 2px #ccc",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });

type ClassKey = "root" | "sourceCard";
type PropsType = IProps & WithStyles<ClassKey>;

const Sources: React.FC<PropsType> = (props) => {
  const {
    sources,
    selectedSources,
    insertSelectedSource,
    removeSelectedSource,
    setIsEditToggledOn,
    setIsAssigningSource,
  } = props;

  const dispatch = useDispatch();

  return (
    <div className={props.classes.root}>
      {sources.map((source) => (
        <SourceCard
          key={source.sourcesId}
          source={source}
          selectedSources={selectedSources}
          insertSelectedSource={insertSelectedSource}
          removeSelectedSource={removeSelectedSource}
        />
      ))}
      <div
        className={props.classes.sourceCard}
        onClick={() => {
          // Flow for creating new source
          dispatch(
            modalActions.openModal(EModalTypes.selectSingleSourceObject, {
              modalProps: {
                setIsEditToggledOn: setIsEditToggledOn,
                setIsAssigningSource: setIsAssigningSource,
                setSelectAll: props.setSelectAll,
                currentSources: props.sources,
              },
            }),
          );
        }}
      >
        <AddBoxIcon style={{ width: 50, height: 50 }} color="primary" />
      </div>
    </div>
  );
};

export default withStyles(styles)(Sources);
