import React, { useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";

import LoginBackground from "../../images/login_background.svg";
import OctoHeader from "../../images/login_header.svg";
import OctoLogo from "../../images/Octo_FavIcon_v2.svg";
import ArupLogo_white from "../../images/ArupLogo_white.svg";
import {
  getAccount,
  acquireTokenSilentAndSetLocal,
  isAuthenticated,
  getloginPopupResponse,
  cleanUpMsalLocalStorage,
} from "../auth";
import i18n from "../../i18n";
import { userActions } from "../../store/user";
import SignInHelp from "./SignInHelp";
import { useHistory } from "react-router";

interface IProps {
  setIsAuth: (isAuth: boolean) => void;
  setIsAuthorizing: (isAuth: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100vw",
      position: "fixed",
      backgroundImage: `url(${LoginBackground})`,
      backgroundSize: "cover",
    },
    container: {
      marginTop: "-200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "80vw",
      height: "20vh",
    },
    header: {
      width: "38em",
      marginTop: "-20px",
    },
    helpButton: {
      paddingTop: "30px",
      color: "#FAFAFA",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    loginButton: {
      width: "113px",
      height: "32px",
      background: "#87C3BB",
      borderRadius: "2px",
      color: "black",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    footer: {
      position: "absolute",
      bottom: "70px",
      display: "flex",
      color: "white",
    },
    footerYear: {
      fontWeight: 50,
      fontSize: "23.3541px",
      paddingLeft: "5px",
    },
  });

type ClassKey =
  | "root"
  | "container"
  | "header"
  | "helpButton"
  | "loginButton"
  | "footer"
  | "footerYear";

type PropsType = IProps & WithStyles<ClassKey>;

const SignIn: React.FC<PropsType> = (props) => {
  const { setIsAuth, setIsAuthorizing, classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [showHelp, setShowHelp] = useState<boolean>(false);

  const submit = async () => {
    setIsAuthorizing(true);
    cleanUpMsalLocalStorage();
    await getloginPopupResponse();
    if (getAccount()) {
      await acquireTokenSilentAndSetLocal(getAccount());
      await dispatch(userActions.updateUser(history));
      setIsAuth(isAuthenticated());
    }
    setIsAuthorizing(false);
  };

  const handleClose = () => {
    setShowHelp(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img src={OctoLogo} alt="" />
        <img className={classes.header} src={OctoHeader} alt="" />
        <form onSubmit={submit}>
          <Button
            className={classes.loginButton}
            onClick={submit}
            type="submit"
          >
            <Typography variant="h1">{i18n.t("login")}</Typography>
          </Button>
        </form>
        <Button
          className={classes.helpButton}
          onClick={() => {
            setShowHelp(!showHelp);
          }}
        >
          <Typography>{i18n.t("havingTroubleLogin")}</Typography>
        </Button>
        <div className={classes.footer}>
          <img style={{ fill: "white" }} src={ArupLogo_white} alt="" />
          <Typography className={classes.footerYear}>2022</Typography>
        </div>
      </div>
      {showHelp ? <SignInHelp handleClose={handleClose} /> : null}
    </div>
  );
};

export default withStyles(styles)(SignIn);
