import { Action, Reducer } from "redux";

import { KnownAction } from "./actions";
import { EModalTypes, IModalProps } from "../interfaces";

export interface IModal {
  modalType: EModalTypes | null;
  modalProps: IModalProps;
}

const initialState: IModal = {
  modalType: null,
  modalProps: {},
};

export const modalReducer: Reducer<IModal> = (
  state = initialState,
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "OPEN_MODAL":
      return action.payload;

    case "CLOSE_MODAL":
      return initialState;

    case "MODIFY_MODAL_PROPS":
      return { ...state, modalProps: action.modifiedProps };

    default:
      return state;
  }
};
