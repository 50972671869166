import { IProjectConfig } from "../../store/selectedProject/actions";

export default {
  name: "sch",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja2lnNXV2bWowOGlnMnBxamhjbWh0bXBsIn0.ltl6wWjVVnxbdhCqw9MtYw",
  },
  graphql: {
    url: "https://prod.dwh-api.arup.com/sch/graphql/",
  },
  coordinates: {
    latitude: 52.308,
    longitude: 4.758268365567975,
    zoom: 11,
  },
  map: {
    zoomTreshold: 15,
    layerSymbols: [
      {
        objectlayerIds: [5],
        symbol: "borehole",
        geomType: "Point",
      },
      {
        objectlayerIds: [6],
        symbol: "cpt",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [1, 2],
        minZoom: 10,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 0,
  air: {
    enabled: false,
    url: "",
  },
} as IProjectConfig;
