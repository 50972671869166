import { ISource } from "../../data/types/typeStitches";
import { AppThunk } from "../interfaces";

export const selectedSourcesActions = {
  resetSelectedSources: (): AppThunk => (dispatch) => {
    dispatch({ type: "RESET_SELECTED_SOURCES" });
  },
  insertSelectedSource: (selectedSource: ISource): AppThunk => (dispatch) => {
    dispatch({ type: "INSERT_SELECTED_SOURCE", selectedSource });
  },
  removeSelectedSource: (selectedSource: ISource): AppThunk => (dispatch) => {
    dispatch({ type: "REMOVE_SELECTED_SOURCE", selectedSource });
  },
  selectAllSources: (selectedSources: ISource[]): AppThunk => (dispatch) => {
    dispatch({ type: "SELECT_ALL_SOURCES", selectedSources });
  },
};
