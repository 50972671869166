import { useLazyQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ISorterKey } from "../../../common/components/SortBy/SortBy";
import { GET_SOURCES } from "../../../data/sourceQueries";
import i18n from "../../../i18n";
import { RootState } from "../../../store";
import { selectedSourcesActions } from "../../../store/selectedSources";
import TreeRow from "../Tree/TreeRow";
import SortPanel from "./../../../common/components/SortBy/SortPanel";
import Sources from "./Sources/Sources";
import { ReactComponent as SharepointLogo } from "../../../images/microsoftSharepointLogo.svg";
import { isLocalEnv, isP500Env } from "../../../common/utils";
import { dateSorter, numberSorter } from "../../../utils/sorters";
import { EPanelSize } from "./Tabular";
import { SharedParameterContext } from "./SharedParameterContext/SharedParameterContext";

const sortKeys: ISorterKey[] = [
  {
    label: i18n.t("dateCreated"),
    key: "sourcesDatecreated",
  },
  {
    label: i18n.t("dateImported"),
    key: "sourcesDateobtained",
  },
  {
    label: i18n.t("dataScore"),
    key: "sourcesBestdatascore",
  },
];

interface IProps {
  setIsAssigningSource: (flag: boolean) => void;
  setIsEditToggledOn: (flag: boolean) => void;
  selectAll: boolean;
  setSelectAll: (flag: boolean) => void;
  sources: any[];
  prevSelectedObjects: any;
  setSources: (newSources: any[]) => void;
  isAirSharepointSupported: boolean;
  size: EPanelSize;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    header: {
      position: "absolute",
      top: theme.spacing(0),
      right: theme.spacing(1),
    },
    treeRow: {
      display: "flex",
      alignItems: "center",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    sortBy: {},
    spinner: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    sources: {
      flex: 1,
      overflowX: "hidden",
      overflowY: "auto",
    },
  });

type ClassKey =
  | "root"
  | "header"
  | "spinner"
  | "treeRow"
  | "sortBy"
  | "sources";
type PropsType = IProps & WithStyles<ClassKey>;

const SourceSelect: React.FC<PropsType> = (props) => {
  const {
    classes,
    sources,
    setSources,
    isAirSharepointSupported,
    size,
    prevSelectedObjects,
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { sharedParameter } = useContext(SharedParameterContext);

  const [getData, { loading, error }] = useLazyQuery(GET_SOURCES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const fetchedSources: any[] = data.allSources?.edges?.map((s) => s.node);
      const defaultSelection = fetchedSources
        ?.sort((a, b) =>
          dateSorter(
            new Date(a.sourcesDatecreated).toDateString(),
            new Date(b.sourcesDatecreated).toDateString(),
            false,
          ),
        )
        .filter(
          (s) =>
            new Date(s.sourcesDatecreated).toDateString() ===
            new Date(fetchedSources[0].sourcesDatecreated).toDateString(),
        )
        .sort((a, b) =>
          numberSorter(a.sourcesBestdatascore, b.sourcesBestdatascore, false),
        )
        .sort((a, b) =>
          numberSorter(a.parameterValues_Count, b.parameterValues_Count, false),
        )[0];

      setSources([...fetchedSources]);

      const matchedSourceId = fetchedSources.findIndex(
        (s) => s.sourcesId === sharedParameter.selectedSource.sourcesId,
      );

      dispatch(selectedSourcesActions.resetSelectedSources());
      if (matchedSourceId > -1)
        dispatch(
          selectedSourcesActions.insertSelectedSource(
            fetchedSources[matchedSourceId],
          ),
        );
      if (matchedSourceId === -1 && defaultSelection)
        dispatch(selectedSourcesActions.insertSelectedSource(defaultSelection));
    },
  });
  const dispatch = useDispatch();
  const selectedSources = useSelector(
    (state: RootState) => state.selectedSources,
  );
  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );
  const rootObjects = useSelector((state: RootState) => state.rootObjects);

  const get = () => {
    getData({
      variables: {
        objectIds: selectedObjects.map((o) => o.objectId),
      },
    });
  };

  useEffect(() => {
    const prevLength = prevSelectedObjects
      ? (prevSelectedObjects as any).length
      : -1;
    if (selectedObjects.length && prevLength !== selectedObjects.length) {
      get();
    }
  }, [selectedObjects]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedObjects.length) get();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (size === EPanelSize.small) return null;

  if (loading) {
    return (
      <div className={classes.root}>
        <div className={classes.spinner}>
          <CircularProgress size={64} />
        </div>
      </div>
    );
  }

  if (error) {
    enqueueSnackbar(i18n.t("error.queryGraphqlError"), { variant: "error" });
  }

  const getSharepointUrl = () => {
    const baseUrl =
      "https://arup.sharepoint.com/sites/P500/3address_related_data/Forms/AllItems.aspx?viewid=4a9ed14a%2Dba42%2D4315%2D82f2%2Df96687e4fa3d&id=%2Fsites%2FP500%2F3address%5Frelated%5Fdata%2F";
    const currentId = rootObjects.rootObject?.objectLongId || "";
    return baseUrl + currentId;
  };

  return (
    <div className={classes.root}>
      <>
        <div className={classes.header}>
          <div className={classes.treeRow}>
            <TreeRow
              isLabelOnly
              isExpanded={false}
              hasChildren={false}
              label={rootObjects.rootObject?.objectLongId?.toString() as string}
              objectType={
                rootObjects?.rootObject?.objectLayer?.objectlayerName as string
              }
              object={rootObjects.rootObject}
            />

            <Typography variant="caption" color="textPrimary">
              {i18n.t("displayingSources", {
                nSources: sources.length,
              })}
            </Typography>
            {(isLocalEnv() || isP500Env()) && isAirSharepointSupported && (
              <Button
                variant={"contained"}
                href={getSharepointUrl()}
                target={"_blank"}
                endIcon={<SharepointLogo />}
              >
                {i18n.t("viewObjectInSharepoint")}
              </Button>
            )}
          </div>
        </div>

        {!!sources?.length && (
          <div className={classes.sortBy}>
            <SortPanel
              sortKeys={sortKeys}
              items={sources}
              selectedItems={selectedSources}
              selectAllItems={() =>
                dispatch(selectedSourcesActions.selectAllSources(sources))
              }
              deselectAllItems={() =>
                dispatch(selectedSourcesActions.resetSelectedSources())
              }
              setItems={setSources}
            />
          </div>
        )}

        <div className={classes.sources}>
          <Sources
            setIsAssigningSource={props.setIsAssigningSource}
            setIsEditToggledOn={props.setIsEditToggledOn}
            sources={sources}
            setSelectAll={props.setSelectAll}
            selectedSources={selectedSources}
            insertSelectedSource={(s) =>
              dispatch(selectedSourcesActions.insertSelectedSource(s))
            }
            removeSelectedSource={(s) =>
              dispatch(selectedSourcesActions.removeSelectedSource(s))
            }
          />
        </div>
      </>
    </div>
  );
};

// Use memo to prevent frequent rerenders caused by map interaction in Core.tsx
export default withStyles(styles)(React.memo(SourceSelect));
