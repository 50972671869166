import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Editor,
  DrawPolygonMode,
  EditingMode,
  RENDER_STATE,
} from "react-map-gl-draw";
import { LatLong, CreateOption } from "./CreateEntry";
interface IProps {
  createMode: CreateOption;
  setPolygon: (polygon: LatLong[]) => void;
}

const getEditHandleStyle = ({ feature, state }) => {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
      return {
        fill: "rgb(251, 176, 59)",
        fillOpacity: 1,
        stroke: "rgb(255, 255, 255)",
        strokeWidth: 2,
        r: 9,
      };

    default:
      return {
        fill: "rgb(251, 176, 59)",
        fillOpacity: 1,
        stroke: "rgb(255, 255, 255)",
        strokeWidth: 2,
        r: 5,
      };
  }
};

const getFeatureStyle = ({ feature, index, state }) => {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      return {
        stroke: "rgb(251, 176, 59)",
        strokeWidth: 2,
        fill: "rgb(251, 176, 59)",
        fillOpacity: 0.3,
        strokeDasharray: "4,2",
        cursor: "pointer",
      };

    default:
      return {
        stroke: "rgb(60, 178, 208)",
        strokeWidth: 2,
        fill: "rgb(60, 178, 208)",
        fillOpacity: 0.1,
      };
  }
};

const MapDrawTools: React.FC<IProps> = (props) => {
  const { createMode, setPolygon } = props;
  const [mode, setMode] = useState<any>(null);
  const editorRef = useRef<any>(null);

  const currentPolygon = (): LatLong[] =>
    editorRef.current
      .getFeatures()[0]
      .geometry.coordinates[0].map(
        (point: number[]) => ({ lat: point[1], long: point[0] } as LatLong),
      );

  const onUpdate = useCallback(
    ({ editType }) => {
      if (editType === "addFeature") {
        setMode(new EditingMode());
        setPolygon(currentPolygon());
      }

      if (editType === "movePosition") {
        setPolygon(currentPolygon());
      }
    },
    [setPolygon],
  );

  useEffect(() => {
    switch (createMode) {
      case CreateOption.None:
        setMode(null);
        break;
      case CreateOption.Draw:
        setMode(new DrawPolygonMode());
        break;
      case CreateOption.Location:
        setMode(new EditingMode());
        break;
      // case CreateOption.Object:
      //     break;
      default:
        setMode(null);
    }
    const features = editorRef.current && editorRef.current.getFeatures();

    // We only want one polygon on screen
    if (
      (createMode === CreateOption.Draw || createMode === CreateOption.None) &&
      features?.length > 0
    ) {
      editorRef.current.deleteFeatures(0);
    }
  }, [createMode]);

  return (
    <Editor
      ref={editorRef}
      style={{ width: "100%", height: "100%" }}
      clickRadius={12}
      mode={mode}
      // onSelect={onSelect}
      onUpdate={onUpdate}
      editHandleShape={"circle"}
      featureStyle={getFeatureStyle}
      editHandleStyle={getEditHandleStyle}
    />
  );
};

export default MapDrawTools;
