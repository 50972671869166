import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Card, CardTitle } from "../../../common/components/Card";
import { ILayerGroup } from "../../../data/types/typeStitches";
import {
  CREATE_OBJECT,
  CREATE_SCOPE_OBJECT,
} from "../../../data/newEntryFromMapQueries";
import {
  LazyQueryResult,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import { DateTimeResolver } from "graphql-scalars";
import { useSnackbar } from "notistack";
import i18n from "../../../i18n";
import { IBareFilter } from "../Core";
import { IFilter } from "../Filter/Filter";

export enum CreateOption {
  Location = "location",
  Object = "object",
  Draw = "draw",
  None = "none",
}
export interface LatLong {
  lat: number;
  long: number;
}

interface IProps {
  layerGroups: ILayerGroup[];
  setIsOpenNewEntry: (isOpenNewEntry: boolean) => void;
  createMode: CreateOption;
  polygon: LatLong[] | undefined;
  setPolygon: (points: LatLong[] | undefined) => void;
  markerCoords: LatLong;
  setCreateMode: (flag: CreateOption) => void;
  scopes: IBareFilter[];
  projects: IBareFilter[];
  selectedScopes: IBareFilter[];
  selectedProjects: IBareFilter[];
  allScopesAndProjects: LazyQueryResult<any, OperationVariables>;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      color: theme.palette.primary.main,
      flex: 1,
      borderLeft: "1px solid #bebebe",
      maxHeight: "200px",
    },
    closingButton: {
      padding: "10px",
      position: "absolute",
      top: 0,
      right: 0,
    },
    container: {
      [theme.breakpoints.down("md")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.up("lg")]: {
        display: "inline",
      },
      marginBottom: "5px",
      maxWidth: "180px",
    },
    buttonWrapper: {
      paddingTop: "50px",
      paddingBottom: "20px",
      display: "flex",
      justifyContent: "flex-end",
    },
    saveNotice: {
      padding: "5px",
      alignSelf: "left",
    },
  });

type ClassKey =
  | "root"
  | "closingButton"
  | "container"
  | "buttonWrapper"
  | "saveNotice";
type PropsType = IProps & WithStyles<ClassKey>;

const CreateEntry: React.FC<PropsType> = (props) => {
  const {
    classes,
    layerGroups,
    setIsOpenNewEntry,
    createMode,
    polygon,
    setPolygon,
    markerCoords,
    setCreateMode,
    projects,
    allScopesAndProjects,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [layerGroupID, setLayerGroupID] = React.useState<number>();
  const [objectLayerID, setObjectLayerID] = React.useState<number>();
  const [objectName, setObjectName] = React.useState("");
  const [objectLongID, setObjectLongID] = React.useState("");
  const [selectedScope, setScope] = React.useState<number>();
  const [selectedProject, setSelectedProject] = React.useState<number>();
  const [createObject] = useMutation(CREATE_OBJECT, {});
  const [createScope] = useMutation(CREATE_SCOPE_OBJECT, {});
  const [relatedScopes, setRelatedScopes] = React.useState<IFilter[]>([]);

  //returns the current layer group as index of "layerGroups"
  const returnCurrentLayerGroupIndex = () => {
    return (layerGroupID as number) - 1;
  };

  //general check in case layer groups are unavailable due to no connection to dwh backend
  if (layerGroups.length === 0) {
    enqueueSnackbar(i18n.t("warning.noLayersAvailable"), {
      variant: "warning",
    });
  }

  const getRelatedScopes = () => {
    const relatedScopes = allScopesAndProjects.data.allScopes.edges
      .filter(
        ({ node }) =>
          node.scopeProject.projectId === selectedProject?.toString(),
      )
      .map(({ node }) => {
        return { id: node.scopeId, name: node.scopeName };
      });
    setRelatedScopes(relatedScopes);
  };

  const handleOnClose = () => {
    setIsOpenNewEntry(false);
    setCreateMode(CreateOption.None);
    setPolygon(undefined);
  };

  const checkIfDataComplete = () => {
    return (
      objectName.trim() &&
      objectLongID.trim() &&
      objectLayerID != null &&
      selectedProject != null &&
      selectedScope != null &&
      ((createMode === CreateOption.Draw && polygon !== undefined) ||
        (createMode === CreateOption.Location && markerCoords !== undefined))
    );
  };

  const onSave = async () => {
    if (checkIfDataComplete()) {
      try {
        const date = DateTimeResolver.serialize(new Date());
        const objectGeom = handleGeometry();
        const result = await createObject({
          variables: {
            objectName: objectName,
            objectLongId: objectLongID,
            objectGeom: objectGeom,
            objectLayer: objectLayerID, // determined as in get layer groups, each layer has own id, suid: 1, address: 3, building: 4, geotech:8, geotech_cpt:9
            objectDatevalidfrom: date, //TODO get date from an input field?
            objectStatus: 0, // for msf only object status 0 exists, for others also 1,2,3..
          },
        });

        const objectId = result.data.updateObject.objectId;

        await createScope({
          variables: {
            scopeId: selectedScope,
            objectId: objectId,
          },
        });

        enqueueSnackbar(i18n.t("success.newObjectCreated"), {
          variant: "success",
        });
        handleOnClose();
      } catch (e) {
        enqueueSnackbar(
          i18n.t("error.mutateGraphqlError", {
            error: e,
          }),
          { variant: "error" },
        );
      }
    } else {
      enqueueSnackbar(i18n.t("warning.inputNotComplete"), {
        variant: "warning",
      });
    }
  };

  const multiPolygon = (polygon: LatLong[]) => {
    const formatPoints = polygon.map(
      (point: LatLong, i: number) =>
        (i === 0 ? "" : " ") + point.long + " " + point.lat,
    );
    return "MULTIPOLYGON (((" + formatPoints + ")))";
  };

  const point = (coords: LatLong) => {
    return "POINT (" + coords.long + " " + coords.lat + ")";
  };

  const handleGeometry = () => {
    if (createMode === CreateOption.Draw && polygon) {
      return multiPolygon(polygon);
    } else if (createMode === CreateOption.Location) {
      return point(markerCoords);
    }
  };

  const dynamicTitle = (
    <CardTitle>
      {createMode === CreateOption.Location
        ? "Use location"
        : createMode === CreateOption.Object
        ? "Use object polygon"
        : createMode === CreateOption.Draw
        ? "Draw on map"
        : ""}
    </CardTitle>
  );

  const saveNotice = (
    <div className={classes.saveNotice}>
      {createMode === CreateOption.Draw ? "Double Click to Save" : ""}
    </div>
  );

  return (
    <Card overflowY="hidden">
      <IconButton className={classes.closingButton} onClick={handleOnClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
      {dynamicTitle}
      {saveNotice}
      <Box title="Object Name" className={classes.container}>
        <TextField
          fullWidth
          aria-label="objectName"
          label="Object Name"
          onChange={(e) => setObjectName(e.target.value)}
        ></TextField>

        <TextField
          fullWidth
          aria-label="objectLongID"
          label="Object Long ID"
          onChange={(e) => setObjectLongID(e.target.value)}
        ></TextField>
      </Box>

      <Box title="Map feature layer" className={classes.container}>
        <FormControl fullWidth>
          <InputLabel id="layerGroupLabel">Layer Group</InputLabel>
          <Select
            labelId="layerGroupLabel"
            id="layerGroupSelect"
            value={layerGroupID || ""}
            label="layerGroup"
            onChange={(e) => setLayerGroupID(e.target.value as number)}
          >
            {layerGroups?.map((val) => (
              <MenuItem
                key={val.objectlayergroupId}
                value={val.objectlayergroupId as number}
              >
                {val.objectlayergroupName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="objectLayerLabel">Object Layer</InputLabel>
          <Select
            labelId="objectLayerLabel"
            id="objectLayerSelect"
            value={objectLayerID || ""}
            label="Object Layer"
            onChange={(e) => setObjectLayerID(e.target.value as number)}
          >
            {layerGroups[returnCurrentLayerGroupIndex()]?.objectlayers.map(
              (val) => (
                <MenuItem
                  key={val.objectlayerId}
                  value={val.objectlayerId as number}
                >
                  {val.objectlayerName}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      </Box>

      <Box title="Map feature layer" className={classes.container}>
        <FormControl fullWidth>
          <InputLabel id="selectProject">Select Project</InputLabel>
          <Select
            labelId="projectLabel"
            id="projectSelect"
            value={selectedProject || ""} //TODO prefill w/ selected project from filter if available
            label="Select Project"
            onChange={(e) => setSelectedProject(e.target.value as number)}
          >
            {projects?.map((val) => (
              <MenuItem key={val.id} value={parseInt(val.id) as number}>
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="selectScopeLabel">Select Scope</InputLabel>
          <Select
            labelId="scopeLabel"
            id="scopeSelect"
            value={selectedScope || ""}
            label="Select Scope"
            onChange={(e) => setScope(e.target.value as number)}
            onOpen={getRelatedScopes}
          >
            {relatedScopes.map((val) => (
              <MenuItem key={val.id} value={val.id}>
                {val.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box className={classes.buttonWrapper}>
        <Button variant="contained" onClick={onSave}>
          Save Entry
        </Button>
      </Box>
    </Card>
  );
};

export default withStyles(styles)(CreateEntry);
