import { EEnvType } from "../config/interfaces";
import config from "../config";
import { store } from "../store/store";

export const getIsIpad = (height: number, width: number) => {
  const aspectRatio = height / width;
  return aspectRatio >= 1.33 && aspectRatio < 1.3339;
};

export const getIsIpadPro = (height: number, width: number) => {
  const aspectRatio = height / width;
  return aspectRatio >= 1.3339 && aspectRatio <= 1.43;
};

export const filterUniqObjs = (list, key) => {
  const entries = list.map((obj) => obj[key]);
  return list.filter((entry, index) => {
    return entries.indexOf(entry[key]) === index;
  });
};

export const onlyUniq = (value, index, self) => self.indexOf(value) === index;

export const isWindowsOs = navigator.appVersion.indexOf("Win") !== -1;

export const getOctoSpecificLocalStorageObjects = () => {
  const keys = [
    "recentSearches",
    "favProjects",
    "mapAutoSearchEnabled",
    "i18nextLng",
    "lastProject",
  ];
  const octoObjects: any[] = [];
  keys.forEach((key) => {
    const item = localStorage.getItem(key);
    if (item) octoObjects.push({ [key]: item });
  });
  return octoObjects;
};

export const setLocalStorageObjects = (objects) => {
  objects.forEach((object) => {
    for (const [key, value] of Object.entries(object)) {
      localStorage.setItem(key, value as string);
    }
  });
};

export const isLocalEnv = () => EEnvType[config.env] === "local";

export const isP500Env = () => {
  const name = store.getState().selectedProject?.name;
  return name === "p500" || name === "p500dev";
};

export const getUrlParams = (history) => {
  const elements = history?.location.pathname.split("/");
  if (!elements?.length) return null;

  return {
    project: elements[1],
    objectId: elements[2] === "object" ? elements[3] : null,
    parameterValueId: elements[2] === "parameter" ? elements[3] : null,
  };
};
