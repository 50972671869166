import React, { useEffect } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";

import { IModalContentProps } from "../../../../common/components/Modal/ModalManager";
import ModalContent from "../../../../common/components/Modal/ModalContent";

import ModalActions from "../../../../common/components/Modal/ModalActions";
import { DELETE_RESOURCES } from "../../../../data/libraryQueries";
import i18n from "../../../../i18n";
import ModalHeader from "../../../../common/components/Modal/ModalHeader";
import { logSentry } from "../../../../utils/logger";

interface IProps {
  refetchLibraryResources: () => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    spinner: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: `${theme.spacing(5)}px 0`,
    },
    text: {
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(6),
    },
  });

type ClassKey = "root" | "spinner" | "text";
type PropsType = IProps & IModalContentProps & WithStyles<ClassKey>;

const DeleteResource: React.FC<PropsType> = (props) => {
  const {
    classes,
    handleClose,
    refetchLibraryResources,
    setSelectedLibraryResources,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [deleteMultipleResources, deletedMultipleResources] = useMutation(
    DELETE_RESOURCES,
    {
      variables: {
        resourceIds: Object.keys(props.selectedLibraryResources),
      },
      onCompleted: () => {
        handleClose();
        refetchLibraryResources();
        setSelectedLibraryResources({});
      },
    },
  );
  useEffect(() => {
    if (deletedMultipleResources?.data) {
      if (
        !deletedMultipleResources?.data.deleteMultipleResources?.warnings.length
      ) {
        enqueueSnackbar(
          i18n.t("success.fileDelete", {
            numOfDeleted:
              deletedMultipleResources.data.deleteMultipleResources.deletedIds
                .length,
          }),
          {
            variant: "success",
          },
        );
      } else {
        logSentry(
          "warning",
          "problem with deleting S3 objects",
          JSON.stringify(
            deletedMultipleResources.data.deleteMultipleResources.warnings,
          ),
        );
      }
    }

    if (deletedMultipleResources?.error) {
      enqueueSnackbar(i18n.t("error.queryGraphqlError"), {
        variant: "error",
      });
    }
  }, [deletedMultipleResources, enqueueSnackbar]);

  return (
    <>
      <ModalContent>
        {deletedMultipleResources.loading ? (
          <div className={classes.spinner}>
            <CircularProgress size={64} />
          </div>
        ) : (
          <>
            <ModalHeader
              title={i18n.t("deleteModalHeader")}
              handleClose={handleClose}
            />

            <Typography className={classes.text}>
              {i18n.t("deleteConfirmation", {
                numberOfDeletedItems: Object.keys(
                  props.selectedLibraryResources,
                ).length,
              })}
            </Typography>

            <ModalActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={deleteMultipleResources as any}
              >
                {i18n.t("delete")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                type="button"
              >
                {i18n.t("cancel")}
              </Button>
            </ModalActions>
          </>
        )}
      </ModalContent>
    </>
  );
};

export default withStyles(styles)(DeleteResource);
