import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& > * + *": {
        marginLeft: theme.spacing(),
      },
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const ModalActions: React.FC<PropsType> = (props) => {
  const { classes, children } = props;
  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(ModalActions);
