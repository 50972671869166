export interface IMapStyle {
  displayName: string;
  url: string;
}

// Improvement: extend with light/dark options, specific styles per project etc
// Caveat: changing map styles will likely count as a map load (in terms of Mapbox pricing)
export const MAP_STYLES: { [keyof: string]: IMapStyle } = {
  streets: {
    displayName: "Streets",
    url: "mapbox://styles/insight-accounts/ckihapsx76d551amx0z29cded",
  },
  aerial: {
    displayName: "Aerial",
    url: "mapbox://styles/mapbox/satellite-v9",
  },
};
