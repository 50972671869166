export type KnownAction = IUpdateSelectedProject;

export interface IProjectConfig {
  name: string;
  mapbox: IMapBox;
  graphql: IGraphQL;
  coordinates: ICoordinates;
  map: IMap;
  tabularDefaultView: number;
  air: IAirConfig;
  features: IFeatures;
}

interface IMapBox {
  token: string;
}

interface IGraphQL {
  url: string;
}

interface ICoordinates {
  latitude: number;
  longitude: number;
  zoom: number;
}

interface IMap {
  zoomTreshold: number;
  layerSymbols: {
    geomType: string;
    symbol: string;
    objectlayerIds: number[];
  }[];
  layerZoomRanges: {
    maxZoom: number;
    minZoom: number;
    objectlayerIds: number[];
  }[];
}

interface IAirConfig {
  enabled: boolean;
  url: string;
}

export interface IFeatures {
  [key: string]: string | number | boolean;
}

export const FEATURE_FLAGS: IFeatures = {
  createObject: false,
};

export interface IUpdateSelectedProject {
  type: "UPDATE_SELECTED_PROJECT";
  selectedProject: IProjectConfig;
}
