import { gql } from "@apollo/client";

export const GET_LAYER_GROUPS = gql`
  query GET_LAYER_GROUPS {
    allLayerGroups {
      edges {
        node {
          objectlayergroupId
          objectlayergroupIshierarchical
          objectlayergroupName
          objectlayergroupOrder
          objectlayers {
            edges {
              node {
                objectlayerName
                objectlayerOrder
                objectlayerId
                objectlayerSearch
              }
            }
          }
        }
      }
    }
  }
`;
