import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Paper,
  Checkbox,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";

import i18n from "../../../i18n";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import clsx from "clsx";

interface IProps {
  viewportChanged: boolean;
  checked: boolean;
  loading: boolean;
  layersVisible: boolean;
  pastDefaultZoomRange: boolean;
  handleOnDemandSearch: () => void;
  handleChange: () => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      position: "absolute",
      top: theme.spacing(1),
      left: "50%",
      transform: "translateX(calc(-50% + 176px))",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      padding: theme.spacing(1),
      "& label": {
        display: "flex",
        alignItems: "center",
      },
      "& .MuiButtonBase-root.MuiIconButton-root": {
        padding: 0,
        paddingRight: theme.spacing(1),
      },
      backgroundColor: theme.palette.background.default,
      borderRadius: 0,
      boxShadow: "2px 2px 2px 0 rgba(118, 118, 118, 0.45)",
    },
    searchOnClick: {
      cursor: "pointer",
    },
    secondBox: {
      marginTop: theme.spacing(5),
    },
  });

type ClassKey = "root" | "searchOnClick" | "secondBox";
type PropsType = IProps & WithStyles<ClassKey>;

const SearchStrategyToggle: React.FC<PropsType> = (props) => {
  const {
    classes,
    layersVisible,
    pastDefaultZoomRange,
    viewportChanged,
    checked,
    loading,
    handleOnDemandSearch,
    handleChange,
  } = props;

  const visibleLayerIds = useSelector(
    (state: RootState) => state.visibleLayerIds,
  );

  const renderPartialLayer = () => {
    if (
      layersVisible &&
      !pastDefaultZoomRange &&
      (visibleLayerIds.length > 1 || visibleLayerIds.includes(1))
    )
      return (
        <Paper className={clsx(classes.root, classes.secondBox)}>
          <Typography variant="body1">
            {i18n.t("mapSearchStrategyToggle.partialLayers")}
          </Typography>
        </Paper>
      );
  };

  const renderContent = () => {
    if (loading) return <CircularProgress size={18} />;

    if (layersVisible || pastDefaultZoomRange) {
      if (viewportChanged) {
        return (
          <>
            <Button onClick={handleOnDemandSearch}>
              <Typography variant="body1" className={classes.searchOnClick}>
                {i18n.t("mapSearchStrategyToggle.searchOnDemand")}
              </Typography>
            </Button>
          </>
        );
      }

      return (
        <>
          <label>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              color="default"
            />
            <Typography variant="body1">
              {i18n.t("mapSearchStrategyToggle.searchOnMapMove")}
            </Typography>
          </label>
        </>
      );
    }

    return (
      <Typography variant="body1">
        {i18n.t("mapSearchStrategyToggle.belowZoomThreshold")}
      </Typography>
    );
  };

  return (
    <>
      <Paper className={classes.root}>{renderContent()}</Paper>
      {renderPartialLayer()}
    </>
  );
};

export default withStyles(styles)(SearchStrategyToggle);
