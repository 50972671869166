import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

import i18n from "../../../../i18n";
import { getFileIcon } from "./utils";

interface IProps {
  libraryResource: any;
  isSelected: boolean;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    selected: {
      border: "2px solid #60C6FF",
    },
    thumbnailImg: {
      height: "100%",
      width: "100%",
    },
  });

type ClassKey = "root" | "selected" | "thumbnailImg";
type PropsType = IProps & WithStyles<ClassKey>;

const LibraryResourceThumbnail: React.FC<PropsType> = (props) => {
  const { classes, libraryResource, isSelected } = props;

  let content;

  if (libraryResource.signedGetThumbnailUrl) {
    content = (
      <img
        src={libraryResource.signedGetThumbnailUrl}
        alt={i18n.t("thumbnailAltText", {
          resourceName: libraryResource.resourcesName,
        })}
        className={classes.thumbnailImg}
      />
    );
  } else {
    const Icon = getFileIcon(libraryResource.extension.toLowerCase());

    content = (
      <Typography variant="body1" color="textPrimary">
        <Icon />
      </Typography>
    );
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.selected]: isSelected,
      })}
    >
      {content}
    </div>
  );
};

export default withStyles(styles)(LibraryResourceThumbnail);
