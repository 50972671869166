import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import CloseButton from "../CloseButton";
import i18n from "../../../i18n";

interface IProps {
  title: string;
  handleClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "space-between",
    },
    header: {
      "& > h2": {
        marginBottom: 10,
      },
    },
  });

type ClassKey = "root" | "header";
type PropsType = IProps & WithStyles<ClassKey>;

const ModalHeader: React.FC<PropsType> = (props) => {
  const { classes, title, handleClose } = props;
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h2">{title}</Typography>
        <label id="requiredModalInformation">
          * {i18n.t("requiredModalInformation")}
        </label>
      </div>
      <CloseButton onClick={handleClose} />
    </div>
  );
};

export default withStyles(styles)(ModalHeader);
