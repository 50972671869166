import React, { useEffect, useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Modal,
  Fade,
  Typography,
  IconButton,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SaveIcon from "@material-ui/icons/Save";
import LaunchIcon from "@material-ui/icons/Launch";

import LibraryResourceThumbnail from "./LibraryResourceThumbnail";
import CloseButton from "../../../../common/components/CloseButton";
import i18n from "../../../../i18n";
import { IModalContentProps } from "../../../../common/components/Modal/ModalManager";
import useKeyboardEvent from "../../../../common/hooks/useKeyboardEvent";
import { isImage, getFileIcon } from "./utils";
import PdfPreview from "../../../../common/components/PdfPreview";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

interface IProps {
  libraryResources: any[];
  startIndex: number;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      color: theme.palette.primary.contrastText,
      "& header": {
        display: "flex",
        justifyContent: "space-between",
      },
      "& footer": {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(80px, 1fr))",
        gridGap: theme.spacing(2),
        alignItems: "center",
      },
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    previewContainer: {
      flex: 1,
      position: "relative",
    },
    preview: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& img": {
        maxHeight: "100%",
        maxWidth: "100%",
      },
    },
    thumbnailContainer: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    iconButton: {
      color: "currentColor",
    },
    fallback: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    headerInfo: {
      display: "flex",
      flexDirection: "column",
    },
    inlineDownload: {
      display: "flex",
      alignItems: "bottom",
    },
    pageCtrl: {
      position: "absolute",
      bottom: "5%",
      left: "50%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      background: "white",
      color: "grey",
      transform: "translateX(-50%)",
      transition: "opacity ease-in-out 0.2s",
      boxShadow: "0 30px 40px 0 rgba(16,36,94, 0.2)",
      "& p": {
        color: "black",
      },
    },
  });

type ClassKey =
  | "root"
  | "previewContainer"
  | "preview"
  | "thumbnailContainer"
  | "iconButton"
  | "fallback"
  | "inlineDownload"
  | "pageCtrl"
  | "headerInfo";
type PropsType = IProps & IModalContentProps & WithStyles<ClassKey>;

/*
  BLOCK: pdf preview, seems to depend on ContentType/ContentDisposition header
  - this could also be what is overriding our custom file names
  - i.e. url/resource has already decided what it wants to call itself
  - https://stackoverflow.com/questions/14150854/aws-s3-display-file-inline-instead-of-force-download
  - https://stackoverflow.com/questions/46690640/force-s3-pdf-to-be-viewed-in-browser-instead-of-download?rq=1
  - N.B. doing this will likely have an effect on the current download functionality!
    - hopefully this will mean that the content comes through without download
      plans already made and our custom download code will take over
*/

const LibraryPreview: React.FC<PropsType> = (props) => {
  let { classes, open, handleClose, startIndex } = props;
  const [previewIndex, setPreviewIndex] = useState<number>(startIndex);
  const {
    libraryResources,
    blockNext,
    blockBack,
    paginationPage,
    totalPages,
    handleGoNextButton,
    handleGoBackButton,
  } = useSelector((state: RootState) => state.modal.modalProps);

  const [currentResources, setCurrentResources] = useState(libraryResources);

  const currentPreviewItem = libraryResources[previewIndex];

  const next = () => {
    if (open) {
      setPreviewIndex((previewIndex + 1) % currentResources.length);
    }
  };

  const prev = () => {
    if (open) {
      setPreviewIndex(
        (previewIndex + libraryResources.length - 1) % currentResources.length,
      );
    }
  };

  useKeyboardEvent("ArrowLeft", prev);
  useKeyboardEvent("ArrowRight", next);

  let content: any = null;

  useEffect(() => {
    if (libraryResources) {
      setCurrentResources(libraryResources);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [libraryResources]);

  if (open) {
    if (currentPreviewItem?.extension?.toLowerCase() === "pdf") {
      content = <PdfPreview fileUrl={currentPreviewItem.signedGetUrl} />;
    } else {
      const Icon = getFileIcon(currentPreviewItem.extension.toLowerCase());
      content = (
        <>
          {isImage(currentPreviewItem.extension) ? (
            <img
              src={currentPreviewItem.signedGetUrl}
              alt={i18n.t("previewAltText", {
                resourceName: currentPreviewItem.resourcesName,
              })}
            />
          ) : (
            <div className={classes.fallback}>
              <Icon height="80px" width="80px" />
              <Typography variant="caption" color="inherit">
                {i18n.t("previewNotSupported", {
                  resourceType: `.${currentPreviewItem.extension}`,
                })}
              </Typography>
            </div>
          )}

          {currentPreviewItem.extension === "speckle" ? (
            <IconButton
              aria-label={i18n.t("viewInSpeckle")}
              size="medium"
              className={classes.pageCtrl}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={classes.inlineDownload}
                href={currentPreviewItem.resourcesPath?.toString()}
              >
                <LaunchIcon fontSize="large" color="primary" />
              </a>
            </IconButton>
          ) : (
            <IconButton
              aria-label={i18n.t("download")}
              size="medium"
              className={classes.pageCtrl}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={classes.inlineDownload}
                href={currentPreviewItem.signedGetUrl?.toString()}
              >
                <SaveIcon fontSize="large" color="primary" />
              </a>
            </IconButton>
          )}
        </>
      );
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={i18n.t("previewModalLabel")}
      closeAfterTransition
      BackdropProps={{
        style: {
          background: "rgba(0, 0, 0, 0.9)",
        },
      }}
    >
      <Fade in={open}>
        <div className={classes.root}>
          <header>
            <div className={classes.headerInfo}>
              <Typography variant="caption" color="inherit">
                {currentPreviewItem?.resourcesName}
              </Typography>

              <Typography variant="caption" color="inherit">
                {currentPreviewItem?.resourcesResourcetype?.resourcetypesName}
              </Typography>
            </div>

            <CloseButton onClick={handleClose} fontSize="large" />
          </header>

          <div className={classes.previewContainer}>
            <div className={classes.preview}>
              <IconButton
                aria-label={i18n.t("previous")}
                size="medium"
                onClick={prev}
                className={classes.iconButton}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>

              {content}

              <IconButton
                aria-label={i18n.t("next")}
                size="medium"
                onClick={next}
                className={classes.iconButton}
              >
                <ChevronRightIcon fontSize="large" />
              </IconButton>
            </div>
          </div>

          <footer>
            <div>
              {paginationPage > 1 && `(${paginationPage - 1} / ${totalPages})`}
              <IconButton
                aria-label={i18n.t("previous")}
                size="medium"
                className={classes.iconButton}
                onClick={() => handleGoBackButton()}
                disabled={blockBack}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
            </div>

            {currentResources.map((libraryResource, i) => (
              <div
                key={libraryResource.resourcesId}
                className={classes.thumbnailContainer}
                onClick={() => setPreviewIndex(i)}
              >
                <LibraryResourceThumbnail
                  libraryResource={libraryResource}
                  isSelected={i === previewIndex}
                />
              </div>
            ))}
            <div>
              {paginationPage !== totalPages &&
                `(${paginationPage + 1} / ${totalPages})`}
              <IconButton
                aria-label={i18n.t("next")}
                size="medium"
                className={classes.iconButton}
                onClick={() => handleGoNextButton()}
                disabled={blockNext}
              >
                <ChevronRightIcon fontSize="large" />
              </IconButton>
            </div>
          </footer>
        </div>
      </Fade>
    </Modal>
  );
};

export default withStyles(styles)(LibraryPreview);
