import { gql } from "@apollo/client";

export const CREATE_OBJECT = gql`
  mutation createObject(
    $objectName: String
    $objectLongId: String
    $objectGeom: String
    $objectLayer: ID
    $objectDatevalidfrom: DateTime
    $objectStatus: ID
  ) {
    updateObject(
      input: {
        objectName: $objectName
        objectLongId: $objectLongId
        objectGeom: $objectGeom
        objectLayer: $objectLayer
        objectDatevalidfrom: $objectDatevalidfrom
        objectStatus: $objectStatus
      }
    ) {
      objectId
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_SCOPE_OBJECT = gql`
  mutation createScopeObject($scopeId: ID!, $objectId: ID!) {
    updateScopeObject(input: { scope: $scopeId, object: $objectId }) {
      id
      scope
      object
      errors {
        field
        messages
      }
    }
  }
`;
