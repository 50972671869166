import React, { useEffect, useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import SourceSelect from "./SourceSelect";
import AirReports from "./AirReports";
import i18n from "../../../i18n";
import { RootState } from "../../../store";
import { EPanelSize } from "./Tabular";
import { IProjectConfig } from "../../../store/selectedProject/actions";

interface IProps {
  setIsAssigningSource: (flag: boolean) => void;
  setIsEditToggledOn: (flag: boolean) => void;
  setSelectAll: (flag: boolean) => void;
  sources: any[];
  prevSelectedObjects: any;
  selectAll: boolean;
  setSources: (newSources: any[]) => void;
  size: EPanelSize;
}

enum ETabs {
  sources,
  airReport,
}

const tabs: string[] = ["sources", "airReport"];

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      overflow: "hidden",
    },
    tabsContainer: {
      minHeight: 26,
      color: theme.palette.text.primary,
    },
    tabsRoot: {
      minHeight: 0,
      margin: -theme.spacing(),
    },
    tabsIndicator: {
      backgroundColor: theme.palette.primary.main,
    },
    tab: {
      minHeight: 26,
      minWidth: 30,
      borderBottom: `2px solid ${theme.palette.secondary.dark}`,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 11,
      textTransform: "uppercase",
      textAlign: "center",
      opacity: 1,
    },
    tabsContent: {
      flex: 1,
      position: "relative",
      marginTop: theme.spacing(),
    },
  });

type ClassKey =
  | "root"
  | "tabsContainer"
  | "tabsRoot"
  | "tabsIndicator"
  | "tabsContent"
  | "tab";
type PropsType = IProps & WithStyles<ClassKey>;

const UpperWorkspacePanel: React.FC<PropsType> = (props) => {
  const {
    classes,
    setIsEditToggledOn,
    setIsAssigningSource,
    setSelectAll,
    sources,
    selectAll,
    setSources,
    size,
    prevSelectedObjects,
  } = props;

  const [currentTab, setCurrentTab] = useState<ETabs>(ETabs["sources"]);
  const [
    isAirSharepointSupported,
    setIsAirSharepointSupported,
  ] = useState<boolean>(false);

  const supportedAirSharepointLayersId = useSelector((state: RootState) =>
    state.layerGroups
      .find((x) => x.objectlayergroupIshierarchical)
      ?.objectlayers.map((y) => y.objectlayerId),
  );

  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );
  const projectConfig = useSelector(
    (state: RootState) => state.selectedProject,
  ) as IProjectConfig;

  useEffect(() => {
    setIsAirSharepointSupported(
      selectedObjects.length > 0 &&
        !!supportedAirSharepointLayersId?.includes(
          selectedObjects[0].objectLayer.objectlayerId,
        ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObjects]);

  return (
    <div className={classes.root}>
      {size !== EPanelSize.small && (
        <div className={classes.tabsContainer}>
          <Tabs
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
            value={currentTab}
            onChange={(_, value) => setCurrentTab(value)}
          >
            {tabs.map((tab) => {
              if (
                ETabs[tab] === ETabs.airReport &&
                (!isAirSharepointSupported || !projectConfig?.air.enabled)
              )
                return null;

              return (
                <Tab
                  key={tab}
                  className={classes.tab}
                  label={i18n.t(tab)}
                  value={ETabs[tab]}
                />
              );
            })}
          </Tabs>
        </div>
      )}
      {currentTab === ETabs["sources"] && (
        <SourceSelect
          setIsAssigningSource={setIsAssigningSource}
          setIsEditToggledOn={setIsEditToggledOn}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          sources={sources}
          setSources={setSources}
          isAirSharepointSupported={isAirSharepointSupported}
          size={size}
          prevSelectedObjects={prevSelectedObjects}
        />
      )}
      <div className={classes.tabsContent}>
        {currentTab === ETabs["airReport"] && <AirReports />}
      </div>
    </div>
  );
};

export default withStyles(styles)(UpperWorkspacePanel);
