import React, { useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@material-ui/core";

import i18n from "../../../../i18n";
import { IModalContentProps } from "../../../../common/components/Modal/ModalManager";
import ModalHeader from "../../../../common/components/Modal/ModalHeader";

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      maxHeight: 450,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      padding: theme.spacing(2),
      borderRadius: 20,
      "@global": {
        "*::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
          backgroundColor: "rgba(206, 206, 206, .4)",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "rgba(26, 76, 87, .6)",
        },
      },
    },
    textHighlight: {
      color: theme.palette.primary.main,
    },
    editedBy: {
      display: "flex",
      alignItems: "center",
      fontSize: 10,
    },
    filter: {
      width: "50%",
      marginLeft: theme.spacing(2),
    },
  });

type ClassKey = "root" | "textHighlight" | "editedBy" | "filter";
type PropsType = IModalContentProps & WithStyles<ClassKey>;

const ParameterValueHistory: React.FC<PropsType> = (props) => {
  const { classes, rowData, handleClose, renderValue } = props;
  const [searchText, setSearchText] = useState("");

  const history = JSON.parse(rowData.parametervaluesHistory);
  const parameterObjectLongId = rowData.parametervaluesObject.objectLongId;
  const rows = history
    ?.filter((h) => h.uploader_email?.toLowerCase().includes(searchText))
    .map((h, i) => {
      return {
        order_number: i + 1,
        uploader_email: h.uploader_email,
        date: new Date(h.date).toLocaleString(),
        value: h.value,
      };
    });

  const handleChange = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  return (
    <div className={classes.root}>
      <ModalHeader
        title={`${i18n.t("edit.editHeader")} ${parameterObjectLongId}`}
        handleClose={handleClose}
      />
      {
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>No</Typography>
                  </TableCell>
                  <TableCell className={classes.editedBy}>
                    <Typography>{i18n.t("edit.editedBy")}</Typography>
                    <TextField
                      id="standard-basic"
                      onChange={(e) => handleChange(e)}
                      className={classes.filter}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>{i18n.t("date")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{i18n.t("value")}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Typography>{row.order_number}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography>{row.uploader_email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{row.date}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{renderValue(row.value) || ""}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
    </div>
  );
};

export default withStyles(styles)(ParameterValueHistory);
