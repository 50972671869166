import { Action, Reducer } from "redux";

import { KnownAction } from "./actions";
import { IUser } from "../interfaces";

export type TUserState = IUser | null;

export const userReducer: Reducer<TUserState> = (
  state = null,
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "RECEIVE_USER":
      return {
        ...state,
        ...action.user,
      };
    case "UPDATE_USER_ACCESS_ROLES":
      return {
        ...state,
        ...action.user,
      };
    case "NO_USER":
      return state;
    case "REQUEST_USER":
      return state;
    case "REMOVE_USER":
      return null;
    default:
      return state;
  }
};
