import { useState, useEffect } from "react";

const useLocalStorage = (key: string, defaultValue: any) => {
  const [value, setValue] = useState<typeof defaultValue>(() => {
    // Check for existing value in localStorage to seed state
    const storedValue = window.localStorage.getItem(key);

    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
