import { Action, Reducer } from "redux";
import { ISource } from "../../data/types/typeStitches";

import { KnownAction } from "./actions";

export type TSelectedSources = ISource[];

export const selectedSourcesReducer: Reducer<TSelectedSources> = (
  state = [],
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "RESET_SELECTED_SOURCES":
      return [];
    case "INSERT_SELECTED_SOURCE":
      return [...state, action.selectedSource];
    case "REMOVE_SELECTED_SOURCE":
      return state.filter((source) => {
        return source.sourcesId !== action.selectedSource.sourcesId;
      });
    case "SELECT_ALL_SOURCES":
      return [...action.selectedSources];
    default:
      return state;
  }
};
