import { gql } from "@apollo/client";

export const GET_SEARCH_RESULTS = gql`
  query GET_SEARCH_RESULTS($term: String, $objectType: String) {
    allObjects(
      first: 10
      search: $term
      order: "objectName"
      objectLayer_ObjectlayerName: $objectType
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          objectId
          objectLongId
          objectName
          objectGeom
          objectSearchLongStr
          objectLayer {
            objectlayerName
          }
        }
      }
    }
  }
`;
