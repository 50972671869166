import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import i18n from "../../i18n";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "space-around",
      alignItems: "center",
      width: "80vw",
      height: "20vh",
      marginTop: theme.spacing(8),
    },
    text: {
      color: theme.palette.text.secondary,
      fontSize: theme.spacing(3),
      marginBottom: theme.spacing(6),
    },
  });

type ClassKey = "root" | "container" | "text";
type PropsType = IProps & WithStyles<ClassKey>;

const Authorizing: React.FC<PropsType> = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.text}>
          {i18n.t("authorizing")}
        </Typography>
        <CircularProgress size={64} />
      </div>
    </div>
  );
};

export default withStyles(styles)(Authorizing);
