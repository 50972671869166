import React, { useState, useEffect, useMemo } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete } from "@material-ui/lab";
import debounce from "lodash.debounce";
import { WebMercatorViewport } from "react-map-gl";

import i18n from "../../../../i18n";
import { IMapViewport } from "../../Core";
import { useSnackbar } from "notistack";
import { IProjectConfig } from "../../../../store/selectedProject/actions";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import { isLocalEnv } from "../../../../common/utils";
import p500dev from "../../../../config/projects/p500dev";

interface IProps {
  updateViewport: (updatedViewport: IMapViewport) => void;
}

interface ILocation {
  place_name: string;
  center: [number, number]; // [longitude, latitude]
  bbox: [number, number, number, number]; // [minX, minY, maxX, maxY]
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    autocomplete: {
      width: "100%",
      height: "100%",
      "& .MuiAutocomplete-inputRoot": {
        padding: 0,
        height: "100%",
      },
      "& .MuiAutocomplete-endAdornment": {
        top: "initial",
      },
      "& > div": {
        height: "inherit",
      },
    },
    textFieldInput: {
      height: "100%",
      "& > fieldset": {
        border: "none",
      },
      "& > input": {
        padding: "1em",
        height: "100%",
      },
    },
  });

type ClassKey = "root" | "autocomplete" | "textFieldInput";
type PropsType = IProps & WithStyles<ClassKey>;

const LocationPanel: React.FC<PropsType> = (props: PropsType) => {
  const [selectedValue, setSelectedValue] = useState<ILocation | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<ILocation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [called, setCalled] = useState<boolean>(false);

  const { updateViewport, classes } = props;
  const { enqueueSnackbar } = useSnackbar();

  const projectConfig = useSelector(
    (state: RootState) => state.selectedProject,
  ) as IProjectConfig;

  const createGeocodingRequestUrl = (query: string): string => {
    const encodedText = encodeURIComponent(query);
    const requestOptions = {
      language: i18n.language.split("-")[0],
      access_token: isLocalEnv()
        ? p500dev.mapbox.token
        : projectConfig?.mapbox.token,
    };

    let requestUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedText}.json?`;

    requestUrl += Object.entries(requestOptions)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    return requestUrl;
  };

  // Don't recreate this, otherwise debounce is pointless (lose timer info)
  const queryLocation = useMemo(
    () => {
      return debounce(async (text) => {
        const validators: Array<(string) => boolean> = [(t) => t.length >= 3];

        // If a single validator fails, return early without making API call
        const failedValidation = validators.some(
          (validator) => !validator(text),
        );

        if (failedValidation) {
          return;
        }

        const requestUrl = createGeocodingRequestUrl(text);

        try {
          setLoading(true);

          const request = await fetch(requestUrl);
          const data = await request.json();

          setOptions(data.features);
        } catch (err) {
          enqueueSnackbar(i18n.t("error.fetchMapBoxQuery"), {
            variant: "error",
          });
        }

        setLoading(false);

        if (!called) {
          setCalled(true);
        }
      }, 400);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [called, setCalled, setLoading],
  );

  useEffect(() => {
    if (inputValue) {
      queryLocation(inputValue);
    }
  }, [inputValue, queryLocation]);

  const handleChange = (_event, newValue: ILocation | null) => {
    setInputValue("");
    setSelectedValue(newValue);

    if (newValue) {
      let newViewport: IMapViewport;

      if (newValue.bbox) {
        // Measured in pixels
        // TODO: take sidebar width into account (also header height?)
        const offsetX = 0;
        // Map is 100vh/100vw and WebMercatorViewport needs number values
        const { innerHeight: height, innerWidth: width } = window;
        const [minX, minY, maxX, maxY] = newValue.bbox;

        // Create new map viewport values so we can fit bounds of map
        newViewport = new WebMercatorViewport({ width, height }).fitBounds(
          [
            [minX, minY],
            [maxX, maxY],
          ],
          { offset: [offsetX, 0] },
        );
      } else {
        newViewport = {
          longitude: newValue.center[0],
          latitude: newValue.center[1],
        };
      }

      updateViewport(newViewport);
    }
  };

  return (
    <Autocomplete
      className={classes.autocomplete}
      options={options}
      getOptionLabel={(option) => option.place_name}
      autoComplete
      includeInputInList
      value={selectedValue}
      filterSelectedOptions
      loading={loading}
      loadingText={i18n.t("searchHint.loading")}
      noOptionsText={
        called ? i18n.t("searchHint.noResults") : i18n.t("searchHint.location")
      }
      onChange={handleChange}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => (
        // TODO: extract
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            className: classes.textFieldInput,
            endAdornment: (
              <InputAdornment position="end">
                {loading ? <CircularProgress size={18} /> : <SearchIcon />}
              </InputAdornment>
            ),
          }}
          placeholder={i18n.t("typeAtLeast3Char")}
          variant="outlined"
          fullWidth
        />
      )}
      renderOption={(option) => (
        <Typography variant="body1">{option.place_name}</Typography>
      )}
    />
  );
};

export default withStyles(styles)(LocationPanel);
