import { Action, Reducer } from "redux";
import { ILayerGroup } from "../../data/types/typeStitches";

import { KnownAction } from "./actions";

export const layerGroupsReducer: Reducer<ILayerGroup[]> = (
  state = [],
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "RESET_LAYER_GROUPS":
      return [];
    case "INSERT_LAYER_GROUPS":
      return [...state, ...action.layerGroups];
    default:
      return state;
  }
};
