import get from "lodash.get";

import {
  numberSorter,
  dateSorter,
  stringSorter,
} from "../../../../../utils/sorters";

type DataType = "Varchar" | "Boolean" | "Integer" | "Numeric" | "Date";

export interface IValueType {
  value: any;
  type?: DataType;
}

const getVal = (v, key) => {
  const isArray = typeof key === "object" && !!key.length;
  return isArray ? concatDataKeysValues(v, key) : get(v, key);
};

export const concatDataKeysValues = (rowData, dataKeys) => {
  let conValue = "";

  dataKeys.forEach((key, index) => {
    const sign = dataKeys.length - 1 === index ? "" : ".";
    conValue += `${get(rowData, key)}${sign}`;
  });
  return conValue;
};

export const paramValSorter = (list, sortBy, isAsc) => {
  list.sort((a, b) => {
    const isValueColumn = sortBy === "parametervaluesValue";
    const typeKey =
      "parametervaluesParameter.parametersParameterdef.parameterdefsType";

    const constrObj = (v) => {
      if (!isValueColumn) return { value: getVal(v, sortBy) };
      return { value: getVal(v, sortBy), type: get(v, typeKey) };
    };

    const aObj = constrObj(a);
    const bObj = constrObj(b);

    // Add a default value making sure undefineds get sorted correctly
    const undefinedVal = { value: "-999" };
    const aChecked = aObj.value ? aObj : undefinedVal;
    const bChecked = bObj.value ? bObj : undefinedVal;

    return sorter(aChecked, bChecked, isAsc);
  });
  return list;
};

export const orderSorter = (list) => {
  list.sort((a, b) => {
    const aIsAssigned = !!a.parametervaluesSource?.sourcesId;
    const bIsAssigned = !!b.parametervaluesSource?.sourcesId;
    const aHasParamMapping = !!a.parametervaluesParameter
      .parametersParametermappings.edges?.length;
    const bHasParamMapping = !!b.parametervaluesParameter
      .parametersParametermappings.edges?.length;

    const aIndex = aIsAssigned
      ? a.parametervaluesParameter.parametersParametermappings.edges.findIndex(
          ({ node }) =>
            node.parametermappingsMapreference.sourcesId ===
            a.parametervaluesSource.sourcesId,
        )
      : 0;
    const bIndex = bIsAssigned
      ? b.parametervaluesParameter.parametersParametermappings.edges.findIndex(
          ({ node }) =>
            node.parametermappingsMapreference.sourcesId ===
            b.parametervaluesSource.sourcesId,
        )
      : 0;

    const getLabel = (entry) =>
      `${entry.parametervaluesParameter.parametersParameterdef.parameterdefsName}.${entry.parametervaluesParameter.parametersElementcode}`;

    if (
      !aHasParamMapping ||
      !bHasParamMapping ||
      aIndex === -1 ||
      bIndex === -1
    ) {
      // Some parameters don't have a mapping for the selected source - only dynamicSources have a mapping
      // In that case we cant use sortOrder
      return stringSorter(getLabel(a), getLabel(b), true);
    }
    const aSortOrder = +a.parametervaluesParameter.parametersParametermappings
      .edges[aIndex].node.parametermappingsSortorder;
    const bSortOrder = +b.parametervaluesParameter.parametersParametermappings
      .edges[bIndex].node.parametermappingsSortorder;

    if (aSortOrder === bSortOrder) {
      return stringSorter(getLabel(a), getLabel(b), true);
    }

    return numberSorter(aSortOrder, bSortOrder, true);
  });
  return list;
};

export const sorter = (a: IValueType, b: IValueType, isAsc: boolean) => {
  if ((a.type || b.type) && (a.value !== "None" || b.value !== "None")) {
    // Type is only present when sorting `values` column
    // Empty Integers/Numerics/Dates are returned as `None` string by API
    if (
      (a.type === "Integer" || a.type === "Numeric") &&
      (b.type === "Integer" || b.type === "Numeric")
    ) {
      return numberSorter(a.value, b.value, isAsc);
    }

    if (a.type === "Date" && b.type === "Date") {
      return dateSorter(a.value, b.value, isAsc);
    }
  }
  return stringSorter(a.value, b.value, isAsc);
};

export const filter = (list, filters) => {
  if (!filters.length) return list;

  const filteredList = list.filter((row) => {
    const isIncludedList: boolean[] = [];

    filters.forEach((filter) => {
      const value = getVal(row, filter.filterBy);
      isIncludedList.push(
        value?.toUpperCase().includes(filter.searchText?.toUpperCase()),
      );
    });

    return isIncludedList.every((isIncluded) => isIncluded);
  });

  if (!filteredList.length) return [{}];
  return filteredList;
};

export const isParameterValue = (rowData) => !!rowData?.parametervaluesId;
