import { useEffect, useState } from "react";
import i18n from "../../../../i18n";
import { logSentry } from "../../../../utils/logger";

export const useGetMdFiles = (path) => {
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [content, setContent] = useState<any>("");
  const language = i18n.language === "en-US" ? "EN" : "NL";

  useEffect(() => {
    setContentLoading(true);

    const getFiles = async () => {
      let rawContent;
      let parsedContent;
      try {
        rawContent = await import(`../md-files/${path}-${language}.md`);
        const introRes = await fetch(rawContent.default);
        parsedContent = await introRes.text();
      } catch (error) {
        logSentry("warning", "Problem with fetching md files", error);
      }
      if (parsedContent) {
        setContent(parsedContent);
      }
      setContentLoading(false);
    };

    getFiles();
  }, [language, path]);

  return { content, contentLoading };
};
