import React, { useEffect } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";

import { GET_SCOPE_PROJECT_GEOMS } from "../../../data/scopeQueries";

import i18n from "../../../i18n";
import { filterUniqObjs } from "../../../common/utils";
import SelectFilter from "./SelectFilter";
import { useDispatch, useSelector } from "react-redux";
import { filteredObjectsActions } from "../../../store/filteredObjects";
import { RootState } from "../../../store";
import { stringSorter } from "../../../utils/sorters";
import { logSentry } from "../../../utils/logger";
import FilterHOC from "../FilterHOC";
import { IBareFilter, ISelectFilter } from "../Core";

interface IProps {
  selectedScopes: IBareFilter[];
  setSelectedScopes: (any) => void;
  selectedProjects: IBareFilter[];
  setSelectedProjects: (any) => void;
  resetMapLayers: () => void;
  activeFilters: ISelectFilter[];
  setActiveFilters: (ISelectFilter) => void;
  scopes: IBareFilter[];
  projects: IBareFilter[];
  availableScopes: IBareFilter[];
  allScopesAndProjects: any;
  setAvailableScopes: (any) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    container: {
      display: "flex",
      border: "1px solid #bebebe",
      marginTop: theme.spacing(1),
      width: "98%",
    },
    label: {
      width: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#353535",
      borderRight: "1px solid #bebebe",
    },
    menu: { width: "50%" },
  });

type ClassKey = "root" | "title" | "label" | "menu" | "container";
type PropsType = IProps & WithStyles<ClassKey>;

export interface IFilter extends IBareFilter {
  objectlayerName: string;
  objectlayerId: string;
  objectlayerSearch: boolean;
  objectlayerOrder: number;
}

const Filter: React.FC<PropsType> = (props) => {
  const {
    selectedScopes,
    setSelectedScopes,
    selectedProjects,
    setSelectedProjects,
    resetMapLayers,
    activeFilters,
    setActiveFilters,
    scopes,
    projects,
    availableScopes,
    allScopesAndProjects,
    setAvailableScopes,
    classes,
  } = props;
  const dispatch = useDispatch();
  const filteredObjects = useSelector(
    (state: RootState) => state.filteredObjects,
  );

  const [getGeoms, geoms] = useLazyQuery(GET_SCOPE_PROJECT_GEOMS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ allScopes }) => {
      if (filteredObjects.length) {
        dispatch(filteredObjectsActions.resetFilteredObjects());
      }

      const objectsInScope = allScopes.edges
        .map(({ node }) => node)
        .map(({ scopeObjects }) => scopeObjects.edges.map(({ node }) => node))
        .flat();
      dispatch(filteredObjectsActions.insertFilteredObjects(objectsInScope));
    },
  });

  useEffect(() => {
    // Narrow down the scope choices after project selection
    if (selectedProjects.length) {
      const relatedScopes = allScopesAndProjects.data.allScopes.edges
        .filter(({ node }) =>
          selectedProjects
            .map(({ id }) => id)
            .includes(node.scopeProject.projectId),
        )
        .map(({ node }) => {
          return { id: node.scopeId, name: node.scopeName };
        });
      setAvailableScopes(
        filterUniqObjs(relatedScopes, "id").sort((a, b) =>
          stringSorter(a.name, b.name, true),
        ),
      );

      if (selectedScopes.length) {
        // Make sure any previously selected scope is included in new set
        const getOutOfRangeSelectedScopeIds = () => {
          const availableScopeIds = availableScopes.map(({ id }) => id);
          let outOfRangeScopes: string[] = [];
          selectedScopes.forEach((selectedScope) => {
            if (!availableScopeIds.includes(selectedScope.id)) {
              outOfRangeScopes.push(selectedScope.id);
            }
          });

          return outOfRangeScopes;
        };

        const outOfRangeSelectedScopeIds: string[] = getOutOfRangeSelectedScopeIds();
        if (outOfRangeSelectedScopeIds.length) {
          // Deselect any scopes now out of range
          const copiedScopes = [...selectedScopes];
          outOfRangeSelectedScopeIds.forEach((id) => {
            const index = copiedScopes.findIndex(
              (selectedScope) => selectedScope.id === id,
            );
            copiedScopes.splice(index, 1);
          });
          setSelectedScopes(copiedScopes);
        }
      }
    }
  }, [selectedProjects]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedScopes.length || selectedProjects.length) {
      getGeoms({
        variables: {
          scopeIds: selectedScopes.map(({ id }) => id),
          projectIds: selectedProjects.map(({ id }) => id),
        },
      });
    }

    if (!selectedProjects.length && !selectedScopes.length) {
      dispatch(filteredObjectsActions.resetFilteredObjects());
    }
  }, [selectedScopes, selectedProjects]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActiveFilters([
      ...activeFilters.filter((filter) => filter.type !== "mapLayers"),
      ...selectedProjects.map<ISelectFilter>((project) => ({
        id: project.id,
        name: project.name,
        type: "mapLayers",
        deselect: () =>
          setSelectedProjects(
            selectedProjects.filter((sp) => sp.id !== project.id),
          ),
      })),
      ...selectedScopes.map<ISelectFilter>((scope) => ({
        id: scope.id,
        name: scope.name,
        type: "mapLayers",
        deselect: () =>
          setSelectedScopes(selectedScopes.filter((sp) => sp.id !== scope.id)),
      })),
    ]);
  }, [selectedProjects, selectedScopes]); // eslint-disable-line react-hooks/exhaustive-deps

  if (allScopesAndProjects.loading || (!scopes.length && !projects.length))
    return null;

  if (allScopesAndProjects.error || geoms.error) {
    logSentry(
      "error",
      "querying allScopes resolvers",
      allScopesAndProjects.error,
    );
  }

  return (
    <FilterHOC
      title={
        <>
          <Typography variant="h2" color="textPrimary">
            {i18n.t("filter.filter")}
          </Typography>
          {geoms.loading ? (
            <CircularProgress size={18} />
          ) : (
            <Button variant="text" size="small" onClick={resetMapLayers}>
              {i18n.t("restoreDefaults")}
            </Button>
          )}
        </>
      }
      selects={
        !!scopes.length &&
        !!projects.length && (
          <>
            <div className={classes.container}>
              <Typography variant="h2" className={classes.label}>
                {i18n.t("project")}
              </Typography>
              <div className={classes.menu}>
                <SelectFilter
                  list={projects}
                  selectedItems={selectedProjects}
                  setSelectedItems={setSelectedProjects}
                  gridColumn={"1"}
                  placeHolder={i18n.t("project")}
                />
              </div>
            </div>
            <div className={classes.container}>
              <Typography variant="h2" className={classes.label}>
                {i18n.t("scope")}
              </Typography>
              <div className={classes.menu}>
                <SelectFilter
                  list={!!selectedProjects.length ? availableScopes : scopes}
                  selectedItems={selectedScopes}
                  setSelectedItems={setSelectedScopes}
                  gridColumn={"1"}
                  placeHolder={i18n.t("scope")}
                  hasDependency={!!selectedProjects.length}
                />
              </div>
            </div>
          </>
        )
      }
    />
  );
};

export default withStyles(styles)(Filter);
