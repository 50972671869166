import { Action, Reducer } from "redux";

import { KnownAction } from "./actions";
import { IObject } from "../../data/types/typeStitches";

export interface IRootObjects {
  rootObject: IObject | null;
  entryObject: IObject | { objectLongId: string } | null;
}

export const rootObjectsReducer: Reducer<IRootObjects> = (
  state: IRootObjects = { rootObject: null, entryObject: null },
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "RESET_ROOT_OBJECT":
      return { ...state, rootObject: null };
    case "INSERT_ROOT_OBJECT":
      return { ...state, rootObject: { ...action.rootObject } };
    case "RESET_ENTRY_OBJECT":
      return { ...state, entryObject: null };
    case "INSERT_ENTRY_OBJECT":
      // Clear any existing root object, replace any existing root object with new one
      return {
        rootObject: null,
        entryObject: {
          ...action.entryObject,
        },
      };
    default:
      return state;
  }
};
