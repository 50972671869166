import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import LibraryCard from "./LibraryCard";
import { modalActions } from "../../../../store/modal";
import { EModalTypes } from "../../../../store/interfaces";
import { IActivePage } from "./Library";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { RootState } from "../../../../store";

interface IPage {
  cursor: string;
}

interface IProps {
  libraryResources: any[];
  selectedLibraryResources: any[];
  toggleSelectedLibraryResource: (id) => void;
  getNextPage: (order: string, endCursor: string) => void;
  getPreviousPage: (order: string, startCursor: string) => void;
  currentPage: IActivePage;
  setCarouselMaxElements: (elements: number) => void;
  nextPageVars: any;
  previousPageVars: any;
  resourcesCursors: any[];
  sortByKey: string;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100%",
      height: 100,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    controlButton: {
      width: "fit-content",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:first-child": {
        paddingRight: 8,
      },
      "&:last-child": {
        paddingLeft: 8,
      },
    },
    carousel: {
      flex: 1,
      display: "grid",
      gridTemplateColumns: "repeat(10, 1fr)",
      justifyItems: "center",
      position: "relative",
      gap: "1rem",
    },
    carouselNotFilled: {
      justifyContent: "flex-start",
    },
    paginationContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    pagesEnumeration: {
      width: "100%",
      height: "fit-content",
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(4),
    },
    pagesEnumerationItem: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.secondary,
      fontSize: 13,
    },
    pagesEnumerationSelected: {
      color: theme.palette.primary.main,
    },
    loadingSpinner: {
      position: "absolute",
    },
  });

type ClassKey =
  | "root"
  | "controlButton"
  | "carousel"
  | "carouselNotFilled"
  | "paginationContainer"
  | "pagesEnumeration"
  | "pagesEnumerationItem"
  | "pagesEnumerationSelected"
  | "loadingSpinner";
type PropsType = IProps & WithStyles<ClassKey>;

const LibraryResourcesPaginated: React.FC<PropsType> = (props) => {
  const {
    classes,
    libraryResources,
    selectedLibraryResources,
    toggleSelectedLibraryResource,
    getNextPage,
    getPreviousPage,
    currentPage,
    setCarouselMaxElements,
    nextPageVars,
    previousPageVars,
    resourcesCursors,
    sortByKey,
  } = props;
  const dispatch = useDispatch();
  const [pages, setPages] = useState<IPage[]>([]);
  const [paginationPage, setPaginationPage] = useState(1);

  const { modalType, modalProps } = useSelector(
    (state: RootState) => state.modal,
  );

  const resourceTotalArea = 96; // 80px + 16px of margin right
  let carouselDiv: HTMLDivElement | null = null;

  const calculateMaxElements = () =>
    carouselDiv ? Math.floor(carouselDiv.offsetWidth / resourceTotalArea) : 0;
  useEffect(() => {
    if (carouselDiv) {
      let maxElements = calculateMaxElements();
      carouselDiv.style.gridTemplateColumns = `repeat(${maxElements}, 1fr)`;
      setCarouselMaxElements(maxElements);
    }
  }, [carouselDiv]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resourcesCursors.length > 0) {
      setPages(resourcesCursors as IPage[]);
    } else {
      setPages([]);
    }
  }, [resourcesCursors]);

  useEffect(() => {
    if (resourcesCursors.length > 0 && carouselDiv) {
      const nth = Math.floor(carouselDiv.offsetWidth / resourceTotalArea);

      const tempPages: IPage[] = [];
      for (let i = 0; i < resourcesCursors.length; i = i + nth) {
        tempPages.push(i === 0 ? "" : resourcesCursors[i - 1]);
      }

      setPages(tempPages);
    } else {
      setPages([]);
    }
  }, [resourcesCursors, carouselDiv]);

  const handlePaginationChange = (event, value) => {
    value === 1
      ? getNextPage(sortByKey, "")
      : getNextPage(sortByKey, pages[value - 1].cursor);
    setPaginationPage(value);
  };

  const handleGoBackButton = () => {
    getPreviousPage(sortByKey, currentPage.startCursor);
    setPaginationPage(paginationPage - 1);
  };

  const handleGoNextButton = () => {
    getNextPage(sortByKey, currentPage.endCursor);
    setPaginationPage(paginationPage + 1);
  };

  const modifyModalProps = (resources: any[], startIndex: number) => {
    dispatch(
      modalActions.modifyModalProps({
        ...modalProps,
        libraryResources: resources,
        startIndex,
        blockNext: !currentPage.hasNextPage,
        blockBack: !currentPage.hasPreviousPage,
        paginationPage,
        handleGoNextButton,
        handleGoBackButton,
      }),
    );
  };

  useEffect(() => {
    if (modalType) {
      modifyModalProps(libraryResources, 0);
    }
  }, [libraryResources, paginationPage]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className={classes.paginationContainer}>
      <div className={classes.root}>
        <div className={classes.controlButton}>
          <IconButton
            onClick={() => handleGoBackButton()}
            disabled={
              !currentPage.hasPreviousPage ||
              previousPageVars.loading ||
              nextPageVars.loading
            }
          >
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div
          className={classes.carousel}
          ref={(r) => (carouselDiv = r as HTMLDivElement)}
        >
          {previousPageVars.loading ||
            (nextPageVars.loading && (
              <CircularProgress size={64} className={classes.loadingSpinner} />
            ))}
          {!previousPageVars.loading &&
            !nextPageVars.loading &&
            libraryResources.map((libraryResource, i) => (
              <LibraryCard
                key={libraryResource.resourcesId}
                libraryResource={libraryResource}
                isSelected={
                  selectedLibraryResources[libraryResource.resourcesId] || false
                }
                showPreview={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  dispatch(
                    modalActions.openModal(EModalTypes.libraryPreview, {
                      modalProps: {
                        customModal: true,
                        startIndex: i,
                        blockNext: !currentPage.hasNextPage,
                        blockBack: !currentPage.hasPreviousPage,
                        libraryResources,
                        paginationPage,
                        totalPages: pages.length,
                        handleGoNextButton,
                        handleGoBackButton,
                      },
                    }),
                  );
                }}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  toggleSelectedLibraryResource(libraryResource.resourcesId);
                }}
              />
            ))}
        </div>
        <div className={classes.controlButton}>
          <IconButton
            onClick={() => handleGoNextButton()}
            disabled={
              !currentPage.hasNextPage ||
              previousPageVars.loading ||
              nextPageVars.loading
            }
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </div>
      {!!pages && pages.length > 1 && (
        <div className={classes.pagesEnumeration}>
          <Pagination
            count={pages.length}
            page={paginationPage}
            onChange={handlePaginationChange}
            hideNextButton={true}
            hidePrevButton={true}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                classes={{
                  page: classes.pagesEnumerationItem,
                  selected: classes.pagesEnumerationSelected,
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(LibraryResourcesPaginated);
