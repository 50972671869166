import { getProjectRoleMapping } from "../../common/auth";
import { getUrlParams } from "../../common/utils";
import { projectConfigMapping } from "../../config";
import { filteredObjectsActions } from "../filteredObjects";
import { AppThunk } from "../interfaces";
import { layerGroupActions } from "../layerGroups";
import { rootObjectActions } from "../rootObjects";
import { selectedObjectsActions } from "../selectedObjects";
import { treeObjectsActions } from "../treeObjects";
import { userActions } from "../user";
import { visibleLayerIdsActions } from "../visibleLayerIds";

export const selectedProjectActions = {
  insertSelectedProjectOnEntry: (projectName, history): AppThunk => (
    dispatch,
  ) => {
    const selectedProject = {
      name: projectName,
      ...projectConfigMapping[projectName],
    };

    if (getProjectRoleMapping[projectName]) {
      localStorage.setItem("lastProject", projectName);
    }

    const urlParams = getUrlParams(history);
    const append = () => {
      if (urlParams?.objectId) return `/object/${urlParams?.objectId}`;
      if (urlParams?.parameterValueId)
        return `/parameter/${urlParams?.parameterValueId}`;
      return "";
    };
    history.push(`/${projectName}${append()}`);
    dispatch({ type: "UPDATE_SELECTED_PROJECT", selectedProject });
  },
  updateSelectedProject: (projectName, history): AppThunk => (dispatch) => {
    localStorage.setItem("lastProject", projectName);

    const selectedProject = {
      name: projectName,
      ...projectConfigMapping[projectName],
    };

    history.push(`/${projectName}/`);
    dispatch(rootObjectActions.resetEntryObject());
    dispatch(rootObjectActions.resetRootObject());
    dispatch(selectedObjectsActions.resetSelectedObjects());
    dispatch(layerGroupActions.resetLayerGroups());
    dispatch(visibleLayerIdsActions.resetVisibleLayerIds());
    dispatch(treeObjectsActions.resetTreeObjects());
    dispatch(filteredObjectsActions.resetFilteredObjects());

    dispatch(userActions.updateUserAccessRoles(projectName));
    dispatch({ type: "UPDATE_SELECTED_PROJECT", selectedProject });
  },
};
