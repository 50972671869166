import { AppThunk } from "../interfaces";

export const visibleLayerIdsActions = {
  resetVisibleLayerIds: (): AppThunk => (dispatch) => {
    dispatch({ type: "RESET_VISIBLE_LAYER_IDS" });
  },
  insertVisibleLayerIds: (visibleLayerIds: number[]): AppThunk => (
    dispatch,
  ) => {
    dispatch({ type: "INSERT_VISIBLE_LAYER_IDS", visibleLayerIds });
  },
};
