import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

import SortBy, { ISorterKey } from "./SortBy";
import ToggleAll from "./ToggleAll";

interface IProps {
  items: any[];
  sortKeys: ISorterKey[];
  setItems?: (list: any[]) => void;
  setSortKey?: (selected: ISorterKey) => void;
  selectedItems: any[];
  selectAllItems: () => void;
  deselectAllItems: () => void;
  activeSortKey?: ISorterKey;
  setIsAsc?: (boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      minWidth: "83%",
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const SortPanel: React.FC<PropsType> = (props) => {
  const {
    classes,
    items,
    setItems,
    setSortKey,
    sortKeys,
    selectedItems,
    selectAllItems,
    deselectAllItems,
    activeSortKey,
    setIsAsc,
  } = props;

  return (
    <div className={classes.root}>
      <SortBy
        items={items}
        setItems={setItems}
        sortKeys={sortKeys}
        setSortKey={setSortKey}
        activeSortKey={activeSortKey}
        setIsAscParent={setIsAsc}
      />
      <ToggleAll
        selectableItems={items}
        selectedItems={selectedItems}
        selectAllItems={selectAllItems}
        deselectAllItems={deselectAllItems}
      />
    </div>
  );
};

export default withStyles(styles)(SortPanel);
