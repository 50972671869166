import { getLayerGroups } from "./layers";

export const getIsObjectHierarchical = (object) => {
  const layerGroups = getLayerGroups();
  const i = layerGroups.findIndex(
    (lg) =>
      lg.objectlayers.findIndex(
        (layer) => layer.objectlayerId === object.objectLayer.objectlayerId,
      ) !== -1,
  );
  return layerGroups[i]?.objectlayergroupIshierarchical;
};
