import { IProjectConfig } from "../../store/selectedProject/actions";

export default {
  name: "core",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja28xZHF3YzAwN201MnZvM21rYjJrOG10In0.eVevHpglBhx3SCoAC5o1xg",
  },
  graphql: {
    url: "https://prod.uk.dwh-api.arup.com/core/graphql/",
  },
  coordinates: {
    latitude: 48.7,
    longitude: 13.033333,
    zoom: 2,
  },
  map: {
    zoomTreshold: 4,
    layerSymbols: [
      {
        objectlayerIds: [8],
        symbol: "borehole",
        geomType: "Point",
      },
      {
        objectlayerIds: [9],
        symbol: "cpt",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [8, 9],
        minZoom: 4,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 0,
  air: {
    enabled: false,
  },
} as IProjectConfig;
