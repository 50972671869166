import { Action, Reducer } from "redux";
import { IObject } from "../../data/types/typeStitches";

import { KnownAction } from "./actions";

export const selectedObjectsReducer: Reducer<IObject[]> = (
  state = [],
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "RESET_SELECTED_OBJECTS":
      return [];
    case "INSERT_SELECTED_OBJECT":
      return [...state, action.selectedObject];
    case "INSERT_SELECTED_OBJECTS":
      return [...state, ...action.selectedObjects];
    case "REMOVE_SELECTED_OBJECT":
      return state.filter((object: IObject) => {
        return object.objectId !== action.selectedObject.objectId;
      });
    default:
      return state;
  }
};
