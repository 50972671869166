import {
  Button,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";

import i18n from "../../../../i18n";

import FilterHOC from "../../FilterHOC";
import FilterPill from "./FilterPill";
import { ISelectFilter } from "../../Core";

interface IProps {
  activeFilters: ISelectFilter[];
  clearFilters: () => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      gridColumn: "1 / span 2",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const FiltersTray: React.FC<PropsType> = (props) => {
  const { classes, activeFilters, clearFilters } = props;

  return (
    <FilterHOC
      title={
        <>
          <Typography variant="h2" color="textPrimary">
            {i18n.t("active")}
          </Typography>
          <Button variant="text" size="small" onClick={clearFilters}>
            {i18n.t("clearFilters")}
          </Button>
        </>
      }
      selects={
        <div className={classes.root}>
          {activeFilters.map((filter, index) => (
            <FilterPill key={`${filter.name}_${index}`} filter={filter} />
          ))}
        </div>
      }
      organizerWidth="100%"
    />
  );
};

export default withStyles(styles)(FiltersTray);
