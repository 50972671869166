import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  MenuItem,
  Select,
} from "@material-ui/core";
import clsx from "clsx";

import i18n from "../../../../../../i18n";

interface IProps {
  sanitizedValue: string;
  handleChange: (e) => void;
  rowData: any;
  enums: any[];
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    pending: {
      backgroundColor: theme.palette.primary.light,
    },
  });

type ClassKey = "root" | "pending";
type PropsType = IProps & WithStyles<ClassKey>;

const EditableMultiSelect: React.FC<PropsType> = (props) => {
  const { classes, sanitizedValue, handleChange, rowData, enums } = props;

  const list = Array.isArray(sanitizedValue)
    ? sanitizedValue
    : [sanitizedValue];

  return (
    <Select
      value={list}
      multiple
      onChange={handleChange}
      className={clsx(
        classes.root,
        rowData.hasPendingChange && classes.pending,
      )}
    >
      {enums.map((e) => {
        const isEnglish = i18n.language === "en-US";
        const option = e.node.parameterenumerationsNameUk;
        const userOption = isEnglish
          ? option
          : e.node.parameterenumerationsNameNl;

        return (
          <MenuItem key={option} value={option}>
            {userOption}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default withStyles(styles)(EditableMultiSelect);
