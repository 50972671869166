import React, { useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import Hint from "../../../../common/components/Hint";
import Library from "../Library/Library";
import i18n from "../../../../i18n";
import { RootState } from "../../../../store";
import TabularViewTable from "../Table/TabularViewTable";
import ControlBar from "../Table/ControlBar";
import { EPanelSize } from "../Tabular";

interface IProps {
  isAssigningSource: boolean;
  setIsAssigningSource: (flag: boolean) => void;
  isEditToggledOn: boolean;
  setIsEditToggledOn: (flag: boolean) => void;
  setSelectAll: (flag: boolean) => void;
  sources: any[];
  size: EPanelSize;
}

enum ETabs {
  properties,
  library,
}

const tabs: string[] = ["properties", "library"];

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      overflow: "hidden",
    },
    tabsContainer: {
      minHeight: 26,
      color: theme.palette.text.primary,
    },
    tabsRoot: {
      minHeight: 0,
      margin: -theme.spacing(),
    },
    tabsIndicator: {
      backgroundColor: theme.palette.primary.main,
    },
    tab: {
      minHeight: 26,
      minWidth: 30,
      borderBottom: `2px solid ${theme.palette.secondary.dark}`,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 11,
      textTransform: "uppercase",
      textAlign: "center",
      opacity: 1,
    },
    center: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    tabsContent: {
      flex: 1,
      position: "relative",
      marginTop: theme.spacing(),
    },
    tabContentScrollable: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  });

type ClassKey =
  | "root"
  | "tabsContainer"
  | "tabsRoot"
  | "tabsIndicator"
  | "tabsContent"
  | "tabContentScrollable"
  | "tab"
  | "center";
type PropsType = IProps & WithStyles<ClassKey>;

const TabularView: React.FC<PropsType> = (props) => {
  const {
    classes,
    isEditToggledOn,
    setIsEditToggledOn,
    isAssigningSource,
    setIsAssigningSource,
    setSelectAll,
    sources,
    size,
  } = props;

  const [currentTab, setCurrentTab] = useState<ETabs>(ETabs["properties"]);
  const selectedSources = useSelector(
    (state: RootState) => state.selectedSources,
  );
  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );
  const user = useSelector((state: RootState) => state.user);

  // Control bar state
  const [allParameters, setAllParameters] = useState<any[]>([]);
  const [isReset, setIsReset] = useState<boolean>(false); // Will revert modified cell values on toggle
  const [isSaved, setIsSaved] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      {size !== EPanelSize.small && (
        <>
          <div className={classes.tabsContainer}>
            <Tabs
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
              value={currentTab}
              onChange={(_, value) => setCurrentTab(value)}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab}
                  className={classes.tab}
                  label={i18n.t(tab)}
                  value={ETabs[tab]}
                />
              ))}
              {!!selectedSources.length &&
                currentTab === ETabs["properties"] &&
                (user?.isAdmin || user?.hasWriteAccess) && (
                  <ControlBar
                    isEditable={
                      selectedSources.findIndex(
                        (source) => source?.sourcesIsDynamic,
                      ) !== -1 || isAssigningSource
                    }
                    isEditToggledOn={isEditToggledOn}
                    setIsEditToggledOn={setIsEditToggledOn}
                    allParameters={allParameters}
                    setIsReset={setIsReset}
                    isSaved={isSaved}
                    setIsSaved={setIsSaved}
                    setSelectAll={setSelectAll}
                    sources={sources}
                  />
                )}
            </Tabs>
          </div>

          <div className={classes.tabsContent}>
            {!selectedSources.length && currentTab === ETabs["properties"] && (
              <div className={classes.center}>
                <Typography variant="h1" color="textPrimary">
                  {i18n.t("noSourcesSelected")}
                </Typography>
                <Hint>{i18n.t("specifySource")}</Hint>
              </div>
            )}
            {currentTab === ETabs["properties"] && (
              <TabularViewTable
                setIsAssigningSource={setIsAssigningSource}
                isAssigningSource={isAssigningSource}
                isEditToggledOn={isEditToggledOn}
                setIsEditToggledOn={setIsEditToggledOn}
                allParameters={allParameters}
                setAllParameters={setAllParameters}
                isReset={isReset}
                setIsReset={setIsReset}
                isSaved={isSaved}
                setIsSaved={setIsSaved}
              />
            )}

            {currentTab === ETabs["library"] && !!selectedObjects.length && (
              <div className={classes.tabContentScrollable}>
                <Library selectedSources={selectedSources} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(TabularView);
