import { Action, Reducer } from "redux";

import { KnownAction } from "./actions";

export type TVisibleLayerIds = number[];

export const visibleLayerIdsReducer: Reducer<TVisibleLayerIds> = (
  state = [],
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "RESET_VISIBLE_LAYER_IDS":
      return [];
    case "INSERT_VISIBLE_LAYER_IDS":
      return action.visibleLayerIds;
    default:
      return state;
  }
};
