import React from "react";
import {
  Button,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router";

import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { selectedProjectActions } from "../../../../store/selectedProject";
import { updateComponentTree } from "../../../..";

interface IProps {
  handleClose: () => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: 160,
      height: "fit-content",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      padding: 16,
    },
    closeIcon: {
      marginLeft: "auto",
    },
    header: {
      marginBottom: theme.spacing(2),
    },
    entry: {
      textTransform: "uppercase",
    },
  });

type ClassKey = "root" | "closeIcon" | "header" | "entry";
type PropsType = IProps & WithStyles<ClassKey>;

const SelectProject: React.FC<PropsType> = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();

  if (!user?.projectRoleMapping) return null;

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.header}>
        Available projects:
      </Typography>
      {Object.entries(user?.projectRoleMapping).map(([key]) => (
        <Button
          key={key}
          onClick={() => {
            dispatch(
              selectedProjectActions.updateSelectedProject(key, history),
            );

            updateComponentTree();
          }}
        >
          <Typography variant="body1" className={classes.entry}>
            {key}
          </Typography>
        </Button>
      ))}
    </div>
  );
};

export default withStyles(styles)(SelectProject);
