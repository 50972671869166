import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { formatDate } from "../../../../utils/formatDate";
import i18n from "../../../../i18n";

interface IProps {
  libraryResource: any;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      "& > h3": {
        fontSize: "1rem",
        borderBottom: "2px solid currentColor",
        paddingBottom: theme.spacing(),
      },
      "& > * + *": {
        marginTop: theme.spacing(),
      },
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const LibraryCardTooltip: React.FC<PropsType> = (props) => {
  const { classes, libraryResource } = props;
  return (
    <div className={classes.root}>
      <Typography variant="h3" color="textPrimary">
        {libraryResource.resourcesName}
      </Typography>

      <Typography variant="body1" color="textPrimary">
        <strong>{i18n.t("libraryCardTooltip.category")}:</strong>{" "}
        {libraryResource.resourcesResourcetype.resourcetypesName}
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <strong>{i18n.t("libraryCardTooltip.description")}:</strong>{" "}
        {libraryResource.resourcesDescription}
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <strong>{i18n.t("libraryCardTooltip.metadata")}:</strong>{" "}
        {libraryResource.resourcesMetadata}
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <strong>{i18n.t("libraryCardTooltip.dateUploaded")}:</strong>{" "}
        {formatDate(libraryResource.resourcesDateobtained)}
      </Typography>
      <Typography variant="body1" color="textPrimary">
        <strong>{i18n.t("libraryCardTooltip.dateCreated")}:</strong>{" "}
        {formatDate(libraryResource.resourcesDatecreated)}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(LibraryCardTooltip);
