import { useLazyQuery } from "@apollo/client";
import {
  Button,
  CircularProgress,
  createStyles,
  MenuItem,
  Select,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import ModalActions from "../../../../common/components/Modal/ModalActions";
import ModalContent from "../../../../common/components/Modal/ModalContent";
import ModalHeader from "../../../../common/components/Modal/ModalHeader";
import { IModalContentProps } from "../../../../common/components/Modal/ModalManager";
import { GET_DYNAMIC_SOURCES } from "../../../../data/sourceQueries";
import i18n from "../../../../i18n";
import { RootState } from "../../../../store";
import { selectedObjectsActions } from "../../../../store/selectedObjects";
import { selectedSourcesActions } from "../../../../store/selectedSources";

interface IProps {
  setIsEditToggledOn?: (isEditToggledOn: boolean) => void;
  setIsAssigningSource?: (isAssigningSource: boolean) => void;
  setSelectAll?: (flag: boolean) => void;
  currentSources: any[];
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    select: {
      flex: 1,
      backgroundColor: theme.palette.primary.contrastText,
      "& > .MuiSelect-root": {
        paddingLeft: 6,
      },
    },
    formControl: {
      display: "flex",
      backgroundColor: theme.palette.primary.light,
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
      marginTop: theme.spacing(2),
      alignItems: "center",
      "& > label": {
        margin: `0 ${theme.spacing(2)}px`,
      },
      "&:last-child": {
        flex: 2,
      },
    },
  });

type ClassKey = "root" | "select" | "formControl";
type PropsType = IProps & IModalContentProps & WithStyles<ClassKey>;

const SelectSingleObjectSource: React.FC<PropsType> = (props) => {
  const {
    classes,
    handleClose,
    setIsEditToggledOn,
    setIsAssigningSource,
    setSelectAll,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const selectedSources = useSelector(
    (state: RootState) => state.selectedSources,
  );
  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );
  const layers = useSelector(
    (state: RootState) => state.layerGroups[0]?.objectlayers || [],
  );

  const [elegibleSources, setElegibleSources] = useState<any[]>([]);

  const [selectedSource, setSelectedSource] = useState<any>(
    selectedSources[0] || elegibleSources[0],
  );

  const [selectedObject, setSelectedObject] = useState<any>(
    selectedObjects[0] || null,
  );

  const [getSources, receivedSources] = useLazyQuery(GET_DYNAMIC_SOURCES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ allSources }) => {
      let sources = allSources.edges.map(({ node }) => node);

      if (setIsAssigningSource) {
        getPossibleSources();
      } else {
        sources = sources.filter((src) =>
          props.currentSources
            .map((source) => source.sourcesId)
            .includes(src.sourcesId),
        );
      }
      setElegibleSources(sources);
    },
  });

  const [getPossibleSources, receivedPossibleSources] = useLazyQuery(
    GET_DYNAMIC_SOURCES,
    {
      fetchPolicy: "no-cache",
    },
  );

  const submit = () => {
    if (setIsEditToggledOn) setIsEditToggledOn(true);
    if (setIsAssigningSource) setIsAssigningSource(true);
    if (setSelectAll) setSelectAll(false);

    selectedObjects.forEach((obj) => {
      if (obj.objectLongId !== selectedObject.objectLongId)
        dispatch(selectedObjectsActions.removeSelectedObject(obj));
    });

    dispatch(selectedSourcesActions.resetSelectedSources());
    dispatch(selectedSourcesActions.insertSelectedSource(selectedSource));

    handleClose();
  };

  useEffect(() => {
    getSources({
      variables: {
        objectIds: [selectedObject.objectId],
      },
    });
  }, [selectedObject]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (receivedPossibleSources.error)
      enqueueSnackbar(i18n.t("error.queryGraphqlError"), { variant: "error" });
    else if (!receivedPossibleSources.loading && receivedPossibleSources.data) {
      let possibleSources: any[] = receivedPossibleSources.data.allSources.edges
        .map(({ node }) => node)
        .filter(
          (src) =>
            !elegibleSources.map((ps) => ps.sourcesId).includes(src.sourcesId),
        );
      setElegibleSources(possibleSources);
      if (!selectedSource) {
        setSelectedSource(possibleSources[0]);
      }
    }
  }, [receivedPossibleSources]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={submit}>
      <ModalContent>
        <ModalHeader
          title={
            setIsAssigningSource
              ? i18n.t("table.addSourceObject")
              : i18n.t("table.selectSingleSourceObject")
          }
          handleClose={handleClose}
        />

        <div className={classes.root}>
          {!setIsAssigningSource && i18n.t("table.selectSingularDescription")}

          <div className={classes.formControl}>
            <label id="selectedObjects">{i18n.t("selectedObjects")}</label>
            <Select
              aria-labelledby="selectedObjects"
              value={selectedObject.objectId}
              onChange={(e) => {
                setSelectedObject(
                  selectedObjects.find(
                    (selectedObject) =>
                      selectedObject.objectId === e.target.value,
                  ),
                );
              }}
              className={classes.select}
              name="selectedObjects"
            >
              {selectedObjects.map((object) => (
                <MenuItem
                  key={object.objectId as number}
                  value={object.objectId as number}
                >
                  {object.objectLayer?.objectlayerName !==
                  layers[2]?.objectlayerName
                    ? object.objectLongId
                    : object.objectName}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>

        <div className={classes.formControl}>
          <label id="selectedSources">{i18n.t("selectedSources")}</label>
          {receivedSources.loading || receivedPossibleSources.loading ? (
            <CircularProgress />
          ) : (
            <Select
              aria-labelledby="selectedSources"
              value={
                !!elegibleSources.length
                  ? selectedSource?.sourcesId || elegibleSources[0].sourcesId
                  : i18n.t("table.noSourcesAvailable")
              }
              onChange={(e) => {
                setSelectedSource(
                  elegibleSources.find(
                    (elegibleSource) =>
                      elegibleSource.sourcesId === e.target.value,
                  ),
                );
              }}
              className={classes.select}
              name="selectedSources"
              disabled={!selectedObject || !elegibleSources.length}
            >
              {!elegibleSources.length && (
                <MenuItem
                  key="no-options-available"
                  value={i18n.t("table.noSourcesAvailable")}
                >
                  {i18n.t("table.noSourcesAvailable")}
                </MenuItem>
              )}
              {elegibleSources.map((source) => (
                <MenuItem key={source.sourcesId} value={source.sourcesId}>
                  {source.sourcesName}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        <ModalActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={submit}
          >
            {i18n.t("ok")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            type="button"
          >
            {i18n.t("cancel")}
          </Button>
        </ModalActions>
      </ModalContent>
    </form>
  );
};

export default withStyles(styles)(SelectSingleObjectSource);
