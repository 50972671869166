import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  Button,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";

import i18n from "../../../i18n";
import { RootState } from "../../../store";
import { IProjectConfig } from "../../../store/selectedProject/actions";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "space-between",
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const AirReports: React.FC<PropsType> = (props) => {
  const { classes } = props;

  const projectConfig = useSelector(
    (state: RootState) => state.selectedProject,
  ) as IProjectConfig;

  return (
    <div className={classes.root}>
      <Typography variant="caption" color="textPrimary">
        {i18n.t("reportTemplatesComingSoon")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => window.open(projectConfig?.air.url, "_blank")}
        type="button"
      >
        {i18n.t("launchAir")}
      </Button>
    </div>
  );
};

export default withStyles(styles)(AirReports);
