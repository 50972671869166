import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { feature, featureCollection } from "@turf/helpers";
import ReactMapGL, {
  NavigationControl,
  ScaleControl,
  Source,
  Layer,
  MapEvent,
} from "react-map-gl";
import { useSnackbar } from "notistack";
import { FeatureCollection } from "geojson";
import "mapbox-gl/dist/mapbox-gl.css";
import { logSentry } from "../../../utils/logger";

import SearchStrategyToggle from "./SearchStrategyToggle";
import MapStyleToggle from "./MapStyleToggle";
import { IBareFilter, IMapViewport, MapFeature } from "../Core";
import { GET_BBOX_ROOT_OBJECTS } from "../../../data/mapQueries";
import i18n from "../../../i18n";
import useLocalStorage from "../../../common/hooks/useLocalStorage";
import { RootState } from "../../../store";
import { createHatchFromImageId } from "./hatching";
import {
  filterSymbolsLine,
  filterSymbolsFill,
  filterSymbolsCircle,
  filterSymbolsCircleLabelLayout,
  filterSymbolsCluster,
  filterSymbolsClusterLabel,
  filterSymbolsClusterLabelLayout,
} from "./dataStyles";
import { MAP_STYLES } from "./mapStyles";
import MapBorehole from "../../../images/map_borehole.png";
import MapCpt from "../../../images/map_cpt.png";
import MapRadius from "../../../images/map_radius.png";

//Utils
import {
  handleMapError,
  handleOnClick,
  handleOnMouseMove,
  handleOnMouseLeave,
} from "./utils/handlers";
import {
  removeMapLayers,
  layerIsVisibleAtZoom,
  getLayerIdsByZoom,
  updateInteractiveLayers,
  filterDataByLayer,
} from "./utils/utils";
import {
  createObjectLayers,
  createActiveLayers,
  createChildLayers,
} from "./utils/layers";
import { convertToCentroids } from "./utils/centroid";
import { getFlattenedLayerNames } from "../../../utils/layers";
import { isLocalEnv } from "../../../common/utils";
import { IProjectConfig } from "../../../store/selectedProject/actions";
import p500dev from "../../../config/projects/p500dev";
import { getFlag } from "../../../config";
import CreateEntry, { CreateOption, LatLong } from "./CreateEntry";
import MapDrawTools from "./MapDrawTools";
import ContextMarker from "./ContextMarker";
import ContextMenu from "./ContextMenu";

interface IProps {
  viewport: IMapViewport;
  setViewport: (viewport: IMapViewport) => void;
  activeFeatures: MapFeature[];
  filteredFeatures: MapFeature[];
  scopes: IBareFilter[];
  projects: IBareFilter[];
  selectedScopes: IBareFilter[];
  selectedProjects: IBareFilter[];
  allScopesAndProjects: any;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      width: "100%",
      position: "relative",
    },
    topRightToggle: {
      position: "absolute",
      top: theme.spacing(14),
      right: theme.spacing(2),
      paddingRight: "3px",
      "&:hover": {
        opacity: 0.6,
      },
    },
    topRight: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(6),
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      "& .mapboxgl-ctrl-group": {
        borderRadius: 0,
        boxShadow: "2px 2px 2px 0 rgba(118, 118, 118, 0.45)",
      },
    },
    bottomRight: {
      position: "absolute",
      bottom: theme.spacing(4.5),
      // Width of mapbox copyright container at bottom right
      right: "270px",
    },
    bottomRightSaveNewEntry: {
      position: "absolute",
      bottom: theme.spacing(4.5),
      right: theme.spacing(2),
    },
  });
type ClassKey =
  | "root"
  | "topRight"
  | "bottomRight"
  | "topRightToggle"
  | "bottomRightSaveNewEntry";
type PropsType = IProps & WithStyles<ClassKey>;

const mapImages: { name: string; image: any; sdf: boolean }[] = [
  { name: "borehole", image: MapBorehole, sdf: true },
  { name: "cpt", image: MapCpt, sdf: true },
  { name: "radius", image: MapRadius, sdf: true },
];

const MapBoxMap: React.FC<PropsType> = (props: PropsType) => {
  const {
    viewport,
    setViewport,
    classes,
    activeFeatures,
    filteredFeatures,
    scopes,
    projects,
    selectedScopes,
    selectedProjects,
    allScopesAndProjects,
  } = props;
  const theme = useTheme();
  const mapRef = useRef<any>();
  const hoverIdRef = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state: RootState) => state.user);
  const { rootObject } = useSelector((state: RootState) => state.rootObjects);
  const tree = useSelector((state: RootState) => state.treeObjects);
  const layerGroups = useSelector((state: RootState) => state.layerGroups);
  const visibleLayerIds = useSelector(
    (state: RootState) => state.visibleLayerIds,
  );
  const [mapLayerIds, setMapLayerIds] = useState<string[]>([]);
  const [autoSearchEnabled, setAutoSearchEnabled] = useLocalStorage(
    "mapAutoSearchEnabled",
    true,
  );
  const [activeFeaturesData, setActiveFeaturesData] = useState<any>([]);
  const [filteredFeaturesData, setFilteredFeaturesData] = useState<any>([]);
  const [bboxObjects, setBboxObjects] = useState<FeatureCollection>(
    featureCollection([]),
  );
  const [viewportChanged, setViewportChanged] = useState<boolean>(false);
  const [mapStyle, setMapStyle] = useLocalStorage(
    "mapStyle",
    MAP_STYLES.streets,
  );
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [clickEventLayers, setClickEventLayers] = useState<string[]>([]);
  const [hoverEventLayers, setHoverEventLayers] = useState<string[]>([]);
  const [styleDataHandlerAdded, setStyleDataHandlerAdded] = useState<boolean>(
    false,
  );

  const [isOpenNewEntry, setIsOpenNewEntry] = useState<boolean>(false);
  const [createMode, setCreateMode] = useState<CreateOption>(CreateOption.None);
  const [polygon, setPolygon] = useState<LatLong[]>();
  const [contextMenuEvent, setContextMenuEvent] = useState<MapEvent>();

  const projectConfig = useSelector(
    (state: RootState) => state.selectedProject,
  ) as IProjectConfig;

  const defaultLayerVisibleZoom = projectConfig?.map?.zoomTreshold
    ? projectConfig?.map?.zoomTreshold
    : 17;
  const pastDefaultZoomRange =
    !!viewport?.zoom && viewport?.zoom >= defaultLayerVisibleZoom;

  const [
    getBboxObjects,
    { error: bboxObjectsError, loading: bboxObjectsLoading },
  ] = useLazyQuery(GET_BBOX_ROOT_OBJECTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      // If query completes after we scroll past threshold, don't commit changes to state
      // TODO: see if we can cancel the request instead
      if (layersVisibleAtZoom(viewport?.zoom)) {
        let features = data.objectsFromBbox
          .filter(({ objectLayer }) => {
            const objectlayerId = objectLayer.objectlayerId;
            if (
              viewport.zoom &&
              layerIsVisibleAtZoom(
                objectlayerId,
                viewport.zoom,
                defaultLayerVisibleZoom,
              )
            ) {
              return true;
            } else return false;
          })
          .map(({ objectGeom, objectLongId, objectLayer }) => {
            const objectlayerId = objectLayer.objectlayerId;
            return feature(objectGeom, {
              objectLongId,
              objectlayerId,
            });
          });

        setBboxObjects(featureCollection(features));
      }
    },
  });

  if (bboxObjectsError) {
    enqueueSnackbar(i18n.t("error.queryGraphqlError"), { variant: "error" });
  }

  const layersVisibleAtZoom = (zoom: number | undefined): boolean => {
    // Check if there are any layers visible at the current zoom
    if (pastDefaultZoomRange) {
      return true;
    } else if (zoom && projectConfig?.map?.layerZoomRanges?.length > 0) {
      // Get the min/max zoom range from all layers in projectConfig
      const minZoom = projectConfig?.map.layerZoomRanges.reduce((a, b) => {
        return a.minZoom < b.minZoom ? a : b;
      }).minZoom;
      const maxZoom = projectConfig?.map.layerZoomRanges.reduce((a, b) => {
        return a.maxZoom > b.maxZoom ? a : b;
      }).maxZoom;

      if (zoom >= minZoom && zoom <= maxZoom) {
        return true;
      }
    }

    return false;
  };

  const refreshBboxObjects = () => {
    if (layersVisibleAtZoom(viewport?.zoom)) {
      if (autoSearchEnabled) {
        fetchObjectGeoms();
      }
    } else {
      if (bboxObjects.features.length) {
        setBboxObjects(featureCollection([]));
      }
    }
    updateInteractiveLayers(
      visibleLayerIds,
      viewport,
      defaultLayerVisibleZoom,
      setClickEventLayers,
      setHoverEventLayers,
    );
  };

  const addImage = (map, img, name, sdf) => {
    map?.loadImage(img, (error, image) => {
      if (error) {
        logSentry(
          "error",
          i18n.t("error.mapImageLoadFailed", {
            error: `${error.error.message}`,
            language: "en-US",
          }),
          error,
        );
        return;
      }

      if (!map?.hasImage(name)) {
        if (sdf) {
          map?.addImage(name, image, { sdf: true });
        } else {
          map?.addImage(name, image);
        }
      }
    });
  };

  const addMapImages = () => {
    // Adds image symbols to the map
    const map = mapRef?.current?.getMap();
    mapImages.forEach((mapImage) => {
      addImage(map, mapImage.image, mapImage.name, mapImage.sdf);
    });
  };

  const fetchObjectGeoms = useMemo(() => {
    const handleQuery = () => {
      const map = mapRef.current;

      if (map) {
        const instance = map?.getMap();
        const {
          _sw: { lng: xmin, lat: ymin },
          _ne: { lng: xmax, lat: ymax },
        } = instance.getBounds();
        const inRangeLayerIds: number[] = getLayerIdsByZoom(
          visibleLayerIds,
          viewport,
          defaultLayerVisibleZoom,
        );

        // Bbox query expects WGS84, so no need to reproject
        if (inRangeLayerIds.length > 0) {
          getBboxObjects({
            variables: {
              xmin,
              ymin,
              xmax,
              ymax,
              layerIds: inRangeLayerIds,
            },
          });
        } else setBboxObjects(featureCollection([]));
      }
    };

    // If auto search is enabled then returned debounced version.
    // If we're in on-demand mode we want the results as soon as possible.
    return autoSearchEnabled ? debounce(handleQuery, 400) : handleQuery;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSearchEnabled, visibleLayerIds, pastDefaultZoomRange]);

  const childGeoms = useMemo(() => {
    let features = [];

    if (tree !== null) {
      // Use original building object as feature properties so we can pass it
      // straight into the store when it's selected.
      features = tree.root[0].children.map((child) => {
        const ftr = feature(child.objectGeom, child);
        // Split out the layer name as MapBox styles can't access nested properties
        ftr.properties["objectlayerName"] =
          ftr.properties["objectLayer"]?.objectlayerName;
        return feature(child.objectGeom, child);
      }) as any;
      refreshBboxObjects();
    }
    return featureCollection(features);
  }, [tree]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshBboxObjects();
  }, [visibleLayerIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewportChanged) refreshBboxObjects();
  }, [autoSearchEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActiveFeaturesData(featureCollection(activeFeatures));
  }, [activeFeatures]);

  useEffect(() => {
    // Remove duplicates from the filterFeatures array
    // before creating the feature collection
    const removeDuplicates = (filteredFeatures) => {
      const uniqueFtrs: any[] = [];
      filteredFeatures.forEach((ftr: any) => {
        // Remove duplicate features
        if (
          uniqueFtrs.some(
            (existingFtr) =>
              existingFtr.properties.objectId === ftr.properties.objectId,
          )
        ) {
          return;
        }
        uniqueFtrs.push(ftr);
      });
      const newFtrCol: any = featureCollection(uniqueFtrs);
      return newFtrCol;
    };
    setFilteredFeaturesData(removeDuplicates(filteredFeatures));
  }, [filteredFeatures]);

  useEffect(() => {
    addMapImages();
    const map = mapRef?.current?.getMap();
    map.on("styleimagemissing", handleStyleImageMissing);
  }, [mapRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mapLoaded) {
      addAllMapLayers();
      refreshBboxObjects();

      // Add an event handler for style change the first time the map is loaded
      if (!styleDataHandlerAdded && layerGroups.length !== 0) {
        // Don't trigger handleStyleData if layerGroups are empty.
        // Fix for unselectable geometry bug OD-24
        const map = mapRef?.current?.getMap();
        map.on("styledata", debounce(handleStyleData, 700));
        setStyleDataHandlerAdded(true);
      }
    }
  }, [mapLoaded, layerGroups]); // eslint-disable-line react-hooks/exhaustive-deps

  const mapRendered = useMemo(() => {
    // to track when the map has finished loading, rendering and has received all the data it needs to symbolize the layers correctly
    // * should prevent the black outlines appearing on the child geometry selections
    return mapLoaded && styleDataHandlerAdded && layerGroups.length !== 0;
  }, [mapLoaded, layerGroups, styleDataHandlerAdded]);

  // MAP PROPS & EVENTS
  const handleStyleData = () => {
    addMapImages();
    addAllMapLayers();
  };

  const handleMapLoad = () => {
    if (!mapLoaded) setMapLoaded(true);
  };

  const handleOnDemandSearch = () => {
    fetchObjectGeoms();
    setViewportChanged(false);
  };

  const handleStrategyCheckboxChange = () => {
    setAutoSearchEnabled(!autoSearchEnabled);
    setViewportChanged(false);
  };

  const handleStyleImageMissing = (event) => {
    // Create a map symbol if there isn't one with the event.id name
    const id = event.id;
    const mapInstance = mapRef?.current?.getMap();
    const symbol = createHatchFromImageId(id);
    if (symbol && !mapInstance?.hasImage(id)) mapInstance?.addImage(id, symbol);
  };

  const stopCreateObject = () => {
    // Hide 'create object' menu and disable drawing
    setCreateMode(CreateOption.None);
    setIsOpenNewEntry(false);
  };

  const handleContextMenu = (e: MapEvent) => {
    e.preventDefault();
    setContextMenuEvent(e);
    stopCreateObject();
    setPolygon(undefined);
  };

  let mapProps: any = {
    onViewportChange: (nextViewport: IMapViewport) => {
      setViewport(nextViewport);

      // If manual search enabled set viewportChanged to true (if it isn't already)
      if (!autoSearchEnabled && !viewportChanged) {
        setViewportChanged(true);
      }
      refreshBboxObjects();
    },
    interactiveLayerIds: mapLayerIds,
    onTransitionEnd: refreshBboxObjects,
  };

  if (layersVisibleAtZoom(viewport?.zoom)) {
    mapProps.onClick = (event) =>
      handleOnClick(
        event,
        mapRef,
        clickEventLayers,
        rootObject,
        mapLayerIds,
        activeFeatures,
      );
    /*
      Hover process
      - hovering sets the 'hover' feature-state to true/false on the feature
      - different layers can respond to this, but must have generated IDs
      - e.g. a fill layer can capture the event with a low opacity, but a line layer can change style
        based on feature-state hover
      - **Only job of hover event is to set feature-state "hover" to true/false**
      onMouseMove
      - query the rendered features at current mouse location
        - looking for specific layers only
      - clear actual feature state for existing hovered feature on map before replacing ref value
      - if we find any then store top-most feature in ref
      onMouseLeave
      - clear ref if it has a value
      - helps to clean up anything not caught in onMouseMove
    */
    mapProps.onMouseMove = (event) =>
      handleOnMouseMove(event, mapRef, hoverEventLayers, hoverIdRef);
    mapProps.onMouseLeave = () => handleOnMouseLeave(hoverIdRef, mapRef);
  }
  const layerNames = getFlattenedLayerNames();
  // LAYERS
  const addAllMapLayers = () => {
    const newMapLayerIds: string[] = [];
    removeMapLayers(mapRef, mapLayerIds, setMapLayerIds);
    createActiveLayers(layerNames, mapRef);
    createChildLayers(layerNames, mapRef);
    newMapLayerIds.push(
      ...createObjectLayers(
        layerGroups,
        defaultLayerVisibleZoom,
        mapRef,
        projectConfig,
      ),
    );

    setMapLayerIds(newMapLayerIds);
  };

  const dataFilteredByLayer = filterDataByLayer(
    filteredFeaturesData,
    layerGroups[0]?.objectlayers[0]?.objectlayerName,
  );

  const filterLayers = () => {
    return (
      <Source
        id="filteredFeatures"
        type="geojson"
        data={dataFilteredByLayer as any}
      >
        <Layer
          id="filter-fill"
          type="fill"
          filter={["==", "$type", "Polygon"]}
          paint={filterSymbolsFill()}
          minzoom={defaultLayerVisibleZoom}
        />
        <Layer
          id="filter-line"
          type="line"
          filter={[
            "any",
            ["==", "$type", "Polygon"],
            ["==", "$type", "LineString"],
          ]}
          paint={filterSymbolsLine()}
          minzoom={defaultLayerVisibleZoom}
        />
        <Layer
          id="filter-circle"
          type="circle"
          filter={["==", "$type", "Point"]}
          paint={filterSymbolsCircle()}
          minzoom={defaultLayerVisibleZoom}
        />
      </Source>
    );
  };

  const filterClusterLayers = () => {
    return (
      <Source
        id="filteredFeaturesCluster"
        type="geojson"
        data={convertToCentroids(dataFilteredByLayer)}
        cluster={true}
        clusterMaxZoom={defaultLayerVisibleZoom}
        clusterRadius={50}
      >
        <Layer
          id="filter-cluster"
          type="circle"
          filter={["has", "point_count"]}
          paint={filterSymbolsCluster() as any}
          maxzoom={defaultLayerVisibleZoom}
        />
        <Layer
          id="filter-cluster-count"
          type="symbol"
          filter={["has", "point_count"]}
          layout={filterSymbolsClusterLabelLayout(
            theme,
            "point_count_abbreviated",
          )}
          paint={filterSymbolsClusterLabel()}
          maxzoom={defaultLayerVisibleZoom}
        />
        <Layer
          id="filter-cluster-single"
          type="circle"
          filter={[
            "all",
            ["==", "$type", "Point"],
            ["!has", "point_count"],
            ["!has", "isCentroid"],
          ]}
          paint={filterSymbolsCircle()}
          maxzoom={defaultLayerVisibleZoom}
        />
        <Layer
          id="filter-cluster-single-count"
          type="symbol"
          filter={[
            "all",
            ["==", "$type", "Point"],
            ["!has", "point_count"],
            ["!has", "isCentroid"],
          ]}
          layout={filterSymbolsCircleLabelLayout(theme, "1")}
          paint={filterSymbolsClusterLabel()}
          maxzoom={defaultLayerVisibleZoom}
        />
      </Source>
    );
  };

  if (!projectConfig?.mapbox?.token) return null;

  return (
    <div className={classes.root}>
      <SearchStrategyToggle
        layersVisible={layersVisibleAtZoom(viewport?.zoom)}
        pastDefaultZoomRange={pastDefaultZoomRange}
        viewportChanged={viewportChanged}
        loading={bboxObjectsLoading}
        checked={autoSearchEnabled}
        handleChange={handleStrategyCheckboxChange}
        handleOnDemandSearch={() => handleOnDemandSearch()}
      />

      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        ref={mapRef}
        mapStyle={mapStyle.url}
        clickRadius={20}
        mapboxApiAccessToken={
          isLocalEnv() ? p500dev.mapbox.token : projectConfig?.mapbox?.token
        }
        onLoad={handleMapLoad}
        onError={handleMapError}
        {...mapProps}
        onContextMenu={
          user && (user.hasWriteAccess || user.isAdmin)
            ? handleContextMenu
            : () => {}
        }
      >
        <div className={classes.topRight}>
          <NavigationControl />
        </div>
        <div className={classes.topRightToggle}>
          <MapStyleToggle setMapStyle={setMapStyle} />
        </div>
        <div className={classes.bottomRight}>
          <ScaleControl maxWidth={100} unit="metric" />
        </div>
        <Source
          id="bboxObjects"
          type="geojson"
          data={bboxObjects}
          generateId={true}
        ></Source>
        {filterClusterLayers()}
        {filterLayers()}
        <Source
          id="activeFeatures"
          type="geojson"
          data={activeFeaturesData}
        ></Source>
        {mapRendered && (
          <Source
            id="childFeatures"
            type="geojson"
            data={childGeoms as any}
            generateId={true}
          ></Source>
        )}

        {getFlag(projectConfig, "createObject") &&
          (user?.hasWriteAccess || user?.isAdmin) && (
            <>
              <MapDrawTools createMode={createMode} setPolygon={setPolygon} />
              {contextMenuEvent && createMode === CreateOption.Location && (
                <ContextMarker
                  markerCoords={{
                    lat: contextMenuEvent.lngLat[1],
                    long: contextMenuEvent.lngLat[0],
                  }}
                />
              )}
              {contextMenuEvent && (
                <ContextMenu
                  setIsOpenNewEntry={setIsOpenNewEntry}
                  contextMenuEvent={contextMenuEvent}
                  setCreateMode={setCreateMode}
                ></ContextMenu>
              )}
              <div className={classes.bottomRightSaveNewEntry}>
                {isOpenNewEntry && contextMenuEvent && (
                  <CreateEntry
                    layerGroups={layerGroups}
                    setIsOpenNewEntry={setIsOpenNewEntry}
                    polygon={polygon}
                    setPolygon={setPolygon}
                    createMode={createMode}
                    markerCoords={{
                      lat: contextMenuEvent.lngLat[1],
                      long: contextMenuEvent.lngLat[0],
                    }}
                    setCreateMode={setCreateMode}
                    scopes={scopes}
                    projects={projects}
                    selectedScopes={selectedScopes}
                    selectedProjects={selectedProjects}
                    allScopesAndProjects={allScopesAndProjects}
                  />
                )}
              </div>
            </>
          )}
      </ReactMapGL>
    </div>
  );
};

export default withStyles(styles)(MapBoxMap);
