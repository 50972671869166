import { ReactComponent as DirectoryIcon } from "./Icons/DirectorySVG.svg";
import { ReactComponent as ImageIcon } from "./Icons/ImageSVG.svg";
import { ReactComponent as LayerIcon } from "./Icons/LayerSVG.svg";
import { ReactComponent as PDFIcon } from "./Icons/PDFSVG.svg";
import { ReactComponent as PointcloudIcon } from "./Icons/PointcloudSVG.svg";
import { ReactComponent as RhinoIcon } from "./Icons/RhinoSVG.svg";
import { ReactComponent as VideoIcon } from "./Icons/VideoSVG.svg";
import { ReactComponent as SpeckleIcon } from "./Icons/SpeckleD.svg";

export const isImage = (filename: string) => {
  const imageRegex = /(jpe?g|png|gif)$/i;
  return imageRegex.test(filename);
};

export const isVideo = (filename: string) => {
  const videoRegex = /(avi|mov|wmv|mp4|mpe?g)$/i;
  return videoRegex.test(filename);
};

export const getFileIcon = (extension: string) => {
  if (isImage(extension)) return ImageIcon;
  if (isVideo(extension)) return VideoIcon;

  // TODO: get proper extensions
  const miscIconRef = {
    l: LayerIcon,
    pdf: PDFIcon,
    p: PointcloudIcon,
    "3ds": RhinoIcon,
    speckle: SpeckleIcon,
  };

  return miscIconRef[extension] || DirectoryIcon;
};

export const grapheneFormatter = (str: string) => {
  let newString = "";
  for (let i = 0; i < str.length; i++) {
    if (str[i] !== "_" && str[i] !== ".") {
      newString += str[i];
    } else if (str[i] === "_" && str[i + 1] !== "_") {
      newString += str[i + 1].toUpperCase();
      i++;
    } else if (str[i] === ".") {
      newString += "_" + str[i + 1].toUpperCase();
      i++;
    }
  }
  return newString;
};
