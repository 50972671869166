import { IProjectConfig } from "../../store/selectedProject/actions";

export default {
  name: "hs2sa",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja2lnNXV2bWowOGlnMnBxamhjbWh0bXBsIn0.ltl6wWjVVnxbdhCqw9MtYw",
  },
  graphql: {
    url: "https://air-uk.dwh-api.arup.com/hs2sa/graphql/",
  },
  coordinates: {
    latitude: 52.543942623775656,
    longitude: -1.6117883343235777,
    zoom: 7,
  },
  map: {
    zoomTreshold: 15,
    layerSymbols: [
      {
        objectlayerIds: [1],
        symbol: "borehole",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [1],
        minZoom: 7,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 0,
  air: {
    enabled: true,
    url: "https://hs2.air.arup.com/",
  },
} as IProjectConfig;
