import React, { createContext, useState } from "react";

interface ISharedParameter {
  selectedSource: {
    sourcesId: number;
  };
}

const initialValue = {
  selectedSource: {
    sourcesId: -1,
  },
};

export const SharedParameterContext = createContext({
  sharedParameter: initialValue,
  saveSelectedSource: (source: any) => {},
});

const SharedParameterProvider = ({ children }) => {
  const [sharedParameter, setSharedParameter] = useState<ISharedParameter>(
    initialValue,
  );
  const saveSelectedSource = (source: any) => {
    setSharedParameter({ selectedSource: source });
  };
  return (
    <SharedParameterContext.Provider
      value={{ sharedParameter, saveSelectedSource }}
    >
      {children}
    </SharedParameterContext.Provider>
  );
};

export default SharedParameterProvider;
