import { IObject } from "../../data/types/typeStitches";
import { AppThunk } from "../interfaces";

export const rootObjectActions = {
  resetRootObject: (): AppThunk => (dispatch) => {
    dispatch({ type: "RESET_ROOT_OBJECT" });
  },
  insertRootObject: (rootObject: IObject): AppThunk => (dispatch) => {
    dispatch({ type: "INSERT_ROOT_OBJECT", rootObject });
  },
  resetEntryObject: (): AppThunk => (dispatch) => {
    dispatch({ type: "RESET_ENTRY_OBJECT" });
  },
  insertEntryObject: (
    entryObject: IObject | { objectLongId: string },
  ): AppThunk => (dispatch) => {
    // Clear any existing selected objects or sources before inserting entry object
    dispatch({ type: "RESET_SELECTED_OBJECTS" });
    dispatch({ type: "RESET_SELECTED_SOURCES" });
    dispatch({ type: "INSERT_ENTRY_OBJECT", entryObject });
  },
};
