import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Chip,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { getColourIndexByLayerId } from "../../../../utils/layers";
import { layerColours } from "../../../../common/colors";
import { ISelectFilter } from "../../Core";

interface IProps {
  filter: ISelectFilter;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      backgroundColor: theme.palette.secondary.dark,
      borderRadius: theme.spacing(1.5),
      marginRight: "0.75rem",
      marginTop: "0.3rem",
      height: theme.spacing(3),
      paddingLeft: ".5rem",
      paddingRight: ".5rem",
      fontSize: theme.spacing(1.25),
    },
    disableButton: {
      color: theme.palette.text.primary,
      fontSize: "0.8rem",
      marginLeft: "0.5rem",
      "&:hover": {
        cursor: "pointer",
      },
    },
    closeIcon: {
      height: theme.spacing(1.5),
      width: theme.spacing(1.25),
      backgroundColor: "#767676",
      borderRadius: "50%",
      color: theme.palette.secondary.dark,
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
      },
    },
  });

type ClassKey = "root" | "disableButton" | "closeIcon";
type PropsType = IProps & WithStyles<ClassKey>;

const FilterPill: React.FC<PropsType> = (props) => {
  const { classes, filter } = props;

  const enableLayerColors = (filter: ISelectFilter) =>
    filter.type === "featureLayers";

  return (
    <Chip
      size="small"
      className={classes.root}
      style={{
        ...(enableLayerColors(filter) && {
          backgroundColor:
            layerColours[getColourIndexByLayerId(filter.id)]?.fill,
        }),
        ...(enableLayerColors(filter) && {
          borderColor:
            layerColours[getColourIndexByLayerId(filter.id)]?.outline,
        }),
      }}
      label={filter.name}
      onDelete={filter.deselect}
      deleteIcon={<CloseRoundedIcon className={classes.closeIcon} />}
    />
  );
};

export default withStyles(styles)(FilterPill);
