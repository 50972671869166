import { gql } from "@apollo/client";

export const GET_PARAMETERVALUES = gql`
  query GET_PARAMETERVALUES(
    $objectIds: [ID]
    $sourceIds: [ID]
    $parameterValuesId: ID
  ) {
    allParameterValues(
      parametervaluesSource_In: $sourceIds
      parametervaluesObject_In: $objectIds
      parametervaluesId: $parameterValuesId
    ) {
      edges {
        node {
          parametervaluesId
          parametervaluesFlag
          parametervaluesValue
          parametervaluesHistory
          parametervaluesQualifier
          parametervaluesObject {
            objectLongId
            objectId
            objectGeom
            objectName
            objectSearchLongStr
            objectLayer {
              objectlayerId
              objectlayerName
            }
          }
          parametervaluesSource {
            sourcesId
            sourcesName
            sourcesIsDynamic
          }
          parametervaluesParameter {
            parametersId
            parametersElementcode
            parametersParametermappings {
              edges {
                node {
                  parametermappingsId
                  parametermappingsMapreference {
                    sourcesId
                    sourcesName
                  }
                  parametermappingsSortorder
                }
              }
            }
            parametersParameterdef {
              parameterdefsId
              parameterdefsType
              parameterdefsName
              parameterdefsUnit
              parameterdefsParameterenumerationset {
                parameterenumerationsetsId
                parameterEnumerations {
                  edges {
                    node {
                      parameterenumerationsNameNl
                      parameterenumerationsNameUk
                      parameterenumerationsAllowed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PARAMETERVALUES = gql`
  mutation UPDATE_PARAMETERVALUES(
    $parametervaluesValue: String
    $parametervaluesId: Int
  ) {
    updateParameterValue(
      input: {
        parametervaluesValue: $parametervaluesValue
        parametervaluesId: $parametervaluesId
      }
    ) {
      clientMutationId
      parametervaluesId
      parametervaluesFlag
      parametervaluesValue
      parametervaluesObject
      parametervaluesParameter
      parametervaluesSource
      parametervaluesHistory
      parametervaluesQualifier
      errors {
        field
        messages
      }
    }
  }
`;

// TODO: Integrate with update query
// having an undefined parametervaluesId present in params
// causes mutation to fail
export const CREATE_PARAMETERVALUES = gql`
  mutation CREATE_PARAMETERVALUES(
    $parametervaluesValue: String!
    $parametervaluesFlag: Int
    $parametervaluesQualifier: Int
    $parametersId: ID
    $objectId: ID
    $sourceId: ID
  ) {
    updateParameterValue(
      input: {
        parametervaluesValue: $parametervaluesValue
        parametervaluesFlag: $parametervaluesFlag
        parametervaluesQualifier: $parametervaluesQualifier
        parametervaluesParameter: $parametersId
        parametervaluesObject: $objectId
        parametervaluesSource: $sourceId
      }
    ) {
      clientMutationId
      parametervaluesId
      parametervaluesFlag
      parametervaluesValue
      parametervaluesObject
      parametervaluesParameter
      parametervaluesSource
      parametervaluesHistory
      parametervaluesQualifier
      errors {
        field
        messages
      }
    }
  }
`;
