import { IProjectConfig } from "../../store/selectedProject/actions";

export default {
  name: "nl-schools",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja2lnNXV2bWowOGlnMnBxamhjbWh0bXBsIn0.ltl6wWjVVnxbdhCqw9MtYw",
  },
  graphql: {
    url: "https://public.dwh-api.arup.com/nl-schools/graphql/",
  },
  coordinates: {
    latitude: 52.18532617874455,
    longitude: 5.31444167363509,
    zoom: 7,
  },
  map: {
    zoomTreshold: 15,
    layerSymbols: [
      {
        objectlayerIds: [1],
        symbol: "borehole",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [1],
        minZoom: 7,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 0,
  air: {
    enabled: false,
    url: "https://demo.air.arup.com/",
  },
} as IProjectConfig;
