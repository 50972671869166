import { Action, Reducer } from "redux";

import { KnownAction } from "./actions";
import { ITree } from "../interfaces";

export type TTreeObjects = ITree | null;

const initialState = null;

export const treeObjectsReducer: Reducer<TTreeObjects> = (
  state = initialState,
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "RESET_TREE_OBJECTS":
      return initialState;
    case "INSERT_TREE_OBJECTS":
      return action.payload;
    default:
      return state;
  }
};
