import React, { useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ReactMarkdown from "react-markdown";
interface IProps {
  data: any;
}
const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100%",
      paddingBottom: theme.spacing(2.5),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
      fontWeight: theme.typography.fontWeightMedium,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    accordionDetails: {
      flexDirection: "column",
    },
  });

type ClassKey = "root" | "heading" | "secondaryHeading" | "accordionDetails";
type PropsType = IProps & WithStyles<ClassKey>;

const ControlledAccordion: React.FC<PropsType> = (props) => {
  const { classes, data } = props;
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      {data.map((info, i) => (
        <Accordion
          key={info.heading}
          expanded={expanded === i}
          onChange={handleChange(i)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>
              {info.heading.split("-")[0]} {info.heading.split("-")[1]}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails className={classes.accordionDetails}>
            <ReactMarkdown key={i}>{info.content}</ReactMarkdown>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default withStyles(styles)(ControlledAccordion);
