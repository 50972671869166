import {
  FEATURE_FLAGS,
  IProjectConfig,
} from "../../store/selectedProject/actions";

/**
 * Former SRP config, hence the old references
 */

export default {
  name: "msf",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja20za3g2a3YyZGN4Mm5yejdnZjJueHQzIn0.46CPdvVzXOZewIgnG7GXag",
  },
  graphql: {
    url: "https://public.dwh-api.arup.com/msfp/graphql/",
  },
  coordinates: {
    latitude: 19.0100210384,
    longitude: -72.88448699,
    zoom: 8,
  },
  map: {
    zoomTreshold: 15,
    layerSymbols: [
      {
        objectlayerIds: [8],
        symbol: "borehole",
        geomType: "Point",
      },
      {
        objectlayerIds: [9],
        symbol: "cpt",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [8, 9],
        minZoom: 6,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 0,
  air: {
    enabled: false,
    url: "",
  },
  features: {
    ...FEATURE_FLAGS,
    createObject: true,
  },
} as IProjectConfig;
