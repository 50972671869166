import { IProjectConfig } from "../../store/selectedProject/actions";

export default {
  name: "open",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja2loYmNkZWQxcWF6MnFyc3JsN2V6OXFmIn0.X9kd3RWDekhSXXhZx74naA",
  },
  graphql: {
    url: "https://prod.dwh-api.arup.com/open/graphql/",
  },
  coordinates: {
    latitude: 38.626995,
    longitude: 34.719975,
    zoom: 6,
  },
  map: {
    zoomTreshold: 17,
    layerSymbols: [
      {
        objectlayerIds: [8],
        symbol: "borehole",
        geomType: "Point",
      },
      {
        objectlayerIds: [9],
        symbol: "cpt",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [8, 9],
        minZoom: 13,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 2,
  air: {
    enabled: true,
    url: "https://hs2.air.arup.com",
  },
} as IProjectConfig;
