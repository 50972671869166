import {
  Button,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import i18n from "../../../../i18n";

import FeatureLayers from "./FeatureLayers";
import FilterHOC from "../../FilterHOC";
import { ISelectFilter } from "../../Core";
import { ILayerGroup } from "../../../../data/types/typeStitches";

interface IProps {
  selectedLayerGroups: ILayerGroup[];
  setSelectedLayerGroups: (layergroups: ILayerGroup[]) => void;
  resetMapFeatureLayers: () => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const FeatureLayerFilter: React.FC<PropsType> = (props) => {
  const {
    selectedLayerGroups,
    setSelectedLayerGroups,
    resetMapFeatureLayers,
  } = props;

  const [layerGroups, setLayerGroups] = useState<ILayerGroup[]>([]);

  useEffect(() => {
    if (!selectedLayerGroups || !layerGroups) return;
    let filters: ISelectFilter[] = [];
    for (const key of Object.keys(selectedLayerGroups)) {
      const objectLayers: any[] = selectedLayerGroups[key];
      if (!objectLayers.length) continue;
      objectLayers.forEach((objectLayer) => {
        filters.push({
          id: objectLayer.objectlayerId,
          name: objectLayer.objectlayerName,
          type: "featureLayers",
          deselect: () =>
            setSelectedLayerGroups({
              ...selectedLayerGroups,
              [key]: selectedLayerGroups[key].filter(
                (x) => x.objectlayerId !== objectLayer.objectlayerId,
              ),
            }),
        });
      });
    }
  }, [selectedLayerGroups]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FilterHOC
      title={
        <>
          <Typography variant="h2" color="textPrimary">
            {i18n.t("mapFeatureLayer")}
          </Typography>
          <Button variant="text" size="small" onClick={resetMapFeatureLayers}>
            {i18n.t("restoreDefaults")}
          </Button>
        </>
      }
      selects={
        <FeatureLayers
          selectedLayerGroups={selectedLayerGroups}
          setSelectedLayerGroups={setSelectedLayerGroups}
          setLayerGroups={setLayerGroups}
        />
      }
    />
  );
};

export default withStyles(styles)(FeatureLayerFilter);
