import {
  Button,
  createStyles,
  TableSortLabel,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";

import { ISorterKey, SortType } from "./SortBy";

interface IProps {
  sortKey: ISorterKey;
  sorter: (filter: ISorterKey) => void;
  isActive: SortType | null;
  isAsc: boolean;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    button: {
      height: 16,
      borderRadius: 4,
      marginLeft: theme.spacing(),
      backgroundColor: theme.palette.primary.light,
      fontSize: 10,
      textTransform: "none",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      "&:disabled": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
    buttonIcon: {
      fontSize: 12,
      color: theme.palette.primary.main,
    },
    buttonIconActive: {
      color: theme.palette.background.paper,
    },
  });

type ClassKey = "root" | "button" | "buttonIcon" | "buttonIconActive";
type PropsType = IProps & WithStyles<ClassKey>;

const SortByButton: React.FC<PropsType> = (props) => {
  const { classes, sorter, isActive, isAsc, sortKey } = props;

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={() => sorter(sortKey)}
      key={sortKey.label}
    >
      {sortKey.label}
      {isActive === sortKey.label ? (
        <TableSortLabel
          active={true}
          direction={isAsc ? "asc" : "desc"}
          classes={{
            icon: classes.buttonIcon,
          }}
        />
      ) : (
        <TableSortLabel
          active={true}
          style={{
            opacity: 0.2,
          }}
          direction={isAsc ? "asc" : "desc"}
          classes={{
            icon: classes.buttonIcon,
            active: classes.buttonIconActive,
          }}
        />
      )}
    </Button>
  );
};

export default withStyles(styles)(SortByButton);
