import {
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import i18n from "../../../i18n";
import SortByButton from "./SortByButton";
import { sortList } from "./utils/utils";

export type SortType = "dateCreated" | "dateImported" | "dataScore";

export interface ISorterKey {
  label: SortType;
  key: string;
}

interface IProps {
  items?: any[];
  sortKeys: ISorterKey[];
  setItems?: (list: any[]) => void;
  setSortKey?: (selected: ISorterKey) => void;
  activeSortKey?: ISorterKey;
  setIsAscParent?: (boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      justifyContent: "space-between",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    button: {
      marginLeft: theme.spacing(),
    },
  });

type ClassKey = "root" | "flex" | "button";
type PropsType = IProps & WithStyles<ClassKey>;

const SortBy: React.FC<PropsType> = (props) => {
  const {
    classes,
    setItems,
    items,
    setSortKey,
    activeSortKey,
    setIsAscParent,
  } = props;
  const [sortKeys] = useState(props.sortKeys);
  const [activeSort, setActiveSort] = useState<SortType | null>(
    activeSortKey ? activeSortKey.label : null,
  );
  const [isAsc, setIsAsc] = useState<boolean>(true);

  const sorter = (sortKey: ISorterKey) => {
    setIsAsc(!isAsc);
    setActiveSort(sortKey.label);
    if (setSortKey && setIsAscParent) {
      setIsAscParent(isAsc);
      setSortKey(sortKey);
    } else if (setItems && items) {
      setItems(sortList([...items], sortKey.key, isAsc));
    }
  };

  return (
    <div className={clsx(classes.root, classes.flex)}>
      <Typography variant="caption" color="textPrimary">
        {i18n.t("sortBy")}:
      </Typography>

      {sortKeys?.map(
        (filter) =>
          filter.label !== i18n.t("libraryCardTooltip.category") && (
            <SortByButton
              key={filter.label}
              sortKey={filter}
              sorter={sorter}
              isActive={activeSort}
              isAsc={isAsc}
            />
          ),
      )}
    </div>
  );
};

export default withStyles(styles)(SortBy);
