import React, { useState, useEffect } from "react";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";

import i18n from "../../../i18n";
import TreeRow from "./TreeRow";
import {
  GET_HIERARCHIC_TREE_ROOT,
  GET_HIERARCHIC_TREE_2,
  GET_HIERARCHIC_TREE_3,
} from "../../../data/treeQueries";
import { selectedObjectsActions } from "../../../store/selectedObjects";
import { rootObjectActions } from "../../../store/rootObjects";
import { RootState } from "../../../store";
import { ITree } from "../../../store/interfaces";
import { treeObjectsActions } from "../../../store/treeObjects";
import { IObject } from "../../../data/types/typeStitches";
import { getIsObjectHierarchical } from "../../../utils/objects";

interface IProps {
  setIsTabularViewOpen: (isTabularViewOpen: boolean) => void;
  setSelectAll: (flag: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    spinner: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    subheader: {
      color: theme.palette.text.secondary,
    },
    tree: {
      overflow: "auto",
      padding: theme.spacing(),
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(0),
    },
    button: {
      textTransform: "capitalize",
    },
    arupLogo: {
      marginTop: theme.spacing(1),
      width: theme.spacing(7),
    },
  });

type ClassKey =
  | "root"
  | "spinner"
  | "subheader"
  | "tree"
  | "footer"
  | "button"
  | "arupLogo";

type PropsType = IProps & WithStyles<ClassKey>;

const Tree: React.FC<PropsType> = (props) => {
  const { classes, setIsTabularViewOpen, setSelectAll } = props;

  const dispatch = useDispatch();
  const layerGroups = useSelector((state: RootState) => state.layerGroups);

  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );

  const rootObjects = useSelector((state: RootState) => state.rootObjects);
  const tree = useSelector((state: RootState) => state.treeObjects);
  const selectedProject = useSelector(
    (state: RootState) => state.selectedProject,
  );

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const defaultObjectType = layerGroups[0]?.objectlayers[0]?.objectlayerName;

  const hasHierarchicLayerGroup =
    layerGroups.findIndex(
      (layerGroup) => layerGroup.objectlayergroupIshierarchical,
    ) !== -1;

  const getObjectType = () =>
    // If rootobject was not set by Search, we ignore the objectType set by Search as well
    (rootObjects.entryObject as IObject)?.objectLayer?.objectlayerName ||
    defaultObjectType;

  const getQuery = (type: string) => {
    // Keep the nullcheck on type here since layers[1] and layers[2] will be undefined on fresh load
    // Causing the wrong query to be returned

    if (!type || !hasHierarchicLayerGroup) return GET_HIERARCHIC_TREE_ROOT;
    if (type === layerGroups[0].objectlayers[1]?.objectlayerName)
      return GET_HIERARCHIC_TREE_2;
    if (type === layerGroups[0].objectlayers[2]?.objectlayerName)
      return GET_HIERARCHIC_TREE_3;

    // Default query supports both hierarchical and singular object retrieval
    return GET_HIERARCHIC_TREE_ROOT;
  };

  const [query, setQuery] = useState<any>(getQuery(getObjectType()));
  const [getData, { loading, error }] = useLazyQuery(query, {
    onCompleted: ({ allObjects }) => {
      const initialTree = getInitialTransformedTree(allObjects);
      const { children, isActive, ...root } = initialTree.root[0] as any;
      if (rootObjects?.entryObject?.objectLongId !== root?.objectLongId) {
        dispatch(
          selectedObjectsActions.insertSelectedObject(
            rootObjects.entryObject as IObject,
          ),
        );
      }
      dispatch(rootObjectActions.insertRootObject(root));
      dispatch(selectedObjectsActions.insertSelectedObject(root));
      dispatch(treeObjectsActions.insertTreeObjects(initialTree));
      if (root?.objectLongId)
        history.push(`/${selectedProject?.name}/object/${root.objectLongId}`);
    },
  });

  useEffect(
    () => {
      const type = getObjectType();
      const query = getQuery(type);
      setQuery(query);

      getData({
        variables: {
          objectLongId: rootObjects?.entryObject?.objectLongId,
        },
      });
    },
    // GraphQL query only (re)runs after selecting a (new) object in Search.tsx
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootObjects?.entryObject?.objectLongId],
  );

  useEffect(() => {
    updateTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedObjects]);

  if (loading) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    enqueueSnackbar(i18n.t("error.queryGraphqlError"), { variant: "error" });
  }

  // API returns self reference on objectChildren
  const removeSelf = (originalNode: any, nodes: any) =>
    nodes?.filter((n) => n.objectId !== originalNode.objectId);

  const extractNodeAndTagIsActive = (objects: any[], savedObjects?: any[]) => {
    return objects?.map((obj) => {
      const node = obj.node ? obj.node : obj;
      const objectId = node.objectId;

      return {
        ...node,
        isActive:
          // Check selectedObjects[0] instead of rootObject so deselection is possible
          (selectedObjects[0] && selectedObjects[0].objectId === objectId) ||
          (savedObjects?.length &&
            savedObjects?.findIndex((o) => {
              const objId = o.node ? o.node.objectId : o.objectId;
              return objId === objectId;
            }) !== -1),
      };
    });
  };

  const getInitialTransformedTree = (allObjects) => {
    const tree: ITree = {
      root: [
        {
          children: [{ grandChildren: [] }],
        },
      ],
    };

    const getRoot = (object: any) => {
      if (getIsObjectHierarchical(object.node)) {
        const layerName = object.node.objectLayer?.objectlayerName;
        if (layerName === layerGroups[0]?.objectlayers[2]?.objectlayerName) {
          return object?.node?.objectParents?.edges[0]?.node?.objectParents
            ?.edges[0]?.node;
        }

        if (layerName === layerGroups[0]?.objectlayers[1]?.objectlayerName) {
          return object?.node?.objectParents?.edges[0]?.node;
        }
      }

      return object?.node;
    };

    allObjects.edges.forEach((o) => {
      const hierarchicalTree = getRoot(o);
      if (!hierarchicalTree) {
        enqueueSnackbar(i18n.t("noParents"), {
          variant: "warning",
        });

        return;
      }

      const { objectChildren, ...root } = hierarchicalTree;
      tree.root = extractNodeAndTagIsActive([root]);

      tree.root[0].children = [];
      const children = removeSelf(root, objectChildren?.edges);
      const taggedchildren = extractNodeAndTagIsActive(children);

      taggedchildren?.forEach((b) => {
        const layerName = `${b.objectLayer?.objectlayerName}`;
        if (layerName === layerGroups[0]?.objectlayers[1]?.objectlayerName) {
          const { objectChildren, ...building } = b;
          const grandChildren = removeSelf(building, objectChildren.edges);

          const taggedGrandChildren = extractNodeAndTagIsActive(grandChildren);

          building.grandChildren = taggedGrandChildren;
          building.isActive = b.isActive;
          tree.root[0].children.push(building);
        }
      });
    });

    return tree;
  };

  const updateTree = () => {
    if (tree) {
      const updatedTree = { ...tree };
      const updatedRoot = extractNodeAndTagIsActive(tree.root, selectedObjects);
      updatedTree.root = updatedRoot;
      const updatedChildren = extractNodeAndTagIsActive(
        tree.root[0].children,
        selectedObjects,
      );
      updatedTree.root[0].children = updatedChildren.map((b) => ({
        ...b,
        grandChildren: extractNodeAndTagIsActive(
          b.grandChildren,
          selectedObjects,
        ),
      }));

      dispatch(treeObjectsActions.insertTreeObjects(updatedTree));
    }
  };

  const getNumberOfChildren = () => {
    let nSecondLevelItems = 0;
    const nFirstLevelItems = tree && tree.root[0].children.length;
    tree &&
      tree.root[0].children.forEach((b) => {
        nSecondLevelItems = nSecondLevelItems + b.grandChildren.length;
      });
    return { nFirstLevelItems, nSecondLevelItems };
  };

  const clearSelection = () => {
    dispatch(selectedObjectsActions.resetSelectedObjects());
  };

  const { nFirstLevelItems, nSecondLevelItems } = getNumberOfChildren();

  if (!tree || !((tree as any)?.root[0] as any)?.objectLongId) return null;

  return (
    <>
      <Typography variant="body1" className={classes.subheader}>
        {tree?.root?.length &&
          i18n.t("displayingRoot", {
            nParentItems: tree.root.length,
            parentLabel: tree.root[0]?.objectLayer?.objectlayerName,
          })}
        {tree &&
          layerGroups[0]?.objectlayers[1] &&
          !!nFirstLevelItems &&
          i18n.t("displayingFirstLevel", {
            nFirstLevelItems,
            firstLevelLabel:
              tree.root[0]?.children[0]?.objectLayer?.objectlayerName,
          })}
        {tree &&
          layerGroups[0]?.objectlayers[2] &&
          !!nSecondLevelItems &&
          i18n.t("displayingSecondLevel", {
            nSecondLevelItems,
            secondLevelLabel:
              tree.root[0]?.children[0]?.grandChildren[0]?.objectLayer
                ?.objectlayerName,
          })}
      </Typography>
      <div className={classes.tree}>
        {tree &&
          tree.root.map((r: any, i) => {
            return [
              <TreeRow
                key={`${r.objectId}-${i}`}
                isExpanded={true}
                hasChildren={!!r.children.length}
                label={r.objectLongId}
                objectType={r?.objectLayer?.objectlayerName}
                object={r}
                setSelectAll={setSelectAll}
              />,
              r.children.map((c: any) => {
                let isExpanded =
                  c.isActive ||
                  c.grandChildren.findIndex((g) => g.isActive) !== -1;
                return [
                  <TreeRow
                    key={c.objectId}
                    isExpanded={isExpanded}
                    hasChildren={!!c.grandChildren.length}
                    label={c.objectLongId}
                    objectType={c?.objectLayer?.objectlayerName}
                    object={c}
                    setSelectAll={setSelectAll}
                  />,
                  c.grandChildren.map((g: any) => {
                    if (g.isActive) isExpanded = true;
                    if (isExpanded) {
                      return (
                        <TreeRow
                          key={g.objectId}
                          isExpanded={isExpanded}
                          label={g.objectName}
                          objectType={g?.objectLayer?.objectlayerName}
                          object={g}
                          setSelectAll={setSelectAll}
                        />
                      );
                    }
                    return null;
                  }),
                ];
              }),
            ];
          })}
      </div>
      <footer>
        <div className={classes.footer}>
          {tree && (
            <>
              <Button variant="text" size="small" onClick={clearSelection}>
                {i18n.t("clearSelection")}
              </Button>
              <Button
                disabled={!selectedObjects.length}
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsTabularViewOpen(true);
                }}
                className={classes.button}
              >
                {i18n.t("showSelected")}
              </Button>
            </>
          )}
        </div>
      </footer>
    </>
  );
};

// Use memo to prevent frequent rerenders caused by map interaction in Core.tsx
export default withStyles(styles)(React.memo(Tree));
