import React, { useEffect, useState } from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { IMapStyle, MAP_STYLES } from "./mapStyles";
import { ReactComponent as SatelliteIcon } from "../../../images/satellite-icon.svg";
import i18n from "../../../i18n";

interface IProps {
  setMapStyle: (mapStyle: IMapStyle) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    mapboxControl: {
      background: theme.palette.background.default,
      border: "none",
      boxShadow: "2px 2px 2px 0 rgba(118, 118, 118, 0.45)",
      width: "28px",
      height: "28px",
      padding: "4px",
      cursor: "pointer",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        background: theme.palette.background.paper,
      },
    },
  });

type ClassKey = "root" | "mapboxControl";
type PropsType = IProps & WithStyles<ClassKey>;

const MapStyleToggle: React.FC<PropsType> = (props) => {
  const { classes, setMapStyle } = props;

  const [isSatellite, setIsSatellite] = useState<boolean>(false);

  useEffect(() => {
    if (isSatellite) {
      setMapStyle(MAP_STYLES.aerial);
    } else {
      setMapStyle(MAP_STYLES.streets);
    }
  }, [isSatellite]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <button
      className={classes.mapboxControl}
      title={i18n.t("toggleSatteliteMapView")}
      onClick={() => {
        setIsSatellite(!isSatellite);
      }}
    >
      <SatelliteIcon width="100%" />
    </button>
  );
};

export default withStyles(styles)(MapStyleToggle);
