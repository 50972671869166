import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";

import { useSelector } from "react-redux";
import i18n from "../../../../i18n";
import { RootState } from "../../../../store";

interface IProps {
  setSelectedMenuIndex: (any) => void;
  selectedMenuIndex: number;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "flex-start",
    },
    menuItem: {
      marginRight: theme.spacing(1.5),
      height: theme.spacing(3),
      border: "1px solid black",
      borderRadius: "10px",
      textAlign: "center",
    },
  });

type ClassKey = "root" | "menuItem";
type PropsType = IProps & WithStyles<ClassKey>;

const MenuChips: React.FC<PropsType> = (props) => {
  const { classes, selectedMenuIndex, setSelectedMenuIndex } = props;

  const selectedProject = useSelector(
    (state: RootState) => state.selectedProject,
  );

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedMenuIndex(index);
  };

  const scrollTo = (id) => {
    document.getElementById(id)?.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  };

  return (
    <>
      <List className={classes.root}>
        <ListItem
          button
          selected={selectedMenuIndex === 0}
          onClick={(event) => {
            handleListItemClick(event, 0);
            scrollTo("introduction");
          }}
          className={classes.menuItem}
        >
          <ListItemText
            primary={i18n.t("documentation.headers.introduction")}
          />
        </ListItem>
        <ListItem
          button
          selected={selectedMenuIndex === 1}
          onClick={(event) => {
            handleListItemClick(event, 1);
            scrollTo("user-guide");
          }}
          className={classes.menuItem}
        >
          <ListItemText primary={i18n.t("documentation.headers.userGuide")} />
        </ListItem>
        <ListItem
          button
          selected={selectedMenuIndex === 2}
          onClick={(event) => {
            handleListItemClick(event, 2);
            scrollTo("faq");
          }}
          className={classes.menuItem}
        >
          <ListItemText primary={i18n.t("documentation.headers.faq")} />
        </ListItem>
        {selectedProject && (
          <ListItem
            button
            selected={selectedMenuIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
            className={classes.menuItem}
          >
            <ListItemText primary={selectedProject.name.toUpperCase()} />
          </ListItem>
        )}

        <ListItem
          button
          selected={selectedMenuIndex === 4}
          onClick={(event) => {
            handleListItemClick(event, 4);
            scrollTo("release-notes");
          }}
          className={classes.menuItem}
        >
          <ListItemText primary={i18n.t("documentation.headers.releaseNote")} />
        </ListItem>
        <ListItem
          button
          selected={selectedMenuIndex === 5}
          id="aa"
          onClick={(event) => {
            handleListItemClick(event, 5);
            scrollTo("tech-ref");
          }}
          className={classes.menuItem}
        >
          <ListItemText primary={i18n.t("documentation.headers.techRef")} />
        </ListItem>
      </List>
    </>
  );
};

export default withStyles(styles)(MenuChips);
