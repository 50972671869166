import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";

interface IProps {
  title: any;
  selects: any;
  organizerWidth?: string;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    selectsOrganizer: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "50% 50%",
    },
  });

type ClassKey = "root" | "selectsOrganizer";
type PropsType = IProps & WithStyles<ClassKey>;

const FilterHOC: React.FC<PropsType> = (props) => {
  const { classes, organizerWidth } = props;

  return (
    <>
      <div className={classes.root}>{props.title}</div>
      <div
        className={classes.selectsOrganizer}
        style={{
          ...(organizerWidth && { width: organizerWidth }),
        }}
      >
        {props.selects}
      </div>
    </>
  );
};

export default withStyles(styles)(FilterHOC);
