import get from "lodash.get";

import {
  numberSorter,
  dateSorter,
  stringSorter,
} from "../../../../utils/sorters";

export const sortList = (list: any[], key: string, isAsc: boolean) => {
  list.sort((a, b) => {
    const aVal = get(a, key);
    const bVal = get(b, key);

    if (typeof aVal === "number") return numberSorter(aVal, bVal, isAsc);
    if (typeof aVal === "string") return stringSorter(aVal, bVal, isAsc);
    return dateSorter(aVal, bVal, isAsc);
  });
  return list;
};

export const sortByEmpty = (list: any[], key: string, emptyFirst: boolean) => {
  const empty: any[] = [];
  const notEmpty: any[] = [];

  list.map((x) => (x[key] === "" ? empty.push(x) : notEmpty.push(x)));

  return emptyFirst ? notEmpty.concat(empty) : empty.concat(notEmpty);
};
