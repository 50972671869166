import React, { useState, useEffect, useContext } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  CircularProgress,
} from "@material-ui/core";
import { Column, Table, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import get from "lodash.get";
import { useLazyQuery } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { DraggableCore } from "react-draggable";

import { GET_PARAMETERVALUES } from "../../../../data/parameterQueries";
import i18n from "../../../../i18n";
import { columns } from "./data/Columns";
import DynamicCell from "./Cell/DynamicCell";
import {
  paramValSorter,
  concatDataKeysValues,
  filter,
  orderSorter,
  isParameterValue,
} from "./utils/utils";
import Header from "./Cell/Header";
import { GET_ASSIGNABLE_PARAMETERS_FOR_SOURCES } from "../../../../data/sourceQueries";
import { RootState } from "../../../../store";
import { useHistory } from "react-router";
import { getUrlParams } from "../../../../common/utils";
import { rootObjectActions } from "../../../../store/rootObjects";
import { selectedSourcesActions } from "../../../../store/selectedSources";
import { SharedParameterContext } from "../SharedParameterContext/SharedParameterContext";

interface IProps {
  setIsAssigningSource: (isAssigningSource: boolean) => void;
  isAssigningSource: boolean;
  setIsEditToggledOn: (isEditToggledOn: boolean) => void;
  isEditToggledOn: boolean;
  allParameters: any[];
  setAllParameters: (parameter: any) => void;
  isReset: boolean;
  setIsReset: (isReset: boolean) => void;
  isSaved: boolean;
  setIsSaved: (isSaved: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& > * .ReactVirtualized__Table__headerColumn": {
        overflow: "hidden",
      },
    },
    table: {
      flex: 1,
    },
    feedback: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    parameterValue: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: `${theme.palette.primary.light}25`,
        position: "relative",
      },
    },
    sharedParameter: {
      backgroundColor: `${theme.palette.primary.light}80`,
    },
    dragicon: {
      color: theme.palette.primary.light,
      fontSize: "1rem",
      cursor: "col-resize",
      width: 50,
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 0,
    },
    header: {
      display: "flex",
      zIndex: 1,
    },
    headerText: {
      flex: "auto",
      minWidth: 0,
    },
  });

type ClassKey =
  | "root"
  | "table"
  | "feedback"
  | "sharedParameter"
  | "parameterValue"
  | "header"
  | "headerText"
  | "dragicon";

type PropsType = IProps & WithStyles<ClassKey>;

export type Order = "asc" | "desc";

interface IFilter {
  filterBy: string;
  searchText: undefined | string;
}

const TabularViewTable: React.FC<PropsType> = (props) => {
  const {
    classes,
    isAssigningSource,
    setIsAssigningSource,
    isEditToggledOn,
    setIsEditToggledOn,
    allParameters,
    setAllParameters,
    isReset,
    setIsReset,
    isSaved,
    setIsSaved,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const { saveSelectedSource } = useContext(SharedParameterContext);

  const [assigned, setAssigned] = useState<any[]>([]);
  const [assignable, setAssignable] = useState<any[]>([]);
  const [unassigned, setUnassigned] = useState<any[]>([]);

  const [list, setList] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState<any>();
  const [sortOrder, setSortOrder] = useState<Order>("asc");
  const [filters, setFilters] = useState<IFilter[]>([]);

  const [highlightedParamValueId, setHighlightedParamValueId] = useState<
    string | null
  >(null);

  const selectedSources = useSelector(
    (state: RootState) => state.selectedSources,
  );
  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );
  const selectedProject = useSelector(
    (state: RootState) => state.selectedProject,
  );
  const urlParams = getUrlParams(history);
  const [getAssignedParamVals, assignedParamVals] = useLazyQuery(
    GET_PARAMETERVALUES,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (urlParams?.parameterValueId) {
          const source =
            data.allParameterValues.edges[0].node.parametervaluesSource;
          const object =
            data.allParameterValues.edges[0].node.parametervaluesObject;

          dispatch(rootObjectActions.insertEntryObject(object));
          dispatch(selectedSourcesActions.insertSelectedSource(source));
          saveSelectedSource(source);
        }
        const paramVals = data.allParameterValues.edges.map((row) => row.node);
        const sortedList = orderSorter([...paramVals]);
        setAssigned(sortedList);
        if (urlParams?.parameterValueId) {
          setHighlightedParamValueId(urlParams.parameterValueId);
          history.push("/" + selectedProject!.name);
        }
      },
    },
  );

  const [getAssignableParams, assignableParams] = useLazyQuery(
    GET_ASSIGNABLE_PARAMETERS_FOR_SOURCES,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const sources = data.allSources?.edges?.map((source) => {
          source.node.sourcesParametermappings = source?.node?.sourcesParametermappings?.edges?.map(
            (edge) => edge.node,
          );
          return source.node;
        });

        const paramsToAssign: any[] = [];

        // Transform to similar structure used by assignedParams
        // This makes sure sorting/filtering keeps working as intended
        sources[0]?.sourcesParametermappings?.forEach((param) => {
          const parametersParametermappings = {
            edges: [
              {
                node: {
                  parametermappingsId: param.parametermappingsId,
                  parametermappingsSortorder: param.parametermappingsSortorder,
                },
              },
            ],
          };
          const p = param.parametermappingsParameter;
          const parametervaluesParameter = {
            parametersId: p.parametersId,
            parametersElementcode: p.parametersElementcode,
            parametersParametermappings,
            parametersParameterdef: p.parametersParameterdef,
          };
          paramsToAssign.push({
            parametervaluesParameter,
          });
        });

        const sortedAssignableParams = orderSorter([...paramsToAssign]);
        setAssignable(sortedAssignableParams);
      },
    },
  );

  const getUnassigned = () => {
    const unassigned: any[] = [];
    assignable.forEach((param) => {
      if (
        assigned.findIndex(
          (item) =>
            item?.parametervaluesParameter?.parametersId ===
            param?.parametervaluesParameter?.parametersId,
        ) === -1
      ) {
        unassigned.push(param);
      }
    });
    return unassigned;
  };

  useEffect(() => {
    if (assigned.length || assignable.length) {
      setUnassigned(getUnassigned());
    }
  }, [assigned, assignable]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAllParameters([...assigned, ...unassigned]);
    setList([...assigned, ...unassigned]);
  }, [unassigned]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeChanges = () => {
    const unmodifiedAllParameters = allParameters.map((p) => {
      return {
        ...p,
        hasPendingChange: false,
        editParamValue: null,
        editQualifier: null,
        editFlag: null,
      };
    });

    setAllParameters(unmodifiedAllParameters);
    setList(filter(unmodifiedAllParameters, filters));
  };

  useEffect(() => {
    if (isSaved && allParameters.length) {
      removeChanges();
      setIsSaved(false);
    }
  }, [isSaved]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isReset && allParameters.length) {
      removeChanges();
      setIsReset(false);
    }
  }, [isReset, allParameters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSources.length && selectedObjects.length) {
      setList([]);
      setAllParameters([]);
      setAssigned([]);
      setAssignable([]);
      setUnassigned([]);
      setFilters([]);
      const objectIds = selectedObjects.map((o) => o.objectId);

      getAssignedParamVals({
        variables: {
          objectIds,
          sourceIds: selectedSources.map((s) => s.sourcesId),
        },
      });

      const sourcesName = selectedSources[0].sourcesName;

      getAssignableParams({
        variables: {
          objectIds: isAssigningSource ? undefined : objectIds,
          sourcesName,
        },
      });

      if (isAssigningSource) setIsAssigningSource(false);
    }

    if (selectedSources.length > 1 || selectedObjects.length > 1) {
      setIsEditToggledOn(false);
    }
  }, [selectedSources, selectedObjects, isSaved]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (urlParams?.parameterValueId)
      getAssignedParamVals({
        variables: {
          parameterValuesId: urlParams?.parameterValueId,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assignable.length && assigned.length) {
      let list = [...assigned, ...unassigned];

      if (isEditToggledOn) {
        // Add attributes to keep track of user changes
        const editableList = orderSorter(
          [...assigned, ...unassigned].map((param) => {
            return {
              ...param,
              hasPendingChange: false,
              editParamValue: null,
              editQualifier: null,
              editFlag: null,
            };
          }),
        );

        list = editableList;
      }

      setAllParameters([...list]);
      setList(filter([...list], filters));
    }
  }, [isEditToggledOn, assigned, assignable]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setList(filter(allParameters, filters));
  }, [filters, setFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  if (assignedParamVals.error || assignableParams.error) {
    enqueueSnackbar(i18n.t("error.queryGraphqlError"), { variant: "error" });
  }

  const rowHeight = 26;

  const handleSort = (sortBy, order) => {
    setSortBy(sortBy);
    setSortOrder(order);
    const sortedVals = paramValSorter([...list], sortBy, order === "asc");
    setList(sortedVals);
  };

  const handleFilterChange = (filterBy, searchText) => {
    const index = filters.findIndex((filter) => filter.filterBy === filterBy);
    const isFilterPresent = index !== -1;
    const copyOfFilters = [...filters];

    if (isFilterPresent && !searchText) {
      copyOfFilters.splice(index, 1);
    } else if (!isFilterPresent && searchText) {
      copyOfFilters.push({ filterBy, searchText });
    } else {
      copyOfFilters[index].searchText = searchText;
    }

    setFilters(copyOfFilters);
  };

  const handleRowChange = (row) => {
    const copy = [...allParameters];
    const index = allParameters.findIndex(
      (param) =>
        param.parametervaluesParameter.parametersId ===
        row.parametervaluesParameter.parametersId,
    );
    copy[index] = row;

    setList(filter(copy, filters));
    setAllParameters(copy);
  };

  const getSanitizedDataKey = (dataKey) =>
    Array.isArray(dataKey) ? dataKey[0] : dataKey;

  const getRelativeColumnWidths = () => {
    let newObject = {};
    columns.forEach((column) => {
      newObject = {
        ...newObject,
        [getSanitizedDataKey(column.dataKey)]: column.width,
      };
    });
    return newObject;
  };

  const [widths, setWidths] = useState<any>(getRelativeColumnWidths());

  const resizeRow = ({ dataKey, deltaX }) => {
    const prevWidths = { ...widths };
    const currentIndex = columns.findIndex((c) => c.dataKey === dataKey);

    const currentKey = getSanitizedDataKey(dataKey);
    const nextKey = getSanitizedDataKey(columns[currentIndex + 1].dataKey);

    setWidths({
      ...prevWidths,
      [currentKey]: prevWidths[currentKey] + deltaX,
      [nextKey]: prevWidths[nextKey] - deltaX,
    });
  };

  const getColumnsWithRenderAttributes = () => {
    const renderHeader = (dataKey, label, isSortable, index) => {
      return (
        <div className={classes.header}>
          <div className={classes.headerText}>
            <Header
              data-testid="vheader"
              isSortable={isSortable}
              handleSort={handleSort}
              handleFilterChange={handleFilterChange}
              sortOrder={sortOrder}
              sortBy={sortBy}
              dataKey={dataKey}
              label={label}
            />
          </div>
          {index !== columns.length - 1 && (
            <DraggableCore
              onDrag={(_event, { deltaX }) => {
                resizeRow({ dataKey, deltaX });
              }}
            >
              <div className={classes.dragicon}>⋮</div>
            </DraggableCore>
          )}
        </div>
      );
    };

    const renderCell = (value, dataKey, rowData, columnIndex) => {
      const isEmpty = Object.keys(rowData).length === 0;
      const isAssignableParam = !rowData?.parametervaluesSource;
      const isEditableCell =
        (dataKey === "parametervaluesValue" ||
          (isAssignableParam &&
            (dataKey === "parametervaluesFlag" ||
              dataKey === "parametervaluesQualifier"))) &&
        isEditToggledOn;

      return (
        <DynamicCell
          isEditable={isEmpty ? false : isEditableCell}
          initialValue={isEmpty ? i18n.t("noResults") : value}
          isAssignableParam={isAssignableParam}
          rowData={rowData}
          setRow={handleRowChange}
          dataKey={dataKey}
          isReset={isReset}
          isEditToggledOn={isEditToggledOn}
          columnIndex={columnIndex}
        />
      );
    };

    return columns.map((c, index) => {
      const getCellValue = (rowData) => {
        const isArray = typeof c.dataKey === "object";
        const isAssignableParam = !rowData?.parametervaluesSource;

        if (isAssignableParam) {
          if (c.dataKey === "parametervaluesObject.objectLongId")
            return selectedObjects[0]?.objectLongId;
          if (c.dataKey === "parametervaluesSource.sourcesName")
            return selectedSources[0]?.sourcesName;
        }

        if (!isArray) return get(rowData, c.dataKey);
        return concatDataKeysValues(rowData, c.dataKey);
      };

      return {
        ...c,
        headerRenderer: () =>
          renderHeader(c.dataKey, c.label, c.isSortable, index),
        cellRenderer: ({ rowData }) =>
          renderCell(getCellValue(rowData), c.dataKey, rowData, index),
      };
    });
  };

  return (
    <div className={classes.root}>
      {(assignedParamVals.loading || assignableParams.loading) && (
        <div className={classes.feedback}>
          <CircularProgress size={64} />
        </div>
      )}
      {!!list.length &&
        !(assignedParamVals.loading || assignableParams.loading) && (
          <div
            className={classes.table}
            id="vtable"
            data-testid="vtable"
            aria-label="vtable"
          >
            <AutoSizer
              onResize={({ width }) => {
                let newPixelWidths = {};
                for (const [key, value] of Object.entries(
                  getRelativeColumnWidths(),
                )) {
                  newPixelWidths = {
                    ...newPixelWidths,
                    [key]: (value as number) * width,
                  };
                }

                setWidths(newPixelWidths);
              }}
            >
              {({ width, height }) => (
                <Table
                  width={width}
                  scrollToIndex={
                    highlightedParamValueId
                      ? list.findIndex(
                          (parameter) =>
                            parameter.parametervaluesId ===
                            +highlightedParamValueId,
                        )
                      : 0
                  }
                  height={height}
                  headerHeight={rowHeight * 3}
                  rowHeight={rowHeight}
                  rowCount={list.length}
                  rowGetter={({ index }) => list[index]}
                  rowClassName={({ index }) => {
                    return clsx(
                      highlightedParamValueId &&
                        +highlightedParamValueId ===
                          list[index]?.parametervaluesId &&
                        classes.sharedParameter,
                      isParameterValue(list[index]) &&
                        !isEditToggledOn &&
                        classes.parameterValue,
                    );
                  }}
                >
                  {getColumnsWithRenderAttributes().map((c) => (
                    <Column
                      key={c.label}
                      {...c}
                      sortBy={sortBy}
                      width={widths[getSanitizedDataKey(c.dataKey)]}
                    />
                  ))}
                </Table>
              )}
            </AutoSizer>
          </div>
        )}
    </div>
  );
};

export default withStyles(styles)(TabularViewTable);
