import React, { useState } from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isValid } from "date-fns";

import { formatDateAPI } from "../../../../../../utils/formatDate";

interface IProps {
  initialDate: Date;
  handleChange: (e) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const EditableDateCell: React.FC<PropsType> = (props) => {
  const { initialDate, handleChange } = props;
  const [date, setDate] = useState<Date | null>(
    isValid(initialDate) ? initialDate : null,
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={date}
        onChange={(date) => {
          if (date) {
            const formattedDate = formatDateAPI(date);
            handleChange(formattedDate);
          }
          setDate(date);
        }}
        format="dd-MM-yyyy"
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(EditableDateCell);
