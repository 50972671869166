import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface IProps {
  onClick: () => void;
  fontSize?: "inherit" | "default" | "large" | "small" | undefined; // Material UI IconProps
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      color: "currentColor",
      "&.MuiIconButton-sizeSmall": {
        height: 20,
        width: 20,
      },
      "& > .MuiIconButton-label": {
        alignItems: "center",
        justifyContent: "center",
      },
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const CloseButton: React.FC<PropsType> = (props) => {
  const { classes, onClick, fontSize = "small" } = props;
  return (
    <IconButton
      aria-label="close"
      size="small"
      onClick={onClick}
      className={classes.root}
    >
      <CloseIcon fontSize={fontSize} />
    </IconButton>
  );
};

export default withStyles(styles)(CloseButton);
