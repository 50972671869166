import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

interface IProps {
  children: React.ReactNode;
  flexNumber?: any;
  overflowY?: "hidden" | "auto" | "scroll";
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      flexDirection: "column",
      padding: `${theme.spacing(2)}px ${theme.spacing(
        2,
      )}px ${theme.spacing()}px ${theme.spacing(2)}px`,
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.background.default,
      height: "fit-content",
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const Card: React.FC<PropsType> = (props) => {
  const { children, flexNumber, overflowY, classes } = props;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
      style={{
        ...(flexNumber && { flex: flexNumber }),
        ...(overflowY && { overflow: overflowY }),
      }}
    >
      {children}
    </div>
  );
};

export default withStyles(styles)(Card);
