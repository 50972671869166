import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Select,
  SelectProps,
} from "@material-ui/core";

interface IProps {
  label: string;
  labelProps?: {
    [keyof: string]: any;
  };
  selectProps: SelectProps;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      backgroundColor: theme.palette.primary.light,
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
      alignItems: "center",
      "& > label": {
        margin: `0 ${theme.spacing(2)}px`,
        color: theme.palette.text.primary,
      },
    },
    selectRoot: {
      backgroundColor: theme.palette.primary.contrastText,
    },
  });

type ClassKey = "root" | "selectRoot";
type PropsType = IProps & WithStyles<ClassKey>;

const OctoSelect: React.FC<PropsType> = (props) => {
  const { classes, label, labelProps = {}, selectProps = {}, children } = props;
  const { classes: selectClasses = {}, ...rest } = selectProps;

  return (
    <div className={classes.root}>
      <label {...labelProps}>{label}</label>
      <Select
        classes={{ root: classes.selectRoot, ...selectClasses }}
        {...rest}
      >
        {children}
      </Select>
    </div>
  );
};

export default withStyles(styles)(OctoSelect);
