import { IObject } from "../../data/types/typeStitches";
import { AppThunk } from "../interfaces";

export const filteredObjectsActions = {
  resetFilteredObjects: (): AppThunk => (dispatch) => {
    dispatch({ type: "RESET_FILTERED_OBJECTS" });
  },
  insertFilteredObjects: (filteredObjects: IObject[]): AppThunk => (
    dispatch,
  ) => {
    dispatch({ type: "INSERT_FILTERED_OBJECTS", filteredObjects });
  },
};
