import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { EPanelSize } from "./Tabular";

interface IProps {
  children: React.ReactNode;
  title: string;
  size: EPanelSize;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.background.default,
      boxShadow: "0px 0px 5px 2px #ccc",
    },
    content: {
      flex: 1,
      // Enable overflow behaviour when minHeight is 0 on
      // parent card.
      minHeight: 0,
      position: "relative",
    },
    header: {},
  });

type ClassKey = "root" | "content" | "header";
type PropsType = IProps & WithStyles<ClassKey>;

const TabularPanel: React.FC<PropsType> = (props) => {
  const { children, title, classes, size } = props;

  const getStyle = () => {
    let style: any = {};

    if (size === EPanelSize.medium) {
      style.flex = 1;
      // Ensure that we're distributing space evenly along main axis
      // (regardless of content) and not just distributing remaining space.
      style.minHeight = 0;
    }

    if (size === EPanelSize.large) {
      style.flex = 2;
    }

    return style;
  };

  return (
    <div className={classes.root} style={getStyle()}>
      {size === EPanelSize.small && (
        <div className={classes.header}>
          <Typography variant="caption" color="textPrimary">
            {title}
          </Typography>
        </div>
      )}
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default withStyles(styles)(TabularPanel);
