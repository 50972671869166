import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
interface IProps {
  value: any;
  rowData: any;
  renderValue: (value) => any;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const ReadOnlyCell: React.FC<PropsType> = (props) => {
  const { value, renderValue, classes } = props;

  return (
    <div>
      <Typography variant="body1" color="textPrimary" className={classes.root}>
        {renderValue(value)}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(ReadOnlyCell);
