import { IProjectConfig } from "../../store/selectedProject/actions";

export default {
  name: "ttfsa",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja2lnNXV2bWowOGlnMnBxamhjbWh0bXBsIn0.ltl6wWjVVnxbdhCqw9MtYw",
  },
  graphql: {
    url: "https://public.dwh-api.arup.com/ttfsa/graphql/",
  },
  coordinates: {
    latitude: 39.563,
    longitude: 34.53,
    zoom: 6,
  },
  map: {
    zoomTreshold: 15,
    layerSymbols: [
      {
        objectlayerIds: [15],
        symbol: "radius",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [12, 13, 15],
        minZoom: 10,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 0,
  air: {
    enabled: false,
    url: "",
  },
} as IProjectConfig;
