import { useLazyQuery } from "@apollo/react-hooks";
import {
  Button,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ISorterKey } from "../../../../common/components/SortBy/SortBy";
import SortPanel from "../../../../common/components/SortBy/SortPanel";
import {
  GET_LIBRARY_RESOURCES_NEXT,
  GET_LIBRARY_RESOURCES_PREVIOUS,
  GET_RESOURCES_CURSORS,
} from "../../../../data/libraryQueries";
import i18n from "../../../../i18n";
import { RootState } from "../../../../store";
import { EModalTypes } from "../../../../store/interfaces";
import { modalActions } from "../../../../store/modal";
import LibraryResourcesPaginated from "./LibraryResourcesPaginated";
import FilterBy from "./FilterBy";
import { grapheneFormatter } from "./utils";

export interface IActivePage {
  endCursor: string;
  startCursor: string;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

const initialPage: IActivePage = {
  endCursor: "",
  startCursor: "",
  hasPreviousPage: false,
  hasNextPage: false,
};

const sortKeys: ISorterKey[] = [
  {
    label: i18n.t("dateImported"),
    key: "resourcesDatecreated",
  },
  {
    label: i18n.t("fileType"),
    key: "extension",
  },
  {
    label: i18n.t("name"),
    key: "resourcesName",
  },
  {
    label: i18n.t("libraryCardTooltip.category"),
    key: "resourcesResourcetype.resourcetypesName",
  },
  {
    label: i18n.t("libraryCardTooltip.status"),
    key: "resourcesStatus.resourcestatusId",
  },
];

interface IProps {
  selectedSources: any[];
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      overflowY: "auto",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    sortBy: {},
    libraryCardContainer: {
      width: "100%",
    },
    actions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: theme.spacing(1),
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
      "& > button": {
        textTransform: "capitalize",
        backgroundColor: theme.palette.primary.light,
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        "&:disabled": {
          backgroundColor: theme.palette.secondary.dark,
        },
      },
    },
    filterSort: {
      display: "flex",
      justifyContent: "space-between",
    },
  });

type ClassKey =
  | "root"
  | "sortBy"
  | "libraryCardContainer"
  | "actions"
  | "filterSort";
type PropsType = IProps & WithStyles<ClassKey>;

const Library: React.FC<PropsType> = (props) => {
  const { classes, selectedSources } = props;
  const dispatch = useDispatch();
  const [activePageSearch, setActivePageSearch] = useState<IActivePage>(
    initialPage,
  );
  const [carouselMaxElements, setCarouselMaxElements] = useState(0);
  const [resourcesCursors, setResourcesCursors] = useState<Array<string>>([]);

  const user = useSelector((state: any) => state.user);
  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );

  const [selectedLibraryResources, setSelectedLibraryResources] = useState<any>(
    {},
  );
  const [libraryResources, setLibraryResources] = useState<any[]>([]);
  const [sortByKey, setSortByKey] = useState<ISorterKey>(sortKeys[0]);
  const [isAsc, setIsAsc] = useState<boolean>(true);

  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const [getLibraryResourcesNextPage, nextPageVars] = useLazyQuery(
    GET_LIBRARY_RESOURCES_NEXT,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (data) => completedQuery(data),
    },
  );

  const [getLibraryResourcesPreviousPage, previousPageVars] = useLazyQuery(
    GET_LIBRARY_RESOURCES_PREVIOUS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      onCompleted: (data) => completedQuery(data),
    },
  );

  const [getLibraryResourcesCursors] = useLazyQuery(GET_RESOURCES_CURSORS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: ({ allResources }) => {
      setResourcesCursors(
        allResources.edges.map((edge) => ({
          cursor: edge.cursor,
        })),
      );
    },
  });

  const completedQuery = (data) => {
    const {
      startCursor,
      endCursor,
      hasPreviousPage,
      hasNextPage,
    } = data.allResources.pageInfo;
    setActivePageSearch({
      startCursor,
      endCursor,
      hasPreviousPage,
      hasNextPage,
    });
    const transformedNodes = data.allResources.edges.map((d) => {
      let extension =
        d.node.resourcesResourcelocation.resourcelocationsName === "Speckle"
          ? "speckle"
          : d.node.resourcesName.substring(
              d.node.resourcesName.lastIndexOf(".") + 1,
            );
      return {
        ...d.node,
        resourcesDatecreated: new Date(d.node.resourcesDatecreated),
        resourcesDateobtained: new Date(d.node.resourcesDateobtained),
        extension: extension,
      };
    });
    setLibraryResources(transformedNodes);
  };

  const getNextPage = (order: string, lastItem?: string) => {
    let sortOrder = isAsc
      ? grapheneFormatter(order)
      : "-" + grapheneFormatter(order);
    getLibraryResourcesNextPage({
      variables: {
        limit: carouselMaxElements,
        endCursor: lastItem || "",
        sourceIds: selectedSources.map((s) => s.sourcesId),
        objectIds: selectedObjects.map((o) => o.objectId),
        resTypeIds: selectedCategories.map((c) => c.id),
        sortOrder: sortOrder,
      },
    });
  };

  const getPreviousPage = (order: string, firstItem: string) => {
    let sortOrder = isAsc
      ? grapheneFormatter(order)
      : "-" + grapheneFormatter(order);
    getLibraryResourcesPreviousPage({
      variables: {
        limit: carouselMaxElements,
        startCursor: firstItem,
        sourceIds: selectedSources.map((s) => s.sourcesId),
        objectIds: selectedObjects.map((o) => o.objectId),
        resTypeIds: selectedCategories.map((c) => c.id),
        sortOrder: sortOrder,
      },
    });
  };

  const getResourcesNodes = () => {
    getLibraryResourcesCursors({
      variables: {
        sourceIds: selectedSources.map((s) => s.sourcesId),
        objectIds: selectedObjects.map((o) => o.objectId),
        resTypeIds: selectedCategories.map((c) => c.id),
      },
    });
  };

  useEffect(() => {
    carouselMaxElements > 0 && getNextPage(sortByKey.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSources, selectedObjects, selectedCategories, sortByKey, isAsc]);

  useEffect(() => {
    if (carouselMaxElements > 0) {
      getNextPage(sortByKey.key);
      getResourcesNodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    carouselMaxElements,
    selectedCategories,
    selectedSources,
    selectedObjects,
    sortByKey,
    isAsc,
  ]);

  if (nextPageVars.error || previousPageVars.error) {
    enqueueSnackbar(i18n.t("error.queryGraphqlError"), { variant: "error" });
  }

  const toggleSelectedLibraryResource = (id) => {
    if (selectedLibraryResources[id]) {
      const { [id]: omit, ...rest } = selectedLibraryResources;
      setSelectedLibraryResources(rest);
    } else {
      setSelectedLibraryResources({
        ...selectedLibraryResources,
        [id]: true,
      });
    }
  };

  const handleDownload = () => {
    Object.keys(selectedLibraryResources).forEach((id) => {
      const resource = libraryResources.find((d) => d.resourcesId === +id);
      let downloadUrl =
        resource.resourcesResourcelocation.resourcelocationsName === "Speckle"
          ? resource.resourcesPath
          : resource.signedGetUrl;
      window.open(downloadUrl, "_blank");
    });
    setSelectedLibraryResources({});
  };

  const selectAllLibraryResources = () => {
    const objectSelection = {};
    for (const resource of libraryResources) {
      objectSelection[resource.resourcesId] = true;
    }
    setSelectedLibraryResources(objectSelection);
  };

  const objectKeysToArray = (object) => {
    const list: string[] = [];
    for (const key of Object.keys(object)) {
      list.push(key);
    }
    return list;
  };

  const deselectAllLibraryResources = () => {
    setSelectedLibraryResources({});
  };

  return (
    <div className={classes.root}>
      <div className={classes.filterSort}>
        <FilterBy
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
        {/* {!previousPageVars.loading && !nextPageVars.loading && ( */}
        <SortPanel
          items={libraryResources}
          sortKeys={sortKeys}
          activeSortKey={sortByKey}
          setSortKey={setSortByKey}
          selectedItems={objectKeysToArray(selectedLibraryResources)}
          selectAllItems={() => selectAllLibraryResources()}
          deselectAllItems={() => deselectAllLibraryResources()}
          setIsAsc={setIsAsc}
        />
        {/* )} */}
      </div>

      <div className={classes.libraryCardContainer}>
        <LibraryResourcesPaginated
          libraryResources={libraryResources}
          selectedLibraryResources={selectedLibraryResources}
          toggleSelectedLibraryResource={toggleSelectedLibraryResource}
          getNextPage={getNextPage}
          getPreviousPage={getPreviousPage}
          sortByKey={sortByKey.key}
          currentPage={activePageSearch}
          setCarouselMaxElements={setCarouselMaxElements}
          previousPageVars={previousPageVars}
          nextPageVars={nextPageVars}
          resourcesCursors={resourcesCursors}
        />
      </div>

      <div className={classes.actions}>
        {(user.isAdmin || user.hasWriteAccess) && (
          <>
            <Button
              variant="contained"
              color="primary"
              disabled={!Object.keys(selectedLibraryResources).length}
              onClick={() => {
                dispatch(
                  modalActions.openModal(EModalTypes.deleteResource, {
                    modalProps: {
                      selectedLibraryResources,
                      setSelectedLibraryResources,
                      refetchLibraryResources: (nextPageVars as any)?.refetch,
                    },
                  }),
                );
              }}
            >
              {i18n.t("delete")}
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={Object.keys(selectedLibraryResources).length !== 1}
              onClick={() => {
                dispatch(
                  modalActions.openModal(
                    EModalTypes.editAndUploadLibraryResources,
                    {
                      modalProps: {
                        libraryResources,
                        selectedLibraryResources,
                        setSelectedLibraryResources,
                        refetchLibraryResources: (nextPageVars as any)?.refetch,
                      },
                    },
                  ),
                );
              }}
            >
              {i18n.t("edit.button")}
            </Button>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownload}
          disabled={!Object.keys(selectedLibraryResources).length}
        >
          {i18n.t("download")}
        </Button>
        {(user.isAdmin || user.hasWriteAccess) && (
          <Button
            variant="contained"
            color="primary"
            disabled={!!Object.keys(selectedLibraryResources).length}
            onClick={() => {
              dispatch(
                modalActions.openModal(
                  EModalTypes.editAndUploadLibraryResources,
                  {
                    modalProps: {
                      libraryResources,
                      selectedLibraryResources,
                      refetchLibraryResources: (nextPageVars as any)?.refetch,
                    },
                  },
                ),
              );
            }}
          >
            {i18n.t("upload")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(Library);
