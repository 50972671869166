import { ILayerGroup } from "../data/types/typeStitches";
import { GET_LAYER_GROUPS_allLayerGroups_edges_node_objectlayers_edges_node } from "../data/__generated__/GET_LAYER_GROUPS";
import { store } from "../store/store";

let flatLayers: GET_LAYER_GROUPS_allLayerGroups_edges_node_objectlayers_edges_node[] = [];
let layerGroups: ILayerGroup[] = [];
let indicesPerGroupLayer: number[] = [];

const getFlatlayers = () => {
  if (!flatLayers.length) {
    flatLayers = store.getState().layerGroups.flatMap((lg) => lg.objectlayers);
  }
  return flatLayers;
};

export const getLayerGroups = () => {
  if (!layerGroups.length) {
    layerGroups = store.getState().layerGroups;
  }
  return layerGroups;
};

export const getIndicesPerGroupLayer = () => {
  if (!indicesPerGroupLayer.length) {
    getLayerGroups().forEach((layerGroup) => {
      layerGroup.objectlayers.forEach((_, i) => {
        indicesPerGroupLayer.push(i);
      });
    });
  }
  return indicesPerGroupLayer;
};

// Objectlayers are mapped to colours by rearranging them in a list
// The index of the layers list corresponds to the index of the colours list
export const getFlattenedLayerIds = () =>
  getFlatlayers().map((l) => l.objectlayerId);

export const getFlattenedLayerNames = () =>
  getFlatlayers().map((l) => l.objectlayerName);

export const getColourIndexByLayerId = (layerId) =>
  getFlattenedLayerIds().indexOf(layerId);

export const getColourIndexByLayerName = (layerName) =>
  getFlattenedLayerNames().indexOf(layerName);

export const getLayerNameById = (layerId: number) => {
  const layers = getFlatlayers().filter((l) => l.objectlayerId === layerId);
  return layers[0].objectlayerName;
};

export const getLayerIdByName = (layerName: string) => {
  const layers = getFlatlayers().filter((l) => l.objectlayerName === layerName);
  return layers[0].objectlayerId;
};
