import { gql } from "@apollo/client";

export const GET_LIBRARY_RESOURCES_NEXT = gql`
  query GET_LIBRARY_RESOURCES_NEXT(
    $limit: Int
    $endCursor: String
    $sourceIds: [ID]
    $objectIds: [ID]
    $resTypeIds: [ID]
    $sortOrder: String
  ) {
    allResources(
      first: $limit
      after: $endCursor
      resourcesSource_In: $sourceIds
      resourcesObject_In: $objectIds
      resourcesResourcetype_In: $resTypeIds
      order: $sortOrder
    ) {
      totalCount
      edges {
        node {
          resourcesId
          resourcesName
          resourcesDescription
          resourcesDatecreated
          resourcesDateobtained
          resourcesThumbnail
          resourcesPath
          resourcesUploaderId
          resourcesMetadata
          signedGetThumbnailUrl
          signedGetUrl
          resourcesResourcetype {
            id
            resourcetypesName
            resourcetypesId
          }
          resourcesSource {
            sourcesName
            sourcesId
          }
          resourcesResourcelocation {
            resourcelocationsName
          }
          resourcesStatus {
            resourcestatusId
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const GET_LIBRARY_RESOURCES_PREVIOUS = gql`
  query GET_LIBRARY_RESOURCES_PREVIOUS(
    $limit: Int
    $startCursor: String
    $sourceIds: [ID]
    $objectIds: [ID]
    $resTypeIds: [ID]
    $sortOrder: String
  ) {
    allResources(
      last: $limit
      before: $startCursor
      resourcesSource_In: $sourceIds
      resourcesObject_In: $objectIds
      resourcesResourcetype_In: $resTypeIds
      order: $sortOrder
    ) {
      totalCount
      edges {
        node {
          resourcesId
          resourcesName
          resourcesDescription
          resourcesDatecreated
          resourcesDateobtained
          resourcesThumbnail
          resourcesPath
          resourcesUploaderId
          resourcesMetadata
          signedGetThumbnailUrl
          signedGetUrl
          resourcesResourcetype {
            id
            resourcetypesName
            resourcetypesId
          }
          resourcesSource {
            sourcesName
            sourcesId
          }
          resourcesResourcelocation {
            resourcelocationsName
          }
          resourcesStatus {
            resourcestatusId
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const GET_RESOURCES_CURSORS = gql`
  query GET_RESOURCES_CURSORS(
    $sourceIds: [ID]
    $objectIds: [ID]
    $resTypeIds: [ID]
  ) {
    allResources(
      resourcesSource_In: $sourceIds
      resourcesObject_In: $objectIds
      resourcesResourcetype_In: $resTypeIds
    ) {
      totalCount
      edges {
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const GET_RESOURCE_TYPES = gql`
  query GET_RESOURCE_TYPES {
    allResourceTypes {
      edges {
        node {
          resourcetypesId
          resourcetypesName
          resourcetypesDescription
        }
      }
    }
  }
`;

export const GET_RESOURCE_STATUSES = gql`
  query GET_RESOURCE_STATUSES {
    allResourceStatus {
      edges {
        node {
          resourcestatusId
        }
      }
    }
  }
`;

export const GET_RELATIVE_RESOURCE_TYPES = gql`
  query($objects: [ID], $sources: [ID]) {
    allResourceTypes(
      relatedObjectId_In: $objects
      relatedSourceId_In: $sources
    ) {
      edges {
        node {
          resourcetypesId
          resourcetypesName
          resourcetypesCnames
          resourcetypesIsImage
          resourcetypesDescription
          resourcetypesSortorder
        }
      }
    }
  }
`;

export const DELETE_RESOURCES = gql`
  mutation DELETE_RESOURCES($resourceIds: [ID!]) {
    deleteMultipleResources(input: { instanceIds: $resourceIds }) {
      deletedIds
      warnings
    }
  }
`;

export const getUpdateLibaryResourceMutation = () => `
  mutation UPDATE_LIBRARY_RESOURCE(
    $metadata: JSONString
    $description: String
    $dateobtained: DateTime!
    $datecreated: DateTime
    $name: String
    $uploaderId: Int
    $object: ID!
    $source: ID!
    $resourcetype: ID!
    $resourcelocation: ID!
    $status: ID!
    $resourcesId: Int
  ) {
    updateResource(
      input: {
        resourcesMetadata: $metadata
        resourcesDescription: $description
        resourcesDateobtained: $dateobtained
        resourcesDatecreated: $datecreated
        resourcesName: $name
        resourcesUploaderId: $uploaderId
        resourcesObject: $object
        resourcesSource: $source
        resourcesResourcetype: $resourcetype
        resourcesResourcelocation: $resourcelocation
        resourcesStatus: $status
        resourcesId: $resourcesId
      }
    ) {
      resourcesId
      resourcesPath
      resourcesThumbnail
      resourcesMetadata
      resourcesDescription
      resourcesDateobtained
      resourcesDatecreated
      resourcesName
      resourcesUploaderId
      resourcesObject
      resourcesParameter
      resourcesSource
      resourcesResourcetype
      resourcesResourcelocation
      resourcesStatus 
      errors {
        field
        messages
      }
    }
  }
`;

export const getCreateLibaryResourceMutation = () => `
  mutation UPDATE_LIBRARY_RESOURCE(
    $metadata: JSONString
    $description: String
    $dateobtained: DateTime!
    $datecreated: DateTime
    $name: String
    $uploaderId: Int
    $object: ID!
    $source: ID!
    $resourcetype: ID!
    $resourcelocation: ID!
    $status: ID!
  ) {
    updateResource(
      input: {
        resourcesMetadata: $metadata
        resourcesDescription: $description
        resourcesDateobtained: $dateobtained
        resourcesDatecreated: $datecreated
        resourcesName: $name
        resourcesUploaderId: $uploaderId
        resourcesObject: $object
        resourcesSource: $source
        resourcesResourcetype: $resourcetype
        resourcesResourcelocation: $resourcelocation
        resourcesStatus: $status
      }
    ) {
      resourcesId
      resourcesPath
      resourcesThumbnail
      resourcesMetadata
      resourcesDescription
      resourcesDateobtained
      resourcesDatecreated
      resourcesName
      resourcesUploaderId
      resourcesObject
      resourcesParameter
      resourcesSource
      resourcesResourcetype
      resourcesResourcelocation
      resourcesStatus 
      errors {
        field
        messages
      }
    }
  }
`;
