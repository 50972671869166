import React, { useState, useEffect, useMemo } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
  TableSortLabel,
  debounce,
  Input,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";

import { Order } from "../TabularViewTable";

interface IProps {
  isSortable: boolean;
  handleSort: (sortBy, order) => void;
  handleFilterChange: (filterBy, searchText) => void;
  sortOrder: Order;
  sortBy: string;
  dataKey: any;
  label: StringConstructor;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      paddingLeft: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    pointer: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    input: {
      width: "100%",
    },
  });

type ClassKey = "root" | "pointer" | "input";

type PropsType = IProps & WithStyles<ClassKey>;

const Header: React.FC<PropsType> = (props) => {
  const {
    classes,
    isSortable,
    handleSort,
    handleFilterChange,
    sortOrder,
    sortBy,
    dataKey,
    label,
  } = props;

  const reverseOrder = sortOrder === "asc" ? "desc" : "asc";
  // TODO: might have to move state up so it's persistent between isEditToggledOn toggles
  const [filterValue, setFilterValue] = useState<undefined | string>(undefined);

  useEffect(() => {
    if (filterValue || filterValue === "") {
      debouncedFilterChangeHandler(dataKey, filterValue);
    }
  }, [filterValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const debouncedFilterChangeHandler = useMemo(() => {
    return debounce((dataKey: string, filterValue: string) => {
      return handleFilterChange(dataKey, filterValue);
    }, 500);
  }, [handleFilterChange]);

  return (
    <div className={classes.root}>
      <div
        data-testid="vsortheader"
        onClick={() => {
          isSortable && handleSort(dataKey, reverseOrder);
        }}
        className={clsx(isSortable && classes.pointer)}
      >
        <Typography variant="h1" color="textPrimary">
          {label}
        </Typography>
        {isSortable && (
          <TableSortLabel
            active={sortBy === dataKey}
            direction={sortBy === dataKey ? sortOrder : "asc"}
            onClick={() => {
              handleSort(dataKey, reverseOrder);
            }}
          />
        )}
      </div>

      <Input
        className={classes.input}
        value={filterValue || ""}
        onChange={(e) => setFilterValue(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </div>
  );
};

export default withStyles(styles)(Header);
