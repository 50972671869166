import { createMuiTheme } from "@material-ui/core/styles";

// For all vars available to config Material-UI theme:
// https://v3.material-ui.com/customization/themes/
export const octoTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#1A4C57",
      light: "#8DC0C0",
      dark: "#4F8BFF",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#F27E5C",
      light: "#BE79CC",
      dark: "#D5D5D5",
    },
    background: {
      default: "#ffffff",
      paper: "#f0f0f0",
    },
    text: {
      primary: "#303030",
      secondary: "#6C6C6C",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        height: "24px",
        backgroundColor: "#8DC0C0",
        "& > .MuiButton-label > .MuiButton-endIcon > svg": {
          fill: "#1A4C57",
          width: "16px",
        },
        "&:hover": {
          backgroundColor: "#1A4C57",
          color: "#ffffff",
          "& > .MuiButton-label > .MuiButton-endIcon > svg": {
            fill: "#ffffff",
          },
        },
      },
      containedPrimary: {
        height: "24pt",
        "&:hover": {
          backgroundColor: "#1A4C57",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#ffffff",
      },
    },
    MuiSelect: {
      icon: {
        top: "calc(50% - 9px)",
      },
    },
  },
  typography: {
    fontFamily: "Roboto, Arial, Helvetica, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightBold: 700,
    fontSize: 10,
    caption: {
      fontSize: 14,
      fontFamily: "Roboto, Arial, Helvetica, sans-serif",
      fontWeight: 500,
    },
    h1: {
      fontSize: 11,
      fontFamily: "Roboto, Arial, Helvetica, sans-serif",
      fontWeight: 500,
    },
    h2: {
      fontSize: 12,
      fontFamily: "Roboto, Arial, Helvetica, sans-serif",
      fontWeight: 500,
    },
    h3: {
      fontSize: 18,
      fontFamily: "Roboto, Arial, Helvetica, sans-serif",
      fontWeight: 500,
    },
    button: {
      fontSize: 11,
      textTransform: "capitalize",
    },
  },
});
