import { ThunkAction } from "redux-thunk";
import { RootStateOrAny } from "react-redux";
import { Action } from "redux";
import { Polygon, MultiPolygon } from "geojson";

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootStateOrAny,
  unknown,
  Action<string>
>;

export interface IUser {
  hasValidSession: boolean;
  employeeId: string;
  isAdmin: boolean;
  hasAccess: boolean;
  hasWriteAccess: boolean;
  name: string;
  email: string;
  avatar: string;
  projectRoleMapping: IProjectRoleMapping | {};
}

export interface IProjectRoleMapping {
  project: string;
  roles: string[];
}

export interface IModalProps {
  [key: string]: any;
}

export enum EModalTypes {
  editAndUploadLibraryResources,
  selectSingleSourceObject,
  libraryPreview,
  addToFavorites,
  deleteResource,
  parameterValueHistory,
}

interface IObject {
  objectGeom: Polygon | MultiPolygon;
  objectId: number;
  objectLayer: { objectlayerName: string };
  objectLongId: string;
  objectName: string;
  objectSearchLongStr: string;
  children: IObject[];
}

export interface ITree {
  root: {
    objectGeom?: Polygon | MultiPolygon;
    objectLongId?: string;
    objectLayer?: { objectlayerName?: string };
    children: {
      grandChildren: IObject[];
      objectGeom?: Polygon | MultiPolygon;
      objectLongId?: string;
      objectLayer?: { objectlayerName?: string };
    }[];
  }[];
}
