import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import LoginBackground from "../../images/login_background.svg";
import PageNotFoundIcon from "../../images/PageNotFoundIcon.svg";
import ArupLogo_white from "../../images/ArupLogo_white.svg";
import i18n from "../../i18n";

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100vw",
      position: "fixed",
      backgroundImage: `url(${LoginBackground})`,
      backgroundSize: "cover",
    },
    container: {
      marginTop: "-200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "80vw",
      height: "20vh",
    },
    header: {
      color: theme.palette.primary.contrastText,
      marginTop: "20px",
    },
    footer: {
      position: "absolute",
      bottom: "70px",
      display: "flex",
      color: "white",
    },
    footerYear: {
      fontWeight: 50,
      fontSize: "23.3541px",
      paddingLeft: "5px",
    },
  });

type ClassKey = "root" | "container" | "header" | "footer" | "footerYear";

type PropsType = WithStyles<ClassKey>;

const PageNotFound: React.FC<PropsType> = (props) => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img src={PageNotFoundIcon} alt="404: Page not found" />
        <Typography variant="h3" className={classes.header}>
          {i18n.t("pageNotFound")}
        </Typography>

        <div className={classes.footer}>
          <img style={{ fill: "white" }} src={ArupLogo_white} alt="" />
          <Typography className={classes.footerYear}>
            {new Date().getFullYear()}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(PageNotFound);
