import * as Sentry from "@sentry/react";
import config from "../config";

type ECategory = "error" | "warning";

export function logSentry(category: ECategory, message: string, data) {
  if (config.sentry.enabled) {
    const level =
      category === "error" ? Sentry.Severity.Error : Sentry.Severity.Warning;

    Sentry.addBreadcrumb({
      category,
      level: data,
      message,
    });

    Sentry.captureMessage(`${message} ${data}`, level);
  }
}
