import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";

import { selectedObjectsActions } from "../../../store/selectedObjects";
import { truncateString } from "../../../utils/truncateString";
import { RootState } from "../../../store";
import { getLayerColourByIndex } from "../../../common/colors";
import { octoTheme } from "../../../common/mui";
import { getColourIndexByLayerId } from "../../../utils/layers";

interface IProps {
  label: string;
  objectType: string;
  object: any;
  hasChildren?: boolean;
  isExpanded: boolean;
  isLabelOnly?: boolean;
  selectAll?: boolean;
  setSelectAll?: (flag: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: 16,
      borderStyle: "solid",
      border: 1.5,
      cursor: "pointer",
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      userSelect: "none",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(),
    },
    rootSpacing: {
      width: "100%",
    },
    firstLevelSpacing: {
      marginLeft: "7.5%",
      width: "92.5%",
    },
    secondLevelSpacing: {
      marginLeft: "15%",
      width: "85%",
    },
    iconRotated: {
      transform: "rotate(180deg)",
    },
  });

type ClassKey =
  | "root"
  | "rootSpacing"
  | "firstLevelSpacing"
  | "secondLevelSpacing"
  | "iconRotated";
type PropsType = IProps & WithStyles<ClassKey>;

const TreeRow: React.FC<PropsType> = (props) => {
  const {
    classes,
    isExpanded,
    hasChildren,
    label,
    objectType,
    object,
    isLabelOnly,
  } = props;

  const dispatch = useDispatch();
  const layerId = object?.objectLayer?.objectlayerId;
  const index = getColourIndexByLayerId(layerId);
  const layerGroups = useSelector(
    (state: RootState) => state.layerGroups || [],
  );

  const formatLabel = () => {
    const maxStringSize = window.innerWidth > 1920 ? 40 : 38;
    if (
      objectType !== layerGroups[0]?.objectlayers[0]?.objectlayerName ||
      objectType !== layerGroups[1]?.objectlayers[0]?.objectlayerName ||
      label.length <= maxStringSize ||
      window.innerWidth < 1600
    )
      return label;

    return truncateString(label, maxStringSize);
  };

  return (
    <div
      className={clsx(
        classes.root,
        !isLabelOnly &&
          ((objectType === layerGroups[0]?.objectlayers[0]?.objectlayerName ||
            objectType === layerGroups[1]?.objectlayers[0]?.objectlayerName ||
            objectType === layerGroups[1]?.objectlayers[1]?.objectlayerName ||
            objectType === layerGroups[1]?.objectlayers[2]?.objectlayerName) &&
            classes.rootSpacing,
          objectType === layerGroups[0]?.objectlayers[2]?.objectlayerName &&
            classes.secondLevelSpacing),
        objectType === layerGroups[0]?.objectlayers[1]?.objectlayerName &&
          classes.firstLevelSpacing,
      )}
      style={{
        borderColor: getLayerColourByIndex(index).outline,
        color: getLayerColourByIndex(index).outline,
        backgroundColor:
          isLabelOnly || object.isActive
            ? getLayerColourByIndex(index)?.fill
            : octoTheme.palette.background.default,
      }}
      onClick={() => {
        if (!isLabelOnly) {
          const isActiveAfterClick = !object.isActive;
          props.setSelectAll && props.setSelectAll(true);

          const copiedObject = {
            ...object,
            isActive: isActiveAfterClick,
          };

          if (isActiveAfterClick) {
            dispatch(selectedObjectsActions.insertSelectedObject(copiedObject));
          } else {
            dispatch(selectedObjectsActions.removeSelectedObject(copiedObject));
          }
        }
      }}
    >
      <Typography>{formatLabel()}</Typography>
      {hasChildren && (
        <KeyboardArrowUpIcon
          className={clsx(
            (object.isActive || isExpanded) && classes.iconRotated,
          )}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(TreeRow);
