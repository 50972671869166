import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import ModalContent from "./Modal/ModalContent";
import SignInText from "./SignInText";
import i18n from "../../i18n";

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      padding: "25px 50px 25px 45px",
      width: "650px",
      position: "absolute",
      top: "20px",
      background: "#F5F5F5",
      borderRadius: "20px",
      color: theme.palette.text.primary,
    },
    header: {
      flex: 1,
      fontSize: theme.spacing(3),
      textAlign: "center",
      paddingBottom: "10px",
    },
    closeButton: {
      top: "0px",
      right: "20px",
      position: "absolute",
    },
    footer: {
      textAlign: "center",
      fontSize: "14px",
    },
    anchor: { color: theme.palette.text.primary },
  });

type ClassKey = "root" | "header" | "closeButton" | "footer" | "anchor";
interface IProps {
  handleClose: () => void;
}
type PropsType = IProps & WithStyles<ClassKey>;
const SignInHelp: React.FC<PropsType> = (props) => {
  const { classes, handleClose } = props;
  return (
    <div className={classes.root}>
      <ModalContent>
        <Typography className={classes.header} variant="h1">
          {i18n.t("signInHelpTitle")}
        </Typography>
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          size="medium"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <SignInText content={i18n.t("signInHelp1")} />
        <SignInText content={i18n.t("noAuthOrTimeOut")} />
        <SignInText content={i18n.t("noAuthCookies")} />
        <SignInText content={i18n.t("noAuthOrTimeOutManual")} />
        <SignInText content={i18n.t("signInDemoProject")} />
        <Typography className={classes.footer}>
          {i18n.t("supportContact")}
          <a
            className={classes.anchor}
            href="mailto:Ikumi.nakanishi@arup.com;michele.palmieri@arup.com"
          >
            {i18n.t("here")}
          </a>
          .
        </Typography>
      </ModalContent>
    </div>
  );
};

export default withStyles(styles)(SignInHelp);
