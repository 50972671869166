import { featureCollection } from "@turf/helpers";
import { isLocalEnv, isP500Env } from "../../../../common/utils";
import {
  getLayerNameById,
  getFlattenedLayerIds,
} from "../../../../utils/layers";

export const addMapLayers = (layersArr: any[], mapRef, belowLayer?: string) => {
  const mapInstance = mapRef?.current?.getMap();
  layersArr.forEach((mapLayer) => {
    if (mapInstance?.getLayer(mapLayer.id)) {
      return;
    }
    if (belowLayer) {
      mapInstance?.addLayer(mapLayer, belowLayer);
    } else {
      mapInstance?.addLayer(mapLayer);
    }
  });
};

export const removeMapLayers = (mapRef, mapLayerIds, setMapLayerIds) => {
  const mapInstance = mapRef?.current?.getMap();
  mapLayerIds.forEach((layer) => {
    if (mapInstance?.getLayer(layer)) {
      mapInstance?.removeLayer(layer);
    }
  });
  setMapLayerIds([]);
};

export const getLayerZoomRange = (
  objectLayerId: number | null,
  defaultLayerVisibleZoom,
  projectConfig?,
): { min: number; max: number } => {
  let max = 24;
  let min = defaultLayerVisibleZoom;

  if (objectLayerId !== null) {
    projectConfig?.map?.layerZoomRanges?.forEach((layerZoom: any) => {
      if (layerZoom.objectlayerIds?.includes(objectLayerId)) {
        min = layerZoom.minZoom;
        max = layerZoom.maxZoom;
      }
    });
  }
  return { min: min, max: max };
};

export const layerIsVisibleAtZoom = (
  objectLayerID: number,
  zoom: number | undefined,
  defaultLayerVisibleZoom,
) => {
  // Check if the given layer is visible at a zoom level
  const zoomRange = getLayerZoomRange(objectLayerID, defaultLayerVisibleZoom);
  let hasLayer = false;
  if (zoom && zoom < zoomRange.max && zoom >= zoomRange.min) {
    hasLayer = true;
  }
  return hasLayer;
};

export const getLayerIdsByZoom = (
  visibleLayerIds: number[],
  viewport,
  defaultLayerVisibleZoom,
): number[] => {
  // Fitler the visible layer ids by the zoom settings
  const inRangeLayerIds: number[] = [];
  visibleLayerIds.forEach((visibleLayerId: number) => {
    if (
      viewport.zoom &&
      layerIsVisibleAtZoom(
        visibleLayerId,
        viewport.zoom,
        defaultLayerVisibleZoom,
      )
    ) {
      inRangeLayerIds.push(visibleLayerId);
    }
  });
  return inRangeLayerIds;
};

export const updateInteractiveLayers = (
  visibleLayerIds,
  viewport,
  defaultLayerVisibleZoom,
  setClickEventLayers,
  setHoverEventLayers,
) => {
  const visibleLayerNames: string[] = [];
  visibleLayerIds.forEach((layerId: number) => {
    if (layerIsVisibleAtZoom(layerId, viewport.zoom, defaultLayerVisibleZoom)) {
      const layerName = getLayerNameById(layerId);
      visibleLayerNames.push(layerName + "-fill");
      visibleLayerNames.push(layerName + "-line");
      visibleLayerNames.push(layerName + "-point");
    }
  });
  setClickEventLayers(["child-features-fill", ...visibleLayerNames]);
  setHoverEventLayers(["child-features-fill", ...visibleLayerNames]);
};

export const getMapLayerSymbol = (
  geomType: string,
  layerId: number,
  projectConfig,
) => {
  const symbols = projectConfig.map?.layerSymbols?.filter((layerSymbol) => {
    return (
      layerSymbol.geomType === geomType &&
      layerSymbol.objectlayerIds.includes(layerId)
    );
  });
  if (symbols.length > 0) return symbols[0];
  else return null;
};

export const getLayerIdsWithSymbols = (
  projectConfig,
  geomType = "Point",
): number[] => {
  const allLayerIds = getFlattenedLayerIds();
  const layerIdsWithSymbol: number[] = [];
  allLayerIds.forEach((layerId) => {
    if (layerId) {
      const layerSymbol = getMapLayerSymbol(geomType, layerId, projectConfig);
      if (layerSymbol) {
        layerIdsWithSymbol.push(layerId);
      }
    }
  });

  return layerIdsWithSymbol;
};

export const filterDataByLayer = (ftrCollection: any, layerName: string) => {
  // Get features that match the filter
  const filteredFeatures: any[] = [];
  const hasHierarchicalLayerGroups = isP500Env() || isLocalEnv();
  ftrCollection.features?.forEach((ftr) => {
    if (
      !hasHierarchicalLayerGroups ||
      layerName === ftr.properties?.objectlayerName
    ) {
      // TODO: Check robustness when adding hierarchical groups in other envs
      // All layers are retrieved for hierarchical objects, as a result we get multiple objects in same location (ie. suid/building/address)
      // Drill down to rootlayer to avoid miscounts. Currently only P500 has hierarchical layerGroups
      filteredFeatures.push(ftr);
    }
  });

  // Return a feature Collection
  const newFtrCollection = featureCollection(filteredFeatures);
  return newFtrCollection;
};

export const objectLayersAdded = (layerNames: string[], mapRef: any) => {
  const mapInstance = mapRef?.current?.getMap();
  let objectLayersAdded = false;

  layerNames.forEach((layerName) => {
    const testName = layerName + "-point";
    if (mapInstance?.getLayer(testName)) {
      objectLayersAdded = true;
    }
  });

  return objectLayersAdded;
};
