import { IProjectConfig } from "../../store/selectedProject/actions";

export default {
  name: "aid",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja2loYmJxbWIwNmM5MnNtbDU4bmt5aHY4In0.r4sK4Sbq92E_zIFG65U7Ug",
  },
  graphql: {
    url: "https://prod.dwh-api.arup.com/aid/graphql/",
  },
  coordinates: {
    latitude: 28.8666,
    longitude: 81.4336,
    zoom: 9,
  },
  map: {
    zoomTreshold: 17,
    layerSymbols: [
      {
        objectlayerIds: [8],
        symbol: "borehole",
        geomType: "Point",
      },
      {
        objectlayerIds: [9],
        symbol: "cpt",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [8, 9],
        minZoom: 13,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 0,
  air: {
    enabled: true,
    url: "https://aid.air.arup.com",
  },
} as IProjectConfig;
