import { AppThunk, IUser } from "../interfaces";
import {
  allowedRoles,
  getAccount,
  getProjectRoleMapping,
  isAuthenticated,
} from "../../common/auth";
import { selectedProjectActions } from "../selectedProject/index";
import config from "../../config";
import { EEnvType } from "../../config/interfaces";
import { getUrlParams } from "../../common/utils";
import { store } from "../store";

export const userActions = {
  updateUser: (history): AppThunk => async (dispatch) => {
    dispatch({ type: "REQUEST_USER" });

    const accountInfo = getAccount();

    if (!accountInfo) {
      dispatch({ type: "NO_USER" });
      return Promise.resolve();
    }

    const avatarRes = await fetch(
      "https://graph.microsoft.com/v1.0/me/photos/48x48/$value",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "Content-Type": "image/jpg",
        },
      },
    );

    const empRes = await fetch(
      "https://graph.microsoft.com/v1.0/me?$select=employeeId",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );

    const empData = await empRes.json();

    const projectRoleMapping = getProjectRoleMapping(accountInfo);

    const getDefaultProject = () => {
      if (!projectRoleMapping["p500"] || !projectRoleMapping["p500dev"])
        return Object.keys(projectRoleMapping)[0];
      return EEnvType[config.env] === "prod" ? "p500" : "p500dev";
    };

    const projectName =
      getUrlParams(history)?.project ||
      localStorage.getItem("lastProject") ||
      getDefaultProject();

    dispatch(
      selectedProjectActions.insertSelectedProjectOnEntry(projectName, history),
    );

    const user: IUser = {
      hasValidSession: isAuthenticated(),
      employeeId: empData.employeeId,
      isAdmin: projectRoleMapping[projectName]?.includes("adminAccess"),
      hasAccess: allowedRoles.some((allowedRole) =>
        projectRoleMapping[projectName]?.includes(allowedRole),
      ),
      hasWriteAccess: projectRoleMapping[projectName]?.includes("writeAccess"),
      name: accountInfo.name as string,
      email: accountInfo.username,
      avatar: URL.createObjectURL(await (avatarRes as any).blob()),
      projectRoleMapping,
    };

    dispatch({
      type: "RECEIVE_USER",
      user,
    });

    return Promise.resolve();
  },
  updateUserAccessRoles: (projectName): AppThunk => (dispatch) => {
    const projectRoleMapping = getProjectRoleMapping(getAccount());

    const copyUser = { ...store.getState().user };

    copyUser.isAdmin = projectRoleMapping[projectName]?.includes("adminAccess");
    copyUser.hasAccess = allowedRoles.some((allowedRole) =>
      projectRoleMapping[projectName]?.includes(allowedRole),
    );
    copyUser.hasWriteAccess = projectRoleMapping[projectName]?.includes(
      "writeAccess",
    );

    dispatch({
      type: "UPDATE_USER_ACCESS_ROLES",
      user: copyUser,
    });
  },
  logoutUser: (): AppThunk => (dispatch) => {
    dispatch({
      type: "REMOVE_USER",
    });
  },
};
