import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import filesize from "filesize";
import { fileIsTooBig } from "./utils";

import i18n from "../../../../../i18n";

interface IProps {
  handleFileLoaded: (fileReaderResult: any) => void;
  selectedFile: File | null;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: 150,
      backgroundColor: theme.palette.primary.contrastText,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    textHighlight: {
      color: theme.palette.primary.main,
    },
  });

type ClassKey = "root" | "textHighlight";
type PropsType = IProps & WithStyles<ClassKey>;

const FileDrop: React.FC<PropsType> = (props) => {
  const { classes, handleFileLoaded, selectedFile } = props;

  const onDrop = useCallback(
    ([file]) => {
      handleFileLoaded(file);
    },
    [handleFileLoaded],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className={classes.root} {...getRootProps()}>
      <input {...getInputProps()} />

      {selectedFile ? (
        <>
          <Typography variant="body1" color="textPrimary">
            {selectedFile.name} {filesize(selectedFile.size)}
          </Typography>
          {fileIsTooBig(selectedFile.size) && (
            <Typography variant="body2" color="textPrimary">
              {i18n.t("uploadHint.warning")}
            </Typography>
          )}
        </>
      ) : (
        <>
          <CloudUploadIcon color="primary" fontSize="large" />

          {isDragActive ? (
            <Typography variant="body1" color="textPrimary">
              {i18n.t("uploadDraggingHint")}
            </Typography>
          ) : (
            <Typography variant="body1" color="textPrimary">
              {i18n.t("uploadHint.prefix")}{" "}
              <span className={classes.textHighlight}>
                {i18n.t("uploadHint.highlight")}
              </span>{" "}
              {i18n.t("uploadHint.suffix")}
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(FileDrop);
