import { IProjectConfig } from "../../store/selectedProject/actions";

export default {
  name: "koc",
  mapbox: {
    token:
      "pk.eyJ1IjoiaW5zaWdodC1hY2NvdW50cyIsImEiOiJja2loYmQ2ZTMxdG54MnlvNWp2bGQwaGVuIn0.Csp9vCWw-s95zBnV_R5zeQ",
  },
  graphql: {
    url: "https://prod.dwh-api.arup.com/koc/graphql/",
  },
  coordinates: { latitude: 41.2057, longitude: 29.0732, zoom: 15 },
  map: {
    zoomTreshold: 17,
    layerSymbols: [
      {
        objectlayerIds: [8],
        symbol: "borehole",
        geomType: "Point",
      },
      {
        objectlayerIds: [9],
        symbol: "cpt",
        geomType: "Point",
      },
    ],
    layerZoomRanges: [
      {
        objectlayerIds: [8, 9],
        minZoom: 13,
        maxZoom: 24,
      },
    ],
  },
  tabularDefaultView: 0,
  air: {
    enabled: false,
    url: "",
  },
} as IProjectConfig;
