import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TextField,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";

import i18n from "../../../../../../i18n";
import { stringSorter } from "../../../../../../utils/sorters";
import EditableDateCell from "./EditableDateCell";
import EditableSingleSelect from "./EditableSingleSelect";
import EditableMultiSelect from "./EditableMultiSelect";

interface IProps {
  rowData: any;
  dataKey: string;
  value: string;
  setValue: (value) => void;
  setRow: (row) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    pending: {
      backgroundColor: theme.palette.primary.light,
    },
  });

type ClassKey = "pending";
type PropsType = IProps & WithStyles<ClassKey>;

const EditableDynamicCell: React.FC<PropsType> = (props) => {
  const { rowData, dataKey, value, setValue, setRow, classes } = props;
  const isAssignableParam = !rowData?.parametervaluesSource;

  const parameterdefsType =
    rowData?.parametervaluesParameter?.parametersParameterdef
      ?.parameterdefsType;
  const parameterdefsParameterenumerationset =
    rowData?.parametervaluesParameter?.parametersParameterdef
      ?.parameterdefsParameterenumerationset;
  const parameterdefsIsMultichoice = rowData?.parametervaluesParameter?.parametersParameterdef?.parameterdefsType?.endsWith(
    "[]",
  );

  const handleChange = (
    input: React.ChangeEvent<HTMLInputElement> & Date,
    dataKey?: string,
  ) => {
    // Date does not contain `target` attribute
    const localValue = input.target ? input.target.value : input;
    setValue(localValue);

    const row = { ...rowData };
    row.hasPendingChange = true;

    if (dataKey === "parametervaluesQualifier") {
      row.editQualifier = localValue;
    } else if (dataKey === "parametervaluesFlag") {
      row.editFlag = localValue;
    } else {
      row.editParamValue = localValue;
    }
    setRow(row);
  };

  const getSelectPopulatedWithNumbers = (options, dataKey) => {
    return (
      <TextField
        value={value ? value : 0}
        select
        onChange={(e: React.ChangeEvent<HTMLInputElement> & Date) =>
          handleChange(e, dataKey)
        }
        className={clsx(rowData.hasPendingChange && classes.pending)}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  if (isAssignableParam && dataKey === "parametervaluesFlag") {
    const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    return getSelectPopulatedWithNumbers(options, dataKey);
  }

  if (isAssignableParam && dataKey === "parametervaluesQualifier") {
    const options = [0, 1, 2, 3, 4];
    return getSelectPopulatedWithNumbers(options, dataKey);
  }

  const sanitizedValue = !value || value === "undefined" ? "" : value;

  if (
    parameterdefsParameterenumerationset?.parameterEnumerations?.edges?.length
  ) {
    const sortedEnums = parameterdefsParameterenumerationset.parameterEnumerations.edges.sort(
      (a, b) => {
        const key =
          i18n.language === "en-US"
            ? "parameterenumerationsNameUk"
            : "parameterenumerationsNameNl";
        const nameA = a.node[key];
        const nameB = b.node[key];
        return stringSorter(nameA, nameB, true);
      },
    );

    if (parameterdefsIsMultichoice)
      return (
        <EditableMultiSelect
          sanitizedValue={sanitizedValue}
          handleChange={handleChange}
          rowData={rowData}
          enums={sortedEnums}
        />
      );

    return (
      <EditableSingleSelect
        sanitizedValue={sanitizedValue}
        handleChange={handleChange}
        rowData={rowData}
        enums={sortedEnums}
      />
    );
  }

  if (parameterdefsType === "Boolean") {
    return (
      <TextField
        type="boolean"
        value={sanitizedValue}
        select
        onChange={handleChange}
        className={clsx(rowData.hasPendingChange && classes.pending)}
      >
        {[
          { label: i18n.t("true"), value: "True" },
          { label: i18n.t("false"), value: "False" },
        ].map((i) => (
          <MenuItem key={i.value} value={i.value}>
            {i.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  if (parameterdefsType === "Integer") {
    return (
      <TextField
        type="number"
        value={value || ""}
        onChange={handleChange}
        className={clsx(rowData.hasPendingChange && classes.pending)}
      />
    );
  }

  if (parameterdefsType === "Numeric") {
    return (
      <TextField
        type="number"
        value={value || ""}
        InputProps={{
          inputProps: {
            // Stepsize covers a float with the most decimal points found in the P500 DWH DB
            step: "0.00000000000000001",
          },
        }}
        onChange={handleChange}
        className={clsx(rowData.hasPendingChange && classes.pending)}
      />
    );
  }

  if (parameterdefsType === "Date") {
    const date: Date | null = new Date(value);
    return <EditableDateCell initialDate={date} handleChange={handleChange} />;
  }

  // Default - parameterdefsType === "Varchar"
  return (
    <TextField
      value={value || ""}
      onChange={handleChange}
      className={clsx(rowData.hasPendingChange && classes.pending)}
    />
  );
};

export default withStyles(styles)(EditableDynamicCell);
