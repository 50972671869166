import { isBefore, isAfter } from "date-fns";

export const numberSorter = (a, b, isAsc) => (isAsc ? a - b : b - a);

export const dateSorter = (a, b, isAsc) => {
  const aDate = new Date(a);
  const bDate = new Date(b);

  if (isBefore(aDate, bDate)) return isAsc ? -1 : 1;
  if (isAfter(aDate, bDate)) return isAsc ? 1 : -1;
  return 0;
};

export const stringSorter = (a: string, b: string, isAsc) => {
  const aString = a?.toUpperCase();
  const bString = b?.toUpperCase();

  if (aString < bString) return isAsc ? -1 : 1;
  if (bString < aString) return isAsc ? 1 : -1;
  return 0;
};
