import React, { useState, useEffect } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  TableCell,
  Button,
  Tooltip,
  Typography,
  List,
  ListItem,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import HistoryIcon from "@material-ui/icons/History";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import LinkIcon from "@material-ui/icons/Link";

import EditableDynamicCell from "./EditableCell/EditableDynamicCell";
import ReadOnlyCell from "./ReadOnlyCell";
import { modalActions } from "../../../../../store/modal";
import { EModalTypes } from "../../../../../store/interfaces";
import i18n from "../../../../../i18n";
import { formatDateCell } from "../../../../../utils/formatDate";
import { getUrlParams } from "../../../../../common/utils";
import { isParameterValue } from "../utils/utils";

interface IProps {
  isEditable: boolean;
  dataKey: string;
  rowData: any;
  setRow: (row) => void;
  initialValue: string;
  isReset: boolean;
  isAssignableParam: boolean;
  isEditToggledOn: boolean;
  columnIndex: number;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
    valueCell: {
      overflow: "auto",
      marginRight: "10px",
    },
    cell: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    icon: {
      minWidth: "30px",
      cursor: "pointer",
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    deleteIcon: {
      cursor: "pointer",
      marginRight: theme.spacing(1),
    },
    firstColumn: {
      justifyContent: "flex-start",
    },
    linkIcon: {
      color: theme.palette.primary.light,
      marginRight: "0.2rem",
    },
    linkIconInvisible: {
      visibility: "hidden",
    },
  });

type ClassKey =
  | "root"
  | "cell"
  | "icon"
  | "iconContainer"
  | "deleteIcon"
  | "valueCell"
  | "firstColumn"
  | "linkIcon"
  | "linkIconInvisible";
type PropsType = IProps & WithStyles<ClassKey>;

const DynamicCell: React.FC<PropsType> = (props) => {
  const {
    isEditable,
    dataKey,
    initialValue,
    isReset,
    isAssignableParam,
    isEditToggledOn,
    rowData,
    setRow,
    columnIndex,
    classes,
  } = props;

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const getPendingValue = () => {
    if (dataKey === "parametervaluesQualifier") return rowData.editQualifier;
    if (dataKey === "parametervaluesFlag") return rowData.editFlag;
    return rowData.editParamValue;
  };

  const getValue = () => {
    const isDateCell =
      rowData?.parametervaluesParameter?.parametersParameterdef
        ?.parameterdefsType === "Date";
    if (dataKey === "parametervaluesValue" && isDateCell && initialValue) {
      return formatDateCell(new Date(initialValue));
    }
    if (dataKey === "parametervaluesValue" && !isAssignableParam)
      return JSON.parse(initialValue);
    return initialValue;
  };

  const [value, setValue] = useState<any>(
    isEditable && rowData.hasPendingChange ? getPendingValue() : getValue(),
  );

  useEffect(() => {
    if (isReset) {
      setValue(initialValue);
    } else {
      // Makes sure sorting triggers cell re-render immediately
      // Otherwise it would only show after scrolling in react-virtualized table
      setValue(
        isEditable && rowData.hasPendingChange ? getPendingValue() : getValue(),
      );
    }
  }, [isReset, initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    setValue("");
    const row = { ...rowData };
    row.hasPendingChange = true;
    row.editParamValue = null;
    setRow(row);
  };

  const renderValue = (value: any) => {
    const isNestedTable = Boolean(value?.columns);
    if (isNestedTable) return JSON.stringify(value);
    if (Array.isArray(value)) return value.join(", ");
    return value;
  };

  const openHistoryModal = () => {
    dispatch(
      modalActions.openModal(EModalTypes.parameterValueHistory, {
        modalProps: { rowData, renderValue },
      }),
    );
  };

  const renderCell = () => {
    if (!isEditable)
      return (
        <ReadOnlyCell
          value={value}
          rowData={rowData}
          renderValue={renderValue}
        />
      );

    return (
      <div className={clsx(classes.cell)}>
        <EditableDynamicCell
          dataKey={dataKey}
          value={value}
          rowData={rowData}
          setValue={setValue}
          setRow={setRow}
        />
      </div>
    );
  };

  const isParameterValueCell = () => !isEditToggledOn && !isEditable;

  const composeShareableUrlAndCopyToClipboard = () => {
    if (!isParameterValueCell()) return;
    enqueueSnackbar(i18n.t("copiedToClipboard"), { variant: "info" });
    navigator.clipboard.writeText(
      `${window.origin}/${getUrlParams(history)?.project}/parameter/${
        rowData.parametervaluesId
      }`,
    );
  };

  return (
    <>
      <TableCell
        component="div"
        className={clsx(classes.cell, columnIndex === 0 && classes.firstColumn)}
        onClick={composeShareableUrlAndCopyToClipboard}
      >
        {columnIndex === 0 && isParameterValueCell() && (
          <LinkIcon
            style={{ transform: "rotate(90deg)" }}
            className={clsx(
              classes.linkIcon,
              !isParameterValue(rowData) && classes.linkIconInvisible,
            )}
          />
        )}
        <div
          className={`${
            dataKey === "parametervaluesValue" && classes.valueCell
          }`}
        >
          {renderCell()}
        </div>
        {dataKey === "parametervaluesValue" && (
          <div className={classes.iconContainer}>
            {isEditToggledOn && (
              <DeleteForeverIcon
                onClick={handleClick}
                color="primary"
                className={classes.deleteIcon}
              />
            )}
            {rowData?.parametervaluesParameter?.parametersParameterdef?.parameterdefsType?.endsWith(
              "[]",
            ) &&
              Array.isArray(value) && (
                <Tooltip
                  title={
                    <List>
                      {value.map((item) => (
                        <ListItem key={item}>
                          <Typography>{item}</Typography>
                        </ListItem>
                      ))}
                    </List>
                  }
                >
                  <div className={classes.icon}>
                    <Button size="small" style={{ minWidth: "30px" }}>
                      <ListAltIcon color="primary" />
                    </Button>
                  </div>
                </Tooltip>
              )}

            {rowData.parametervaluesSource?.sourcesIsDynamic && (
              <Tooltip
                title={
                  !rowData.parametervaluesHistory ? (
                    <Typography>{i18n.t("edit.noHistory")}</Typography>
                  ) : (
                    <Typography>
                      {`${i18n.t("edit.lastEdit")} : ${
                        JSON.parse(rowData.parametervaluesHistory)[0]
                          .uploader_email === undefined
                          ? `${i18n.t("edit.unknown")}`
                          : JSON.parse(rowData.parametervaluesHistory)[0]
                              .uploader_email
                      } on ${new Date(
                        JSON.parse(rowData.parametervaluesHistory)[0].date,
                      ).toLocaleString()}`}
                    </Typography>
                  )
                }
              >
                <div className={classes.icon}>
                  <Button
                    onClick={openHistoryModal}
                    disabled={!Boolean(rowData.parametervaluesHistory)}
                    size="small"
                    style={{ minWidth: "30px" }}
                  >
                    <HistoryIcon color="primary" />
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </TableCell>
    </>
  );
};

export default withStyles(styles)(DynamicCell);
