import {
  getColourIndexByLayerId,
  getIndicesPerGroupLayer,
  getLayerIdByName,
} from "../../../../utils/layers";
import {
  bboxObjectFill,
  bboxObjectLine,
  bboxObjectCircle,
  mapTheme,
  activeFeaturesPolygonsFill,
  activeFeaturesPolygonsLine,
  activeFeaturesPointsCircle,
  childFill,
  childLine,
} from "../dataStyles";
import {
  addMapLayers,
  getLayerZoomRange,
  getMapLayerSymbol,
  objectLayersAdded,
} from "./utils";

interface ILayerType {
  layerNameSuffix: string;
  layerType: string;
  layerGeomType: string;
  layerPaint: (layerIndex: number) => void;
}
export const createObjectLayers = (
  layerGroups,
  defaultLayerVisibleZoom,
  mapRef,
  projectConfig,
): string[] => {
  const layerGeomTypes: ILayerType[] = [
    {
      layerNameSuffix: "fill",
      layerType: "fill",
      layerGeomType: "Polygon",
      layerPaint: (layerIndex: number) => {
        return bboxObjectFill(layerIndex);
      },
    },
    {
      layerNameSuffix: "line",
      layerType: "line",
      layerGeomType: "LineString",
      layerPaint: (layerIndex: number) => {
        return bboxObjectLine(layerIndex);
      },
    },
    {
      layerNameSuffix: "point",
      layerType: "circle",
      layerGeomType: "Point",
      layerPaint: (layerIndex: number) => {
        return bboxObjectCircle(layerIndex);
      },
    },
  ];
  const newMapLayerIds: string[] = [];

  layerGeomTypes.forEach((layerGeomType) => {
    const mapLayers: any[] = [];

    // Layer groups and layers are already sorted in Group/Layer order!
    layerGroups.forEach((layerGroup, grpIdx) => {
      layerGroup?.objectlayers.forEach((objLayer, lyrIdx) => {
        const zoomRange = getLayerZoomRange(
          objLayer.objectlayerId,
          defaultLayerVisibleZoom,
          projectConfig,
        );

        /*
          Assume the first layer group is the main/structural layer group
          Also assume it has a heirarchy, so only add the first layer from
          this group to the map.

          For all other groups add all layers.
          */
        if ((grpIdx === 0 && lyrIdx === 0) || grpIdx > 0) {
          const layerName = objLayer?.objectlayerName.replace(" ", "-");
          let mapLayer: any;
          const layerSymbol = getMapLayerSymbol(
            layerGeomType.layerGeomType,
            objLayer.objectlayerId as number,
            projectConfig,
          );

          if (layerSymbol) {
            mapLayer = {
              id: layerName + "-" + layerGeomType.layerNameSuffix,
              source: "bboxObjects",
              minzoom: zoomRange.min,
              maxzoom: zoomRange.max,
              filter: [
                "all",
                ["==", "$type", layerGeomType.layerGeomType],
                ["!has", "is_selected"],
                ["==", "objectlayerId", objLayer.objectlayerId],
              ],
              ...mapTheme.fixedStyles[layerSymbol.symbol](
                getColourIndexByLayerId(objLayer.objectlayerId),
              ),
            };
          } else {
            mapLayer = {
              id: layerName + "-" + layerGeomType.layerNameSuffix,
              source: "bboxObjects",
              type: layerGeomType.layerType,
              minzoom: zoomRange.min,
              maxzoom: zoomRange.max,
              filter: [
                "all",
                ["==", "$type", layerGeomType.layerGeomType],
                ["!has", "is_selected"],
                ["==", "objectlayerId", objLayer.objectlayerId],
              ],
              paint: layerGeomType.layerPaint(
                getColourIndexByLayerId(objLayer.objectlayerId),
              ),
            };
          }
          mapLayers.push(mapLayer);

          newMapLayerIds.push(mapLayer.id);
        }
      });
    });

    addMapLayers(mapLayers.reverse(), mapRef, "filter-cluster");
  });
  return newMapLayerIds;
};

export const createActiveLayers = (layerNames, mapRef): string[] => {
  const groupLayerOrder = getIndicesPerGroupLayer();
  const mapLayers: any[] = [];

  mapLayers.push({
    id: "active-features-polygons-fill",
    source: "activeFeatures",
    type: "fill",
    filter: ["==", "$type", "Polygon"],
    paint: activeFeaturesPolygonsFill(layerNames, groupLayerOrder),
    layout: {
      "fill-sort-key": ["to-number", ["get", "objectlayerId"]],
    },
  });

  mapLayers.push({
    id: "active-features-polygons-line",
    source: "activeFeatures",
    type: "line",
    filter: ["==", "$type", "Polygon"],
    paint: activeFeaturesPolygonsLine(layerNames, groupLayerOrder),
    layout: {
      "line-sort-key": ["to-number", ["get", "objectlayerId"]],
    },
  });

  const newMapLayerIds: string[] = [];
  if (objectLayersAdded(layerNames, mapRef)) {
    const mapInstance = mapRef?.current?.getMap();

    layerNames.forEach((layerName) => {
      const filter = [
        "all",
        ["==", "$type", "Point"],
        ["==", "objectlayerName", layerName],
      ];

      // Check if this layer has an Object Layer in the map.
      // If it does use the radius marker, otherwise use the basic point marker.
      if (mapInstance.getLayer(layerName + "-point")) {
        const radii: number[] = [0.8, 0.6];
        radii.forEach((radius, radiusIdx) => {
          const mapLayerId =
            "active-features-points-" + layerName + "-radius" + radiusIdx;
          newMapLayerIds.push(mapLayerId);
          mapLayers.push({
            id: mapLayerId,
            source: "activeFeatures",
            filter: filter,
            ...mapTheme.fixedStyles["radius"](
              getColourIndexByLayerId(getLayerIdByName(layerName)),
              radius,
            ),
          });
        });
      } else {
        const mapLayerId = "active-features-points-" + layerName + "-circle";
        newMapLayerIds.push(mapLayerId);
        mapLayers.push({
          id: mapLayerId,
          source: "activeFeatures",
          type: "circle",
          filter: filter,
          paint: activeFeaturesPointsCircle(layerNames),
        });
      }
    });

    addMapLayers(mapLayers, mapRef);
  }
  return [
    "active-features-polygons-fill",
    "active-features-polygons-line",
  ].concat(newMapLayerIds);
};

export const createChildLayers = (layerNames, mapRef): string[] => {
  const mapLayers: any[] = [];

  mapLayers.push({
    id: "child-features-fill",
    source: "childFeatures",
    type: "fill",
    paint: childFill(layerNames),
  });

  mapLayers.push({
    id: "child-features-line",
    source: "childFeatures",
    type: "line",
    paint: childLine(layerNames),
  });

  addMapLayers(mapLayers, mapRef);

  return ["child-features-fill", "child-features-line"];
};
