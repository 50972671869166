import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import Core from "../../../modules/Core/Core";
import { RootState } from "../../../store";
import { userActions } from "../../../store/user";
import {
  acquireTokenSilentAndSetLocal,
  cleanUpMsalLocalStorage,
  getAccount,
  isAuthenticated,
} from "../../auth";
import Authorizing from "../Authorizing";
import PageNotFound from "../PageNotFound";
import SignIn from "../SignIn";
import AppBar from "./AppBar";

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      color: theme.palette.secondary.light,
      "@global": {
        "*::-webkit-scrollbar": {
          width: "0.4em",
          height: "0.4em",
          backgroundColor: "rgba(206, 206, 206, .4)",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "rgba(26, 76, 87, .6)",
        },
      },
    },
    flex: {
      flex: "1",
      position: "relative",
    },
    content: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  });

type ClassKey = "root" | "flex" | "content";
type PropsType = WithStyles<ClassKey>;

const Frame: React.FC<PropsType> = (props: PropsType) => {
  const [isAuth, setIsAuth] = useState<Boolean>(isAuthenticated());
  const [isAuthorizing, setIsAuthorizing] = useState<Boolean>(false);
  const user = useSelector((state: RootState) => state.user);
  const selectedProject = useSelector(
    (state: RootState) => state.selectedProject,
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const authorizeUser = async () => {
    const accountInfo = getAccount();
    if (accountInfo) {
      await acquireTokenSilentAndSetLocal(accountInfo);
    }
    setIsAuth(isAuthenticated());
  };

  useEffect(() => {
    const init = async () => {
      if (!isAuth) {
        setIsAuthorizing(true);
        cleanUpMsalLocalStorage();
        await authorizeUser();
        setIsAuthorizing(false);
      }
    };
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const updateUser = async () => {
      await dispatch(userActions.updateUser(history));
    };

    if (isAuth && !user) {
      updateUser();
    }
  }, [isAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderComponent = () => {
    if (isAuth && selectedProject) {
      if (!user?.hasAccess) return <PageNotFound />;
      return <Core />;
    }
    if (isAuthorizing || (isAuth && !selectedProject)) return <Authorizing />;
    return <SignIn setIsAuth={setIsAuth} setIsAuthorizing={setIsAuthorizing} />;
  };

  return (
    <div className={props.classes.root}>
      <AppBar />
      <div className={props.classes.flex}>
        <div className={props.classes.content}>{renderComponent()}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Frame);
