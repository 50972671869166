import { useLazyQuery } from "@apollo/client";
import {
  createStyles,
  Divider,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import bbox from "@turf/bbox";
import buffer from "@turf/buffer";
import { feature as createFeature } from "@turf/helpers";
import { Feature, Geometry } from "geojson";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { WebMercatorViewport } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import ModalManager from "../../common/components/Modal/ModalManager";
import {
  filterUniqObjs,
  getIsIpad,
  getIsIpadPro,
  getUrlParams,
} from "../../common/utils";
import { GET_LAYER_GROUPS } from "../../data/layerGroupQueries";
import i18n from "../../i18n";
import { RootState } from "../../store";
import { layerGroupActions } from "../../store/layerGroups";
import { visibleLayerIdsActions } from "../../store/visibleLayerIds";
import { logSentry } from "../../utils/logger";
import { numberSorter, stringSorter } from "../../utils/sorters";
import Filter, { IFilter } from "./Filter/Filter";
import Map from "./Map/Map";
import Search from "./Search/Search";
import FeatureLayerFilter from "./Filter/FeatureLayers/FeatureLayerFilter";
import Tabular from "./Tabular/Tabular";
import TreeCard from "./Tree/TreeCard";
import FiltersTray from "./Filter/FiltersTray/FiltersTray";
import { ILayerGroup } from "../../data/types/typeStitches";
import { usePrevious } from "../../common/hooks/usePrevious";
import { IProjectConfig } from "../../store/selectedProject/actions";
import { rootObjectActions } from "../../store/rootObjects";
import { GET_SCOPES_AND_PROJECTS } from "../../data/scopeQueries";

interface IProps {}

// https://github.com/visgl/react-map-gl/blob/master/src/components/static-map.js#L88
export interface IMapViewport {
  width?: number | string;
  height?: number | string;
  longitude?: number;
  latitude?: number;
  zoom?: number;
  pitch?: number;
  bearing?: number;
  altitude?: number;
}

interface IMapFeatureProperties {
  objectLayerName: string;
  objectLongId: string;
  objectId: number;
}
export type MapFeature = Feature<Geometry, IMapFeatureProperties>;

export interface ISelectFilter extends IBareFilter {
  type: "featureLayers" | "mapLayers";
  deselect: () => void;
}

export interface IBareFilter {
  id: string;
  name: string;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      height: "100%",
      width: "100%",
      position: "relative",
      display: "flex",
    },
    map: {
      position: "absolute",
      zIndex: 1,
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
    },
    menus: {
      zIndex: 2,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: 352,
      maxWidth: "30vw",
      padding: theme.spacing(1),
      overflowY: "hidden",
      position: "relative",
      "& > * + *": {
        marginTop: theme.spacing(1),
      },
    },
    divider: {
      margin: ".75rem 0",
    },
    upperFiltersContainer: {},
    sideCard: {
      marginBottom: theme.spacing(1),
      padding: "1rem",
      background: theme.palette.background.default,
      "&:last-child": {
        marginBottom: theme.spacing(0),
      },
      fontWeight: 300,
    },
  });

type ClassKey =
  | "root"
  | "menus"
  | "map"
  | "divider"
  | "upperFiltersContainer"
  | "sideCard";
type PropsType = IProps & WithStyles<ClassKey>;

const Core: React.FC<PropsType> = (props) => {
  const { classes } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );
  const filteredObjects = useSelector(
    (state: RootState) => state.filteredObjects,
  );
  const rootObjects = useSelector((state: RootState) => state.rootObjects);
  const [isTabularViewOpen, setIsTabularViewOpen] = useState<boolean>(false);

  const [activeFeatures, setActiveFeatures] = useState<MapFeature[]>([]);
  const [filteredFeatures, setFilteredFeatures] = useState<MapFeature[]>([]);
  const projectConfig = useSelector(
    (state: RootState) => state.selectedProject,
  ) as IProjectConfig;
  const [viewport, setViewport] = useState<IMapViewport>(
    projectConfig?.coordinates,
  );
  const [selectAll, setSelectAll] = useState(true);

  const [activeFilters, setActiveFilters] = useState<ISelectFilter[]>([]);
  const [selectedLayerGroups, setSelectedLayerGroups] = useState<ILayerGroup[]>(
    [],
  );
  const [selectedScopes, setSelectedScopes] = useState<IFilter[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<IFilter[]>([]);

  const [sources, setSources] = useState<any[]>([]);
  const prevSelectedObjects = usePrevious(selectedObjects);

  const [scopes, setScopes] = useState<IFilter[]>([]);
  const [projects, setProjects] = useState<IFilter[]>([]);
  const [availableScopes, setAvailableScopes] = useState<IFilter[]>([]);

  const resetMapFeatureLayers = () => {
    setSelectedLayerGroups([]);
    dispatch(visibleLayerIdsActions.resetVisibleLayerIds());
  };

  const resetMapLayers = () => {
    setSelectedProjects([]);
    setSelectedScopes([]);
  };

  const clearFilters = () => {
    resetMapFeatureLayers();
    resetMapLayers();
    setActiveFilters([]);
  };

  const updateViewport = (updatedViewport: IMapViewport) => {
    setViewport({
      ...viewport,
      ...updatedViewport,
    });
  };

  const fitMapBounds = (geometry: Geometry) => {
    // Measured in pixels
    // TODO: take sidebar width into account (also header height?)
    // Map is 100vh/100vw and WebMercatorViewport needs number values
    const { innerHeight: height, innerWidth: width } = window;
    let [minX, minY, maxX, maxY] = bbox(geometry);
    if (geometry.type === "Point") {
      // Buffer points to prevent the map zooming in to < 1m
      const buf = buffer(geometry, 35, { units: "meters" });
      [minX, minY, maxX, maxY] = bbox(buf.geometry);
    }

    // Assertion of npm package fails on calc innerHeight of iPads
    const isIpad = getIsIpad(height, width);
    const isIpadPro = getIsIpadPro(height, width);
    const optimalIpadPadding = isIpadPro ? 475 : 325;
    const padding =
      isIpad || isIpadPro ? optimalIpadPadding : window.innerHeight * 0.4;

    // Create new map viewport values so we can fit bounds of map
    // Padding (in px) is applied to top/bottom/left/right
    // Ensures geom takes up remainder of screen height (i.e. padding -- geom -- padding)
    const newViewport: IMapViewport = new WebMercatorViewport({
      width,
      height,
    }).fitBounds(
      [
        [minX, minY],
        [maxX, maxY],
      ],
      { padding },
    );

    updateViewport(newViewport);
  };
  const [getLayerGroups] = useLazyQuery(GET_LAYER_GROUPS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ allLayerGroups }) => {
      const layerGroups = allLayerGroups.edges
        .map(({ node }) => node)
        .sort((a, b) =>
          numberSorter(a.objectlayergroupOrder, b.objectlayergroupOrder, true),
        );

      let copyLayerGroups: any[] = [];

      layerGroups.forEach((layerGroup) => {
        const copy = { ...layerGroup };
        const layers = layerGroup?.objectlayers.edges
          .map(({ node }) => node)
          .sort((a, b) =>
            numberSorter(a.objectlayerOrder, b.objectlayerOrder, true),
          );
        copy.objectlayers = layers;
        copyLayerGroups = [...copyLayerGroups, copy];
      });

      dispatch(layerGroupActions.insertLayerGroups(copyLayerGroups));
      dispatch(
        visibleLayerIdsActions.insertVisibleLayerIds([
          copyLayerGroups[0]?.objectlayers[0]?.objectlayerId,
        ]),
      );
    },
  });

  const [getAllScopesAndProjects, allScopesAndProjects] = useLazyQuery(
    GET_SCOPES_AND_PROJECTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ allScopes }) => {
        const scopes = allScopes.edges.map(({ node }) => {
          return { name: node.scopeName, id: node.scopeId };
        });

        const projects = allScopes.edges.map(({ node }) => {
          return {
            name: node.scopeProject.projectName,
            id: node.scopeProject.projectId,
          };
        });

        setScopes(scopes.sort((a, b) => stringSorter(a.name, b.name, true)));
        setProjects(
          filterUniqObjs(projects, "id").sort((a, b) =>
            stringSorter(a.name, b.name, true),
          ),
        );
      },
    },
  );

  useEffect(() => {
    getAllScopesAndProjects();
  }, [getAllScopesAndProjects]);

  useEffect(() => {
    if (projectConfig) getLayerGroups();
  }, [getLayerGroups]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (rootObjects.rootObject) {
      const { objectGeom } = rootObjects.rootObject;
      if (objectGeom) {
        fitMapBounds(objectGeom);
      }
    }
  }, [rootObjects.rootObject]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedObjects.length) {
      setActiveFeatures([]);
    } else {
      const features: MapFeature[] = [];
      selectedObjects.forEach((o) => {
        pushFeature(o, features);
      });

      setActiveFeatures(features);
    }
  }, [selectedObjects]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    () => {
      if (!filteredObjects.length) {
        setFilteredFeatures([]);
      } else {
        const features: MapFeature[] = [];
        filteredObjects.forEach((o) => {
          pushFeature(o, features);
        });

        setFilteredFeatures(features);
      }
    },
    [filteredObjects], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const layerGroups = useSelector((state: RootState) => state.layerGroups);
  const history = useHistory();

  useEffect(() => {
    if (!rootObjects?.entryObject?.objectLongId && layerGroups.length > 0) {
      const { objectId, parameterValueId } = { ...getUrlParams(history) };
      if (objectId)
        dispatch(
          rootObjectActions.insertEntryObject({
            objectLongId: objectId,
          }),
        );
      else if (parameterValueId) {
        setIsTabularViewOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerGroups]);

  const pushFeature = (o, features) => {
    if (!o) return;
    if (!o.objectGeom) {
      enqueueSnackbar(i18n.t("warning.noGeometry"), {
        variant: "warning",
      });
      logSentry("warning", "no geometry assigned to object", o.objectId);
      return;
    }

    const feature = createFeature(o.objectGeom, {
      objectlayerName: o.objectLayer?.objectlayerName,
      objectId: o.objectId,
      objectLongId: o.objectLongId,
    });

    features.push(feature);
  };

  const showFeatureLayerFilter = () => {
    if (!layerGroups || layerGroups.length === 0) return false;
    if (layerGroups.length > 1) return true;
    if (layerGroups.length === 1 && layerGroups[0].objectlayers.length > 1)
      return true;
    return false;
  };

  if (!projectConfig) return null;

  return (
    <div className={classes.root}>
      <div className={classes.menus}>
        <div className={classes.upperFiltersContainer}>
          <div className={classes.sideCard}>
            <Typography variant="h2" color="textPrimary">
              {i18n.t("searchByLocation")}
            </Typography>
            <Search updateViewport={updateViewport} />
          </div>
          {showFeatureLayerFilter() && (
            <div className={classes.sideCard}>
              <FeatureLayerFilter
                selectedLayerGroups={selectedLayerGroups}
                setSelectedLayerGroups={setSelectedLayerGroups}
                resetMapFeatureLayers={resetMapFeatureLayers}
              />
            </div>
          )}
          {projects.length > 0 && (
            <div className={classes.sideCard}>
              <Filter
                selectedScopes={selectedScopes}
                setSelectedScopes={setSelectedScopes}
                selectedProjects={selectedProjects}
                setSelectedProjects={setSelectedProjects}
                resetMapLayers={resetMapLayers}
                setActiveFilters={setActiveFilters}
                activeFilters={activeFilters}
                scopes={scopes}
                projects={projects}
                allScopesAndProjects={allScopesAndProjects}
                availableScopes={availableScopes}
                setAvailableScopes={setAvailableScopes}
              />

              {!!activeFilters.length && (
                <>
                  <Divider className={classes.divider} />
                  <FiltersTray
                    activeFilters={activeFilters}
                    clearFilters={clearFilters}
                  />
                </>
              )}
            </div>
          )}
        </div>
        <TreeCard
          setIsTabularViewOpen={setIsTabularViewOpen}
          setSelectAll={setSelectAll}
        />
      </div>
      {isTabularViewOpen && (
        <Tabular
          setIsTabularViewOpen={setIsTabularViewOpen}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          sources={sources}
          setSources={setSources}
          prevSelectedObjects={prevSelectedObjects}
        />
      )}
      <div className={classes.map}>
        <Map
          viewport={viewport}
          setViewport={setViewport}
          activeFeatures={activeFeatures}
          filteredFeatures={filteredFeatures}
          scopes={scopes}
          projects={projects}
          selectedScopes={selectedScopes}
          selectedProjects={selectedProjects}
          allScopesAndProjects={allScopesAndProjects}
        />
      </div>

      <ModalManager />
    </div>
  );
};

export default withStyles(styles)(Core);
