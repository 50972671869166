import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      color: theme.palette.text.primary,
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const CardTitle: React.FC<PropsType> = (props) => {
  const { classes, children } = props;
  return (
    <Typography gutterBottom={true} variant="caption" className={classes.root}>
      {children}
    </Typography>
  );
};

export default withStyles(styles)(CardTitle);
