import { IEnvConfig } from ".";
import { EEnvType } from "./interfaces";

export default {
  activeDirectory: {
    appId: "ce476409-ddd7-430d-82a4-b4c71d7bdc20",
    redirectUri: "https://octo.arup.com/",
    // redirectUri: "http://localhost:3000",
  },
  sentry: {
    enabled: false,
    dsn:
      "https://f4a08ca57ebc497ebf588e17e7260b26@o141124.ingest.sentry.io/5530532",
  },
  env: EEnvType["prod"],
} as IEnvConfig;
