import i18n from "../../../../i18n";
import { logSentry } from "../../../../utils/logger";
import { rootObjectActions } from "../../../../store/rootObjects";
import { selectedObjectsActions } from "../../../../store/selectedObjects";
import { store } from "../../../../store/store";

export const handleMapError = (e: any) => {
  logSentry(
    "error",
    i18n.t("error.mapInitError", {
      error: `${e.error.message}`,
      language: "en-US",
    }),
    e,
  );
};

export const handleOnClick = (
  event,
  mapRef,
  clickEventLayers,
  rootObject,
  mapLayerIds,
  activeFeatures,
) => {
  // Filter possible features down to features in layers that we care about
  const mapInstance = mapRef?.current?.getMap();
  const features = mapInstance?.queryRenderedFeatures(event.point, {
    layers: clickEventLayers,
  });
  // Default to first feature found
  // In future: could group by layer id and pass relevant features to different handlers
  const feature = features[0];
  if (feature) {
    // Handle click events for different layers
    if (mapLayerIds.includes(feature.layer.id)) {
      if (rootObject?.objectLongId !== feature.properties.objectLongId) {
        store.dispatch(
          rootObjectActions.insertRootObject(feature.properties) as any,
        );
        store.dispatch(
          rootObjectActions.insertEntryObject(feature.properties) as any,
        );
      }
    } else if (
      [
        "child-features-fill",
        "child-features-line",
        "child-features-point",
      ].includes(feature.layer.id)
    ) {
      const building = feature.properties;
      // TODO: can we use objectId here? Feel like this won't be unique
      // across layers. (hence using objectLongId for now)
      const existing = activeFeatures.find((f) => {
        return f.properties.objectLongId === building.objectLongId;
      });
      // When we use these objects as features, nested objects are stringified
      // so convert them back.
      // Improvement: we're converting these back into features in Core.tsx,
      // seems unnecessary. Also use types to ensure they all have same shape.
      building.objectGeom = JSON.parse(building.objectGeom);
      building.objectLayer = JSON.parse(building.objectLayer);
      if (!existing) {
        store.dispatch(
          selectedObjectsActions.insertSelectedObject(building) as any,
        );
      } else {
        store.dispatch(
          selectedObjectsActions.removeSelectedObject(building) as any,
        );
      }
    }
  }
};

export const handleOnMouseMove = (
  event,
  mapRef,
  hoverEventLayers,
  hoverIdRef,
) => {
  // Filter possible features down to features in layers that we care about
  const mapInstance = mapRef?.current?.getMap();
  const features = mapInstance?.queryRenderedFeatures(event.point, {
    layers: hoverEventLayers,
  });
  if (features.length) {
    if (hoverIdRef.current) {
      mapInstance?.removeFeatureState({
        source: hoverIdRef.current.source,
        id: hoverIdRef.current.id,
      });
    }
    // Stacking order of layers appears to be determined by order in
    // render (like svg), so look at the top-most feature.
    hoverIdRef.current = features[0];
    mapInstance?.setFeatureState(
      {
        source: hoverIdRef.current.source,
        id: hoverIdRef.current.id,
      },
      {
        hover: true,
      },
    );
  }
};

export const handleOnMouseLeave = (hoverIdRef, mapRef) => {
  if (hoverIdRef.current) {
    const mapInstance = mapRef?.current?.getMap();
    mapInstance?.setFeatureState(
      {
        source: hoverIdRef.current.source,
        id: hoverIdRef.current.id,
      },
      {
        hover: false,
      },
    );
    hoverIdRef.current = null;
  }
};
