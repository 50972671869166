import * as Msal from "@azure/msal-browser";
import { isAfter } from "date-fns";

import config from "../config";
import { IProjectRoleMapping } from "../store/interfaces";
import {
  getOctoSpecificLocalStorageObjects,
  setLocalStorageObjects,
} from "./utils";

const initMsal = () => {
  const msalConfig = {
    auth: {
      clientId: config.activeDirectory.appId,
      authority: "https://login.microsoftonline.com/arup.onmicrosoft.com",
      tenant: "arup.onmicrosoft.com",
      redirectUri: config.activeDirectory.redirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  };

  const msal = new Msal.PublicClientApplication(msalConfig as any);

  return msal;
};

export const msal = initMsal();

const loginRequest = {
  scopes: ["openid", "profile", "User.Read", "email"],
};

export const loginRedirect = () => msal.loginRedirect(loginRequest);

export const getloginPopupResponse = async () =>
  await msal.loginPopup(loginRequest);

export const getAccount = () => msal.getAllAccounts()[0];

export const logout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("idToken");
  localStorage.removeItem("expiration");
  msal.logout();
};

export const acquireTokenSilentAndSetLocal = async (accountInfo) => {
  const tokenRequest = {
    account: accountInfo,
    scopes: loginRequest.scopes,
    loginHint: accountInfo.idTokenClaims.preffered_username,
  };
  const res = await msal.acquireTokenSilent(tokenRequest);
  localStorage.setItem("accessToken", res.accessToken);
  localStorage.setItem("idToken", res.idToken);
};

export const cleanUpMsalLocalStorage = () => {
  // Deletes expired msal entries in localStorage so a proper new request can be made
  const octoObjects = getOctoSpecificLocalStorageObjects();
  localStorage.clear();
  setLocalStorageObjects(octoObjects);
};

export const allowedRoles = ["readAccess", "writeAccess", "adminAccess"];
export const isAuthenticated = () => {
  const accountInfo = getAccount();
  const accessToken = localStorage.getItem("accessToken");
  const idToken = localStorage.getItem("idToken");
  const expiration = (accountInfo?.idTokenClaims as any)?.exp;
  const expiryDate = expiration ? new Date(expiration * 1000) : new Date(0);
  const now = new Date();
  const isExpired = !isAfter(expiryDate, now);

  if (accessToken && idToken && !isExpired) return true;
  return false;
};

export const getProjectRoleMapping = (accountInfo) => {
  let projectRoleMapping: IProjectRoleMapping | {} = {};
  (accountInfo?.idTokenClaims as any)?.roles.forEach((role) => {
    const rle = role.split(":")[0];
    const project = role.split(":")[1];

    if (projectRoleMapping[project]?.length) {
      projectRoleMapping[project].push(rle);
    } else {
      projectRoleMapping[project] = [rle];
    }
  });

  return projectRoleMapping;
};
