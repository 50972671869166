import { IObject } from "../../data/types/typeStitches";
import { AppThunk } from "../interfaces";

export const selectedObjectsActions = {
  resetSelectedObjects: (): AppThunk => (dispatch) => {
    dispatch({ type: "RESET_SELECTED_OBJECTS" });
  },
  insertSelectedObjects: (selectedObjects: IObject[]): AppThunk => (
    dispatch,
  ) => {
    dispatch({ type: "INSERT_SELECTED_OBJECTS", selectedObjects });
  },
  insertSelectedObject: (selectedObject: IObject): AppThunk => (dispatch) => {
    dispatch({ type: "INSERT_SELECTED_OBJECT", selectedObject });
  },
  removeSelectedObject: (selectedObject: IObject): AppThunk => (dispatch) => {
    dispatch({ type: "REMOVE_SELECTED_OBJECT", selectedObject });
  },
};
