export const layerColours = [
  { outline: "#FA7333", fill: "#FFD0C2" },
  { outline: "#B941FF", fill: "#D6BFDB" },
  { outline: "#28AAE1", fill: "#BCD3FF" },
  { outline: "#CC0A0A", fill: "#FF6A6A" },
  { outline: "#000000", fill: "#9B9B9B" },
  { outline: "#28AF73", fill: "#87FB80" },
  { outline: "#FFD313", fill: "#FEF740" },
  { outline: "#2962B2", fill: "#28AAE1" },
];

export const getLayerColourByIndex = (i: number) => {
  return layerColours[i < 0 ? 0 : Math.min(i, layerColours.length - 1)];
};
