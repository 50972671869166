import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Dialog,
  DialogTitle,
  ListItem,
  List,
} from "@material-ui/core";
import i18n, { localesImmutable, supportedLanguages } from "../../../../i18n";
import { updateComponentTree } from "../../../..";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const LanguageDialog: React.FC<PropsType> = (props) => {
  const { open, setOpen } = props;
  const handleLngChange = (lng: string) => {
    setOpen(false);
    if (lng !== i18n.language && supportedLanguages.includes(lng)) {
      i18n.changeLanguage(lng);
      updateComponentTree();
    }
  };

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <DialogTitle>{i18n.t("chooseLanguage")}</DialogTitle>
      <List>
        {localesImmutable().map((lng) => (
          <ListItem button onClick={() => handleLngChange(lng)} key={lng}>
            {i18n.t(`${lng}`)}
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default withStyles(styles)(LanguageDialog);
