import React, { useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Menu,
  MenuItem,
  Typography,
  Divider,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";

import i18n from "../../../../i18n";
import LanguageDialog from "./LanguageDialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { logout } from "../../../auth";
import { userActions } from "../../../../store/user";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(1.5),
    },
    name: {
      marginRight: "0.5em",
      color: "#1C1C1C",
    },
    user: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      marginRight: "1em",
      color: theme.palette.primary.contrastText,
    },
    menu: { marginTop: 50 },
    menuContent: {
      minWidth: 150,
      padding: theme.spacing(2),
    },
    menuTitle: {
      marginBottom: "1em",
    },
    avatar: {
      width: 30,
      height: 30,
    },
  });

type ClassKey =
  | "root"
  | "name"
  | "menu"
  | "menuContent"
  | "menuTitle"
  | "user"
  | "avatar";
type PropsType = IProps & WithStyles<ClassKey>;

const UserMenu: React.FC<PropsType> = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [lngOpen, setLngOpen] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAccessType = () => {
    if (!user) return null;
    if (user.isAdmin) return i18n.t("access.admin");
    if (user.hasWriteAccess) return i18n.t("access.write");
    return i18n.t("access.read");
  };

  return (
    <div className={classes.root}>
      {user && (
        <div className={classes.user} onClick={handleOpen}>
          <Typography variant="h2" className={classes.name}>
            {user.name}
          </Typography>
          <Avatar
            alt={user.name}
            src={user.avatar}
            classes={{
              root: classes.avatar,
            }}
          />
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        elevation={2}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{ className: classes.menuContent }}
        className={classes.menu}
      >
        <Typography variant="h1" className={classes.menuTitle}>
          {`${i18n.t("access.access")}: ${getAccessType()}`}
        </Typography>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            setLngOpen(true);
          }}
        >
          {i18n.t("language")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();
            dispatch(userActions.logoutUser());
          }}
        >
          {i18n.t("logout")}
        </MenuItem>
      </Menu>
      <LanguageDialog open={lngOpen} setOpen={setLngOpen} />
    </div>
  );
};

export default withStyles(styles)(UserMenu);
