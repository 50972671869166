import { useEffect } from "react";

const useKeyboardEvent = (targetKey, handler) => {
  useEffect(() => {
    const handleKeydown = ({ key }) => {
      if (key === targetKey) {
        handler();
      }
    };

    window.addEventListener("keydown", handleKeydown);

    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [targetKey, handler]);
};

export default useKeyboardEvent;
