import localConfig from "./local";
import devConfig from "./dev";
import testConfig from "./test";
import prodConfig from "./prod";
import p500devProjectConfig from "./projects/p500dev";
import p500prodProjectConfig from "./projects/p500prod";
import p500testProjectConfig from "./projects/p500test";
import aidProjectConfig from "./projects/aid";
import kocProjectConfig from "./projects/koc";
import coreProjectConfig from "./projects/core";
import fordProjectConfig from "./projects/ford";
import tofasProjectConfig from "./projects/tofas";
import openProjectConfig from "./projects/open";
import wbgProjectConfig from "./projects/wbg";
import tsaProjectConfig from "./projects/tsa";
import msfpProjectConfig from "./projects/msfp";
import ttfsaProjectConfig from "./projects/ttfsa";
import hs2saProjectConfig from "./projects/hs2sa";
import schProjectConfig from "./projects/schiphol";
import DUOProjectConfig from "./projects/duo-nl-schools";
import { EEnvType } from "./interfaces";
import { IProjectConfig } from "../store/selectedProject/actions";

export interface IEnvConfig {
  activeDirectory: {
    appId: string;
    redirectUri: string;
  };
  env: EEnvType;
  sentry: {
    enabled: boolean;
    dsn: string;
    tracesSampleRate?: number;
  };
}

let config: IEnvConfig;

export const projectConfigMapping = {
  p500: p500prodProjectConfig,
  p500dev: p500devProjectConfig,
  p500test: p500testProjectConfig,
  aid: aidProjectConfig,
  koc: kocProjectConfig,
  hs2sa: hs2saProjectConfig,
  sch: schProjectConfig,
  core: coreProjectConfig,
  open: openProjectConfig,
  wbg: wbgProjectConfig,
  tsa: tsaProjectConfig,
  msf: msfpProjectConfig,
  ttfsa: ttfsaProjectConfig,
  ford: fordProjectConfig,
  tofas: tofasProjectConfig,
  "nl-schools": DUOProjectConfig,
};

export const getFlag = (config: IProjectConfig, flag: string) => {
  // this comes directly from the config file where we store
  // features enabled for the entire environment
  if (config.features && config.features[flag]) {
    return true;
  }
  // this option allows a user to enable a specific feature that
  // has not been enabled inside the config file
  if (localStorage.getItem(`OCTO-${flag}`) === "true") {
    return true;
  }

  return false;
};

const hostname = window.location.hostname;
if (hostname === "localhost") {
  config = localConfig;
} else if (hostname === "dev.octo.arup.com") {
  config = devConfig;
} else if (hostname === "test.octo.arup.com") {
  config = testConfig;
} else {
  config = prodConfig;
}

export default config;
