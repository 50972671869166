import React, { useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  MenuItem,
  Typography,
} from "@material-ui/core";

import TabularPanel from "./TabularPanel";
import CloseButton from "../../../common/components/CloseButton";
import UpperWorkspacePanel from "./UpperWorkspacePanel";
import TabularView from "./TabularView/TabularView";
import OctoSelect from "../../../common/components/OctoSelect";
import i18n from "../../../i18n";

import preset1Icon from "../../../images/preset-1.svg";
import preset2Icon from "../../../images/preset-2.svg";
import preset3Icon from "../../../images/preset-3.svg";
import preset4Icon from "../../../images/preset-4.svg";
import preset5Icon from "../../../images/preset-5.svg";
import preset6Icon from "../../../images/preset-6.svg";
import preset7Icon from "../../../images/preset-7.svg";
import radioIcon from "../../../images/radio.svg";
import radioSelectedIcon from "../../../images/radio-selected.svg";
import { IProjectConfig } from "../../../store/selectedProject/actions";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import SharedParameterProvider from "./SharedParameterContext/SharedParameterContext";

export enum EPanelSize {
  small,
  medium,
  large,
}

export const panelSizes = [
  EPanelSize.small,
  EPanelSize.medium,
  EPanelSize.large,
];

interface IPanelPreset {
  id: number;
  upper: EPanelSize;
  lower: EPanelSize;
  icon: string; // path to bundled svg
}

const presets: IPanelPreset[] = [
  {
    //Default
    id: 1,
    upper: EPanelSize.medium,
    lower: EPanelSize.medium,
    icon: preset1Icon,
  },
  {
    // Expand data sources
    id: 3,
    upper: EPanelSize.medium,
    lower: EPanelSize.small,
    icon: preset3Icon,
  },
  {
    // Expand props./library
    id: 2,
    upper: EPanelSize.small,
    lower: EPanelSize.medium,
    icon: preset2Icon,
  },
  {
    // Maximise data sources
    id: 5,
    upper: EPanelSize.large,
    lower: EPanelSize.small,
    icon: preset5Icon,
  },
  {
    // Maximise prop./library
    id: 4,
    upper: EPanelSize.small,
    lower: EPanelSize.large,
    icon: preset4Icon,
  },
  {
    // Expand both
    id: 6,
    upper: EPanelSize.large,
    lower: EPanelSize.large,
    icon: preset6Icon,
  },
  {
    // Collapse both
    id: 7,
    upper: EPanelSize.small,
    lower: EPanelSize.small,
    icon: preset7Icon,
  },
];

interface IProps {
  setIsTabularViewOpen: (isOpen: boolean) => void;
  selectAll: boolean;
  setSelectAll: (flag: boolean) => void;
  sources: any[];
  setSources: (sources: any[]) => void;
  prevSelectedObjects: any;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      zIndex: 3,
      flex: 1,
      alignSelf: "flex-end",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      "& > * + *": {
        marginTop: theme.spacing(1),
      },
    },
    actions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    menuItem: {
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    select: {
      paddingLeft: theme.spacing(),
    },
    selectImage: {
      height: 16,
    },
  });

type ClassKey = "root" | "actions" | "menuItem" | "select" | "selectImage";
type PropsType = IProps & WithStyles<ClassKey>;

const Tabular: React.FC<PropsType> = (props) => {
  const {
    classes,
    setIsTabularViewOpen,
    selectAll,
    setSelectAll,
    sources,
    setSources,
    prevSelectedObjects,
  } = props;

  const projectConfig = useSelector(
    (state: RootState) => state.selectedProject,
  ) as IProjectConfig;
  const [selectedPresetIdx, setSelectedPresetIdx] = useState(
    projectConfig?.tabularDefaultView,
  );
  const panelPreset = presets[selectedPresetIdx];

  // Control bar state
  const [isAssigningSource, setIsAssigningSource] = useState<boolean>(false);
  const [isEditToggledOn, setIsEditToggledOn] = useState<boolean>(false);

  const getParentHeight = () => {
    // If both panels are small, give parent auto height
    if (
      panelPreset.upper === EPanelSize.small &&
      panelPreset.lower === EPanelSize.small
    ) {
      return "initial";
    }

    // If either panel is expanded, parent should fill height
    if (
      panelPreset.upper === EPanelSize.large ||
      panelPreset.lower === EPanelSize.large
    ) {
      return "100%";
    }

    // In any other case, set reduced height so we can see map
    return "60%";
  };

  const handlePresetChange = (e) => {
    setSelectedPresetIdx(e.target.value);
  };

  return (
    <div
      className={classes.root}
      style={{
        height: getParentHeight(),
      }}
    >
      <div className={classes.actions}>
        <OctoSelect
          label={i18n.t("workspace")}
          selectProps={{
            value: selectedPresetIdx,
            onChange: handlePresetChange,
            // eslint-disable-next-line react/display-name
            renderValue: () => (
              <img
                src={panelPreset.icon}
                alt={i18n.t(`workspacePresetLabels.${panelPreset.id}`)}
                className={classes.selectImage}
              />
            ),
            classes: {
              select: classes.select,
            },
          }}
        >
          {presets.map((preset, i) => {
            const selected = i === selectedPresetIdx;
            const label = i18n.t(`workspacePresetLabels.${preset.id}`);
            return (
              <MenuItem
                key={preset.id}
                value={i}
                classes={{ root: classes.menuItem }}
              >
                <img
                  src={selected ? radioSelectedIcon : radioIcon}
                  alt={`${selected ? i18n.t("selected") : ""} radio`}
                />
                <img src={preset.icon} alt={label} />
                <Typography variant="body1" color="textPrimary">
                  {label}
                </Typography>
              </MenuItem>
            );
          })}
        </OctoSelect>

        <CloseButton onClick={() => setIsTabularViewOpen(false)} />
      </div>
      <SharedParameterProvider>
        <TabularPanel
          title={i18n.t("workspacePanels.upper")}
          size={panelPreset.upper}
        >
          <UpperWorkspacePanel
            setIsAssigningSource={setIsAssigningSource}
            setIsEditToggledOn={setIsEditToggledOn}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
            sources={sources}
            setSources={setSources}
            size={panelPreset.upper}
            prevSelectedObjects={prevSelectedObjects}
          />
        </TabularPanel>

        <TabularPanel
          title={i18n.t("workspacePanels.lower")}
          size={panelPreset.lower}
        >
          <TabularView
            setIsAssigningSource={setIsAssigningSource}
            isAssigningSource={isAssigningSource}
            isEditToggledOn={isEditToggledOn}
            setIsEditToggledOn={setIsEditToggledOn}
            setSelectAll={setSelectAll}
            sources={sources}
            size={panelPreset.lower}
          />
        </TabularPanel>
      </SharedParameterProvider>
    </div>
  );
};

export default withStyles(styles)(Tabular);
