import React, { useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  InputLabel,
} from "@material-ui/core";
import clsx from "clsx";

import i18n from "../../../i18n";

interface IProps {
  label?: string;
  list: any[];
  selectedItems: any;
  gridColumn?: string;
  placeHolder?: string;
  disabledOption?: string | false;
  hasDependency?: boolean;
  setSelectedItems: (items: any) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "33%",
      "& > label": {
        marginBottom: ".4rem",
      },
    },
    selectChoices: {
      display: "flex",
      alignItems: "center",
      textAlign: "left",
      textIndent: theme.spacing(1),
    },
    active: {
      backgroundColor: theme.palette.background.paper,
      textIndent: theme.spacing(1),
    },
    checkbox: {
      color: theme.palette.text.secondary,
      "&.MuiCheckbox-colorPrimary.Mui-checked": {
        color: theme.palette.primary.light,
      },
    },
  });

type ClassKey = "root" | "selectChoices" | "checkbox" | "active";
type PropsType = IProps & WithStyles<ClassKey>;

const SelectFilter: React.FC<PropsType> = (props) => {
  const {
    classes,
    list,
    selectedItems,
    label,
    gridColumn,
    placeHolder,
    disabledOption,
    setSelectedItems,
    hasDependency,
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const handleClick = (item) => {
    const index = selectedItems.findIndex((selectedItem) => {
      if (selectedItem?.id && item?.id) return selectedItem?.id === item?.id;
      return selectedItem?.objectlayerId === item?.objectlayerId;
    });

    if (index === -1) {
      setSelectedItems([...selectedItems, item]);
    } else {
      const items = [...selectedItems];
      items.splice(index, 1);
      setSelectedItems(items);
    }
  };

  return (
    <div
      className={classes.root}
      style={{
        gridColumn: gridColumn ? gridColumn : "",
        width: gridColumn ? "100%" : "",
        margin: gridColumn ? "0" : "",
      }}
    >
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Select
        multiple
        displayEmpty
        value={selectedItems}
        className={clsx(
          classes.selectChoices,
          (open || !!selectedItems.length) && classes.active,
        )}
        disableUnderline
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderValue={(selected) => {
          const customSelected = selected as any[];
          if (!customSelected.length) {
            if (!selectedItems.length && hasDependency) return i18n.t("all");
            return placeHolder || i18n.t("none");
          } else if (customSelected.length > 1) {
            return i18n.t("multiple");
          }
          return customSelected[0]?.name || customSelected[0]?.objectlayerName;
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          elevation: 2,
        }}
      >
        {disabledOption !== false && (
          <MenuItem value="" disabled>
            {disabledOption || placeHolder || i18n.t("none")}
          </MenuItem>
        )}
        {list.map((item) => {
          return (
            <MenuItem
              key={`${item?.id}-${item?.name || item?.objectlayerName}`}
              value={item?.name || item?.objectlayerName}
              onClick={() => handleClick(item)}
            >
              <ListItemText primary={item?.name || item?.objectlayerName} />
              <Checkbox
                checked={
                  selectedItems.findIndex((selectedItem) => {
                    if (selectedItem?.id && item?.id)
                      return selectedItem?.id === item?.id;
                    return selectedItem?.objectlayerId === item?.objectlayerId;
                  }) !== -1
                }
                className={classes.checkbox}
                color="primary"
              />
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default withStyles(styles)(SelectFilter);
