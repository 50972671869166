import React from "react";
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import ReactMarkdown from "react-markdown";
import { useGetMdFiles } from "../utils/getMdFiles";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      padding: theme.spacing(3.5),
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const TechnicalReferences: React.FC<PropsType> = (props) => {
  const { classes } = props;

  const path = "technical-references/technical-references";
  const { content, contentLoading } = useGetMdFiles(path);

  return (
    <>
      {contentLoading ? (
        <div>
          <Skeleton width={500} height={50} />
          <Skeleton width={500} height={150} />
        </div>
      ) : (
        <div className={classes.root} id="tech-ref">
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(TechnicalReferences);
