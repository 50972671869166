import React, { useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  SwipeableDrawer,
  Container,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";

import mdi from "markdown-it";
import markdownItAttrs from "markdown-it-attrs";

import MenuChips from "./views/MenuChips";

//.md Modules
import Introduction from "./views/Introduction";
import UserGuide from "./views/UserGuide";
import Faq from "./views/Faq";
import ReleaseNotes from "./views/ReleaseNotes";
import TechnicalReferences from "./views/TechnicalReferences";

import clsx from "clsx";
import i18n from "../../../i18n";

interface IProps {
  documentationOpen: boolean;
  toggleDocumentation: (any) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      wordBreak: "break-word",
      fontSize: 16,
      position: "relative",
      padding: 0,
      "& .anchor-link": {
        marginTop: -96, // Offset for the anchor.
        position: "absolute",
      },
      "& pre": {
        margin: theme.spacing(3, "auto"),
        padding: theme.spacing(2),
        backgroundColor: "#272c34",
        direction: "ltr",
        borderRadius: theme.shape.borderRadius,
        // overflow: "auto",
        WebkitOverflowScrolling: "touch", // iOS momentum scrolling.
        maxWidth: "calc(100vw - 32px)",
        [theme.breakpoints.up("md")]: {
          maxWidth: "calc(100vw - 32px - 16px)",
        },
      },
      // inline code
      "& code": {
        direction: "ltr",
        lineHeight: 1.4,
        display: "inline-block",
        fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
        WebkitFontSmoothing: "subpixel-antialiased",
        padding: "0 3px",
        color: theme.palette.text.primary,
        backgroundColor:
          theme.palette.type === "light"
            ? "rgba(255, 229, 100, 0.2)"
            : "rgba(255, 229, 100, 0.2)",
        fontSize: ".85em",
        borderRadius: 2,
      },
      '& code[class*="language-"]': {
        backgroundColor: "#272c34",
        color: "#fff",
        // Avoid layout jump after hydration (style injected by prism)
        lineHeight: 1.5,
      },
      // code blocks
      "& pre code": {
        fontSize: ".9em",
      },
      "& .token.operator": {
        background: "transparent",
      },
      "& h1": {
        ...theme.typography.h3,
        fontSize: 40,
        margin: "16px 0",
      },
      "& .description": {
        ...theme.typography.h5,
        margin: "0 0 40px",
      },
      "& h2": {
        ...theme.typography.h4,
        margin: "16px 0 16px",
      },
      "& h3": {
        ...theme.typography.h5,
        margin: "24px 0 16px",
      },
      "& h4": {
        ...theme.typography.h6,
        margin: "32px 0 16px",
      },
      "& h5": {
        // ...theme.typography.subtitle2,
        margin: "24px 0 16px",
      },
      "& p, & ul, & ol": {
        marginTop: 2,
        marginBottom: 2,
      },
      "& ul": {
        // paddingLeft: 30,
      },
      "& h1, & h2, & h3, & h4": {
        "& code": {
          fontSize: "inherit",
          lineHeight: "inherit",
          // Remove scroll on small screens.
          wordBreak: "break-all",
        },
        "& .anchor-link-style": {
          // To prevent the link to get the focus.
          display: "none",
        },
        "&:hover .anchor-link-style": {
          display: "inline-block",
          padding: "0 8px",
          color: theme.palette.text.secondary,
          "&:hover": {
            color: theme.palette.text.primary,
          },
          "& svg": {
            width: "0.7em",
            height: "0.7em",
            fill: "currentColor",
          },
        },
      },
      "& table": {
        // Trade display table for scroll overflow
        display: "block",
        wordBreak: "normal",
        width: "100%",
        overflowX: "auto",
        WebkitOverflowScrolling: "touch", // iOS momentum scrolling.
        borderCollapse: "collapse",
        marginBottom: "16px",
        borderSpacing: 0,
        overflow: "hidden",
        "& .prop-name": {
          fontFamily: 'Consolas, "Liberation Mono", Menlo, monospace',
        },
        "& .required": {
          color: theme.palette.type === "light" ? "#006500" : "#a5ffa5",
        },
        "& .prop-type": {
          fontFamily: 'Consolas, "Liberation Mono", Menlo, monospace',
          color: theme.palette.type === "light" ? "#932981" : "#ffb6ec",
        },
        "& .prop-default": {
          fontFamily: 'Consolas, "Liberation Mono", Menlo, monospace',
          borderBottom: `1px dotted ${theme.palette.divider}`,
        },
      },
      "& td": {
        ...theme.typography.body2,
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: 16,
        color: theme.palette.text.primary,
      },
      "& td code": {
        lineHeight: 1.6,
      },
      "& th": {
        lineHeight: theme.typography.pxToRem(24),
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.text.primary,
        whiteSpace: "pre",
        borderBottom: `1px solid ${theme.palette.divider}`,
        padding: 16,
      },
      "& blockquote": {
        borderLeft: "5px solid #ffe564",
        backgroundColor: "rgba(255,229,100,0.2)",
        padding: "4px 12px",
        margin: "4px 8px",
      },
      "& a, & a code": {
        // Style taken from the Link component
        color: theme.palette.primary.main,
        textDecoration: "none",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "& img, video": {
        maxWidth: "100%",
      },
      "& img": {
        // Avoid layout jump
        display: "inline-block",
      },
      "& hr": {
        height: 1,
        border: "none",
        flexShrink: 0,
        backgroundColor: theme.palette.divider,
      },
      "& kbd": {
        // Style taken from GitHub
        padding: "2px 5px",
        font: "11px Consolas,Liberation Mono,Menlo,monospace",
        lineHeight: "10px",
        color: "#444d56",
        verticalAlign: "middle",
        backgroundColor: "#fafbfc",
        border: "1px solid #d1d5da",
        borderRadius: 3,
        boxShadow: "inset 0 -1px 0 #d1d5da",
      },
    },
    container: {
      "& .MuiDrawer-paper": {
        width: "50%",
      },
    },
    divider: {
      marginTop: theme.spacing(),
    },
    menuChips: {
      marginBottom: theme.spacing(2),
    },
    form: {
      display: "flex",
      width: "50%",
      alignItems: "center",
    },
    searchBox: {
      width: "100%",
    },
    searchBtn: {
      marginLeft: theme.spacing(2),
      width: "25%",
    },
    searchContainer: {
      display: "flex",
      alignItems: "center",
    },
    header: {
      position: "fixed",
      background: "#f7f3ec",
      padding: theme.spacing(3),
      width: window.innerWidth / 2,
      zIndex: 2,
      borderBottom: "1px solid #1A4C57",
      right: 0,
      left: "50%",
    },
  });

type ClassKey =
  | "root"
  | "searchBox"
  | "container"
  | "divider"
  | "menuChips"
  | "searchContainer"
  | "header"
  | "form"
  | "searchBtn";

type PropsType = IProps & WithStyles<ClassKey>;

const Documentation: React.FC<PropsType> = (props) => {
  const { classes, documentationOpen, toggleDocumentation } = props;
  const globalAny: any = global;

  const md = mdi(); //This will enable to use extra syntax for md files to add class or id to elements
  md.use(markdownItAttrs, {
    // optional, these are default options
    leftDelimiter: "{",
    rightDelimiter: "}",
    allowedAttributes: [], // empty array = all attributes are allowed
  });
  const [inputValue, setInputValue] = useState("");
  const [selectedMenuIndex, setSelectedMenuIndex] = useState<number>(0);

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = () => {
    const isAvaliale = (window as any).find(inputValue);
    if (isAvaliale) {
      const s = window.getSelection();
      const oRange = s!.getRangeAt(0); //get the text range
      const oRect = oRange.getBoundingClientRect();
      globalAny.window.scroll(0, 1000);
      window.scrollTo({
        top: oRect.x,
        left: oRect.y,
        behavior: "smooth",
      });
    } else {
      globalAny.window.scrollTo(0, 0);
    }
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={documentationOpen}
      onClose={toggleDocumentation}
      onOpen={() => {}}
      className={classes.container}
    >
      <Container
        component="main"
        id="main-content"
        maxWidth={false}
        tabIndex={-1}
        className={clsx(classes.root)}
      >
        <div className={classes.header}>
          <div className={classes.menuChips}>
            <Typography variant="h2" color="primary">
              {i18n.t("documentation.title")}
            </Typography>
            <MenuChips
              setSelectedMenuIndex={setSelectedMenuIndex}
              selectedMenuIndex={selectedMenuIndex}
            />
          </div>
          <div className={classes.searchContainer}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
              }}
              className={classes.form}
            >
              <TextField
                id="search-box"
                label="Search"
                variant="outlined"
                value={inputValue}
                onChange={handleOnChange}
                className={classes.searchBox}
                size="small"
              />
              <Button
                variant="contained"
                onClick={handleSearch}
                className={classes.searchBtn}
              >
                {i18n.t("search")}
              </Button>
            </form>
          </div>
        </div>
        <Introduction />
        <UserGuide />
        <Faq />
        <ReleaseNotes />
        <TechnicalReferences />
      </Container>
    </SwipeableDrawer>
  );
};

export default withStyles(styles)(Documentation);
