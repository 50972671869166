import React, { useState } from "react";
import {
  createStyles,
  IconButton,
  Popover,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";

import OctoLogo from "../../../images/Octo_Logo_MainTheme.svg";
import OctoLogoText from "../../../images/Octo_LogoText_MainTheme.svg";
import ArupLogo from "../../../images/Arup_Red.svg";
import i18n from "../../../i18n";
import config from "./../../../config";
import { EEnvType } from "../../../config/interfaces";
import UserMenu from "./UserMenu/UserMenu";
import { RootState } from "../../../store";
import SelectProject from "./UserMenu/SelectProject";
import { useHistory } from "react-router";
import Documentation from "../../../modules/Core/Documentation/documentation";

import { useSelector } from "react-redux";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: "100%",
      height: theme.spacing(7),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: theme.palette.background.default,
    },
    logo: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(3),
      width: "fit-content",
      height: 30,
      cursor: "pointer",
    },
    iconButton: {
      marginRight: theme.spacing(1),
      color: theme.palette.background.paper,
      fontSize: theme.spacing(1.5),
    },
    iconButtonActive: {
      color: theme.palette.secondary.main,
    },
    developmentIndicator: {
      width: "fit-content ",
      height: 30,
      alignSelf: "center",
      border: "1px solid",
      borderColor: theme.palette.secondary.main,
      borderRadius: theme.spacing(0.5),
      color: theme.palette.secondary.main,
      fontSize: 11,
      letterSpacing: "2px",
      marginLeft: theme.spacing(2),
      marginRight: "auto",
      padding: `0 ${theme.spacing(1)}px`,
      textTransform: "uppercase",
      animation: `$blinker 3s 1 forwards`,
      display: "flex",
      alignItems: "center",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      height: 30,
    },
    text: {
      color: "#1C1C1C",
      marginRight: theme.spacing(),
    },
    starBlinking: {
      animation: `$blinker 1.5s 1 forwards`,
    },
    widget: {
      width: theme.spacing(3),
    },
    logoText: {
      width: theme.spacing(10),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    "@keyframes blinker": {
      "0%": { opacity: 1 },
      "50%": { opacity: 0 },
      "100%": { opacity: 1 },
    },
    arupLogo: {
      marginRight: theme.spacing(3),
      width: theme.spacing(7),
    },
    selectInput: {
      width: theme.spacing(15),
      textAlign: "left",
      color: "#1C1C1C",
      "& .MuiSelect-select": {
        paddingRight: 0,
      },
      marginLeft: theme.spacing(1),
    },
    projectSelectHeader: {
      padding: theme.spacing(1),
    },
    selectProjectContainer: {
      display: "flex",
      alignItems: "center",
      border: "1px solid #bebebe",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  });

type ClassKey =
  | "root"
  | "logo"
  | "iconButton"
  | "iconButtonActive"
  | "developmentIndicator"
  | "container"
  | "starBlinking"
  | "widget"
  | "logoText"
  | "text"
  | "arupLogo"
  | "selectInput"
  | "selectProjectContainer"
  | "projectSelectHeader"
  | "@keyframes blinker";
type PropsType = IProps & WithStyles<ClassKey>;

const AppBar: React.FC<PropsType> = (props) => {
  const { classes } = props;
  const isDevEnv =
    EEnvType[config.env] === "local" || EEnvType[config.env] === "dev";
  const [
    projectAnchor,
    setProjectAnchor,
  ] = React.useState<HTMLButtonElement | null>(null);

  const selectedProject = useSelector(
    (state: RootState) => state.selectedProject,
  );

  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();

  const [documentationOpen, setDocumentationOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, set) => {
    set(event.currentTarget);
  };

  const handleClose = (set) => set(null);

  const toggleDocumentation = (e) => {
    setDocumentationOpen((prev) => !prev);
  };

  return (
    <header className={classes.root}>
      <div
        className={classes.logo}
        onClick={() => {
          history.push("/");
        }}
      >
        <img src={OctoLogo} alt="Octo" className={classes.widget} />
        <img
          src={OctoLogoText}
          alt="Octo logo text"
          className={classes.logoText}
        />
      </div>
      {isDevEnv && (
        <div className={classes.developmentIndicator}>
          <p>{i18n.t("development")}</p>
        </div>
      )}
      <div className={classes.container}>
        {user && (
          <>
            <IconButton
              size="small"
              onClick={(e) => handleClick(e, setProjectAnchor)}
              className={classes.iconButton}
            >
              <FolderOpenIcon fontSize="large" style={{ color: "#1C1C1C" }} />
              <Typography variant="h2" className={classes.text}>
                {selectedProject?.name}
              </Typography>
            </IconButton>
            <Popover
              id="selected-projects"
              anchorEl={projectAnchor}
              open={Boolean(projectAnchor)}
              onClose={() => handleClose(setProjectAnchor)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ marginTop: 8 }}
            >
              <SelectProject
                handleClose={() => handleClose(setProjectAnchor)}
              />
            </Popover>
          </>
        )}
        <UserMenu />
        {isDevEnv && (
          <IconButton
            size="small"
            onClick={(e) => toggleDocumentation(e)}
            className={classes.iconButton}
          >
            <LiveHelpOutlinedIcon
              fontSize="large"
              style={{ color: "#1C1C1C" }}
            />
          </IconButton>
        )}
        <img src={ArupLogo} alt="Arup Logo" className={classes.arupLogo} />
      </div>
      <Documentation
        documentationOpen={documentationOpen}
        toggleDocumentation={toggleDocumentation}
      />
    </header>
  );
};

export default withStyles(styles)(AppBar);
