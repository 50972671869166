import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Dialog,
} from "@material-ui/core";

import { EModalTypes } from "../../../store/interfaces";
import { RootState } from "../../../store";
import { modalActions } from "../../../store/modal";
import EditAndUploadLibraryResources from "../../../modules/Core/Tabular/Library/UploadLibraryResources/EditAndUploadLibraryResources";
import DeleteResource from "../../../modules/Core/Tabular/Library/DeleteResource";
import ParameterValueHistory from "../../../modules/Core/Tabular/Library/ParameterValueHistory";
import SelectSingleObjectSource from "../../../modules/Core/Tabular/TabularView/SelectSingleObjectSource";
import LibraryPreview from "../../../modules/Core/Tabular/Library/LibraryPreview";

const modalComponents = {
  [EModalTypes.editAndUploadLibraryResources]: EditAndUploadLibraryResources,
  [EModalTypes.selectSingleSourceObject]: SelectSingleObjectSource,
  [EModalTypes.libraryPreview]: LibraryPreview,
  [EModalTypes.deleteResource]: DeleteResource,
  [EModalTypes.parameterValueHistory]: ParameterValueHistory,
};

interface IProps {}

export interface IModalContentProps {
  handleClose: () => void;
  // modalProps from redux store
  [key: string]: any;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    paper: {
      width: "100%",
      maxWidth: 750,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 20,
    },
  });

type ClassKey = "paper";
type PropsType = IProps & WithStyles<ClassKey>;

const ModalManager: React.FC<PropsType> = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { modalType, modalProps } = useSelector(
    (state: RootState) => state.modal,
  );

  const handleClose = () => dispatch(modalActions.closeModal());

  const open = modalType !== null;
  const ModalContentComponent =
    modalType !== null && modalComponents[modalType];

  // Custom modals manage their own parent to give greater flexibility
  if (modalProps.customModal) {
    return ModalContentComponent ? (
      <ModalContentComponent
        open={open}
        handleClose={handleClose}
        {...modalProps}
      />
    ) : null;
  }

  // Non-custom modals render in consistent Dialog style component
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.paper,
      }}
    >
      {ModalContentComponent && (
        <ModalContentComponent handleClose={handleClose} {...modalProps} />
      )}
    </Dialog>
  );
};

export default withStyles(styles)(ModalManager);
