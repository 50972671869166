import React, { useState, useEffect } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
  Divider,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import Panel from "../Panel";
import i18n from "../../../../i18n";
import { logSentry } from "../../../../utils/logger";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      padding: theme.spacing(3.5),
    },
  });

type ClassKey = "root";
type PropsType = IProps & WithStyles<ClassKey>;

const Faq: React.FC<PropsType> = (props) => {
  const { classes } = props;
  const [faqLoading, setFaqLoading] = useState(true);
  const [faq, setFaq] = useState([{ heading: "", content: "" }]);

  // Safari bug: help get correct markdown title
  const regex = /(?<=[a-z]+\/[a-z]+\/)[^.]+/gi;

  const faqFilesEN = require["context"](
    "../md-files/faq/en-US",
    false,
    /\.md$/,
  );
  const faqFilesPL = require["context"](
    "../md-files/faq/nl-NL",
    false,
    /\.md$/,
  );

  useEffect(() => {
    setFaqLoading(true);

    const getFaq = async () => {
      let data;
      let markdownFiles;
      try {
        const importAll = (r) => r.keys().map(r);
        if (i18n.language === "en-US") {
          markdownFiles = importAll(faqFilesEN);
        } else {
          markdownFiles = importAll(faqFilesPL);
        }
        data = await Promise.all(
          markdownFiles.map(async (file) => {
            const heading = file.default.match(regex)[0];
            return {
              content: await fetch(file.default).then((res) => res.text()),
              heading,
            };
          }),
        );
      } catch (error) {
        logSentry("warning", "Problem with fetching md files", error);
      }

      if (data) {
        setFaq(data);
      }
    };

    getFaq();
    setFaqLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {faqLoading ? (
        <div>
          <Skeleton width={500} height={50} />
          <Skeleton width={500} height={150} />
        </div>
      ) : (
        <>
          <div className={classes.root} id="faq">
            <Typography variant="h2" color="primary">
              {i18n.t("documentation.headers.faq")}
            </Typography>
            <Panel data={faq} />
          </div>
          <Divider />
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Faq);
