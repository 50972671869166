import { gql } from "@apollo/client";

export const GET_BBOX_ROOT_OBJECTS = gql`
  query GET_BBOX_ROOT_OBJECTS(
    $xmin: Float!
    $ymin: Float!
    $xmax: Float!
    $ymax: Float!
    $layerIds: [Int!]
  ) {
    objectsFromBbox(
      xmin: $xmin
      ymin: $ymin
      xmax: $xmax
      ymax: $ymax
      layerIds: $layerIds
    ) {
      objectGeom
      objectLongId
      objectLayer {
        objectlayerId
      }
    }
  }
`;

export const GET_POINT_ROOT_OBJECTS = gql`
  query GET_POINT_ROOT_OBJECTS($long: Float!, $lat: Float!) {
    objectsFromPoint(long: $long, lat: $lat) {
      objectGeom
      objectLongId
      objectLayer {
        objectlayerName
      }
    }
  }
`;
