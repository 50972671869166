import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Divider,
  Typography,
  Tooltip,
} from "@material-ui/core";
import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import clsx from "clsx";
import { useSelector } from "react-redux";

import i18n from "../../../../i18n";
import { ISource } from "../../../../data/types/typeStitches";

interface IProps {
  source: ISource;
  selectedSources: ISource[];
  insertSelectedSource: (selectedSource) => void;
  removeSelectedSource: (selectedSource) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      width: 105,
      height: 105,
      padding: theme.spacing(),
      overflow: "hidden",
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
      cursor: "pointer",
    },
    inactiveBorder: {
      boxShadow: "0px 0px 5px 2px #ccc",
    },
    activeBorder: {
      boxShadow: `0px 0px 5px 2px ${theme.palette.primary.main}`,
    },
    horizontalContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "100%",
    },
    textContainer: {
      width: "85%",
      overflow: "hidden",
    },
    iconContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: "15%",
      alignContent: "space-between",
    },
    label: {
      height: "30%",
      color: theme.palette.primary.main,
      wordBreak: "break-word",
      overflow: "hidden",
      fontSize: 10,
    },
    divider: {
      margin: theme.spacing(),
    },
    sourceCard: {
      fontSize: 10,
    },
  });

type ClassKey =
  | "root"
  | "activeBorder"
  | "inactiveBorder"
  | "horizontalContainer"
  | "textContainer"
  | "iconContainer"
  | "label"
  | "divider"
  | "sourceCard";
type PropsType = IProps & WithStyles<ClassKey>;

const SourceCard: React.FC<PropsType> = (props) => {
  const {
    classes,
    source,
    selectedSources,
    insertSelectedSource,
    removeSelectedSource,
  } = props;

  const user = useSelector((state: any) => state.user);

  const sliceLabel = (label: string) => {
    const maxLength = 26;
    if (label.length <= maxLength) return label;
    return `${label.slice(0, maxLength - 3)}...`;
  };

  const isActive =
    selectedSources.findIndex((s) => s.sourcesId === source.sourcesId) !== -1;

  const handleClick = () => {
    if (!isActive) {
      insertSelectedSource(source);
    } else {
      removeSelectedSource(source);
    }
  };

  return (
    <div
      className={clsx(
        classes.root,
        isActive ? classes.activeBorder : classes.inactiveBorder,
      )}
      onClick={handleClick}
    >
      <Tooltip
        title={
          <Typography variant="body1" className={classes.sourceCard}>
            {source.sourcesName}
          </Typography>
        }
      >
        <div className={classes.horizontalContainer}>
          <div className={classes.textContainer}>
            <header className={classes.label}>
              <Typography variant="body1" className={classes.sourceCard}>
                {sliceLabel(source.sourcesName)}
              </Typography>
            </header>
            <Divider className={classes.divider} />
            <div>
              <Typography variant="body1" className={classes.sourceCard}>
                {i18n.t("attributes")}: {source.parameterValues_Count}
              </Typography>
              <Typography variant="body1" className={classes.sourceCard}>
                {i18n.t("score")}: {source.sourcesBestdatascore}
              </Typography>
              <Typography variant="body1" className={classes.sourceCard}>
                {source.sourcesDatecreated.slice(0, 10)}
              </Typography>
            </div>
          </div>
          <div className={classes.iconContainer}>
            <PanoramaFishEyeIcon color="primary" />
            {source.sourcesIsDynamic &&
              (user?.isAdmin || user?.hasWriteAccess) && (
                <EditIcon color="primary" />
              )}
            <VisibilityIcon color="primary" />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(SourceCard);
