import { Action, Reducer } from "redux";
import { IProjectConfig, KnownAction } from "./actions";

export const selectedProjectReducer: Reducer<IProjectConfig | null> = (
  state = null,
  incomingAction: Action,
) => {
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "UPDATE_SELECTED_PROJECT":
      return {
        ...action.selectedProject,
      };
    default:
      return state;
  }
};
