import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  IconButton,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SaveIcon from "@material-ui/icons/Save";

import { getFileIcon } from "../../modules/Core/Tabular/Library/utils";
import i18n from "../../i18n";
import { logSentry } from "../../utils/logger";
// See exmaple for importing PDF Worker in react correctly:
// https://github.com/mozilla/pdf.js/blob/b86df97725cef5ec29feea3d61b8a7112c21ebcb/examples/create-react-app/src/pdf.tsx
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

interface IProps {
  fileUrl: String;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      maxHeight: "100%",
      maxWidth: "80%",
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
    },
    document: {
      overflow: "auto",
    },
    pageCtrl: {
      position: "absolute",
      bottom: "5%",
      left: "50%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      background: "white",
      transform: "translateX(-50%)",
      transition: "opacity ease-in-out 0.2s",
      boxShadow: "0 30px 40px 0 rgba(16,36,94, 0.2)",
      "& p": {
        color: "black",
      },
    },
    fallback: {
      maxHeight: "100%",
      maxWidth: "80%",
      padding: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    spinner: {
      position: "relative",
      height: "90px",
      width: "90px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& CircularSpinner": {
        MuiCircularProgressColorPrimary: theme.palette.text.secondary,
      },
    },
    inlineDownload: {
      display: "flex",
      alignItems: "center",
    },
  });

type ClassKey =
  | "root"
  | "document"
  | "pageCtrl"
  | "fallback"
  | "spinner"
  | "inlineDownload";
type PropsType = IProps & WithStyles<ClassKey>;

const PdfPreview: React.FC<PropsType> = (props: PropsType) => {
  const { classes, fileUrl } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>(1);
  const [docError, setDocError] = useState<boolean>(false);

  // Set the PDF worker (see https://github.com/wojtekmaj/react-pdf#standard-browserify-and-others)
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  // Reset the document error state when the PDF changes
  useEffect(() => {
    setNumPages(0);
    setPageNum(1);
    setDocError(false);
  }, [fileUrl]);

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNum(1);
  };

  const handleLoadError = (error) => {
    logSentry(
      "error",
      i18n.t("previewPdfLoadError", { language: "en" }),
      error,
    );
    enqueueSnackbar(i18n.t("previewPdfLoadError"), { variant: "error" });
    setDocError(true);
  };

  const handleRenderError = (error) => {
    logSentry(
      "error",
      i18n.t("previewPdfRenderError", {
        pageNum: `${pageNum}`,
        language: "en",
      }),
      error,
    );
    enqueueSnackbar(
      i18n.t("previewPdfRenderError", { pageNum: `${pageNum}` }),
      { variant: "error" },
    );
    setDocError(true);
  };

  const changePage = (offset: number) => {
    setPageNum((pageNum) => pageNum + offset);
  };

  const nextPage = () => {
    changePage(1);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const loading = (
    <div className={classes.spinner}>
      <CircularProgress size={64} />
    </div>
  );

  const Icon = getFileIcon("pdf");
  const errored = (
    <div className={classes.fallback}>
      <a href={fileUrl?.toString()} target="_blank" rel="noopener noreferrer">
        <Icon height="80px" width="80px" />
      </a>

      <Typography variant="caption" color="inherit">
        {i18n.t("previewPdfNotSupported")}
      </Typography>

      <Typography variant="caption" color="inherit">
        {i18n.t("previewMustDownload")}
      </Typography>
    </div>
  );

  if (docError) {
    return errored;
  }

  return (
    <div className={classes.root}>
      <Document
        file={fileUrl}
        options={{}}
        onLoadSuccess={handleDocumentLoadSuccess}
        className={classes.document}
        loading={loading}
        onLoadError={handleLoadError}
        error={errored}
      >
        <Page
          pageNumber={pageNum}
          onRenderError={handleRenderError}
          error={errored}
        />
        <div className={classes.pageCtrl}>
          <IconButton
            aria-label={i18n.t("previous")}
            size="small"
            onClick={previousPage}
            disabled={pageNum <= 1}
          >
            <ChevronLeftIcon />
          </IconButton>
          <p>
            {pageNum || (numPages ? 1 : "--")} / {numPages || "--"}
          </p>
          <IconButton
            aria-label={i18n.t("next")}
            size="small"
            onClick={nextPage}
            disabled={pageNum >= numPages}
          >
            <ChevronRightIcon />
          </IconButton>
          <IconButton aria-label={i18n.t("download")} size="small">
            <a
              href={fileUrl?.toString()}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.inlineDownload}
            >
              <SaveIcon color="action" />
            </a>
          </IconButton>
        </div>
      </Document>
    </div>
  );
};

export default withStyles(styles)(PdfPreview);
