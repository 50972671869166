import { IEnvConfig } from ".";
import { EEnvType } from "./interfaces";

export default {
  activeDirectory: {
    appId: "b2b5e098-163a-418a-8cb6-10b8b1f64424",
    redirectUri: "https://test.octo.arup.com/",
  },
  sentry: {
    enabled: false,
    dsn:
      "https://f4a08ca57ebc497ebf588e17e7260b26@o141124.ingest.sentry.io/5530532",
  },
  env: EEnvType["test"],
} as IEnvConfig;
