import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
  Checkbox,
} from "@material-ui/core";

import i18n from "../../../i18n";

export type SelectionType = "none" | "all" | "indeterminate";

interface IProps {
  selectAllItems: () => void;
  deselectAllItems: () => void;
  selectedItems: any[];
  selectableItems: any[];
  noBoldTitle?: boolean;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      justifyContent: "space-between",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    noBoldTitle: {
      fontWeight: 500,
    },
  });

type ClassKey = "root" | "flex" | "noBoldTitle";
type PropsType = IProps & WithStyles<ClassKey>;

const ToggleAll: React.FC<PropsType> = (props) => {
  const {
    classes,
    selectAllItems,
    deselectAllItems,
    selectedItems,
    selectableItems,
  } = props;

  const toggleSelectAll = () => {
    if (selectionState === "none") {
      selectAllItems();
    } else {
      deselectAllItems();
    }
  };

  const getSelectionState = (): SelectionType => {
    if (selectedItems?.length === selectableItems.length) {
      return "all";
    } else if (selectedItems?.length === 0) {
      return "none";
    }
    return "indeterminate";
  };

  const selectionState = getSelectionState();

  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        {props.noBoldTitle ? (
          <Typography variant="body1" color="textPrimary">
            {i18n.t("selectAll")}
          </Typography>
        ) : (
          <Typography variant="caption" color="textPrimary">
            {i18n.t("selectAll")}
          </Typography>
        )}
        <Checkbox
          color={"primary"}
          onClick={() => {
            toggleSelectAll();
          }}
          indeterminate={selectionState === "indeterminate"}
          checked={
            selectionState === "indeterminate" || selectionState === "all"
          }
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(ToggleAll);
