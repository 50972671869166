import { gql } from "@apollo/client";

export const GET_SOURCES = gql`
  query GET_SOURCES($objectIds: [ID]) {
    allSources(
      relatedObjectId_In: $objectIds
      sourcesStatus_SourcestatusName_Iexact: "approved"
    ) {
      edges {
        node {
          sourcesId
          sourcesName
          sourcesIsDynamic
          sourcesDatecreated
          sourcesDateobtained
          sourcesBestdatascore
          parameterValues_Count(parametervaluesObject_ObjectId_In: $objectIds)
        }
      }
    }
  }
`;

export const GET_DYNAMIC_SOURCES = gql`
  query GET_DYNAMIC_SOURCES($objectIds: [ID]) {
    allSources(
      relatedObjectId_In: $objectIds
      sourcesStatus_SourcestatusName_Iexact: "approved"
      sourcesIsDynamic: true
    ) {
      edges {
        node {
          sourcesId
          sourcesName
          sourcesIsDynamic
          sourcesDescription
        }
      }
    }
  }
`;

export const GET_ASSIGNABLE_PARAMETERS_FOR_SOURCES = gql`
  query GET_ASSIGNABLE_PARAMETERS_FOR_SOURCES(
    $objectIds: [ID]
    $sourcesName: String!
  ) {
    allSources(relatedObjectId_In: $objectIds, sourcesName: $sourcesName) {
      edges {
        node {
          sourcesId
          sourcesName
          sourcesParametermappings {
            edges {
              node {
                parametermappingsId
                parametermappingsSortorder
                parametermappingsParameter {
                  parametersId
                  parametersNote
                  parametersElementcode
                  parametersDatecreated
                  parametersDisplayList
                  parametersDateobtained
                  parametersParameterdef {
                    parameterdefsType
                    parameterdefsName
                    parameterdefsUnit
                    parameterdefsDefinitionNl
                    parameterdefsDefinitionUk
                    parameterdefsParameterenumerationset {
                      parameterenumerationsetsId
                      parameterEnumerations {
                        edges {
                          node {
                            parameterenumerationsNameNl
                            parameterenumerationsNameUk
                            parameterenumerationsAllowed
                          }
                        }
                      }
                    }
                  }
                }
                parametermappingsExternalName
                parametermappingsExternalUnit
                parametermappingsExternalQualifier
                parametermappingsInternalQualifier
                parametermappingsConstraintHardmin
                parametermappingsConstraintSoftmin
                parametermappingsConstraintSoftmax
                parametermappingsConstraintHardmax
                parametermappingsUnitconversionShift
                parametermappingsUnitconversionMultiplier
              }
            }
          }
        }
      }
    }
  }
`;
