import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";

import LibraryCardTooltip from "./LibraryCardTooltip";
import LibraryResourceThumbnail from "./LibraryResourceThumbnail";

interface IProps {
  onClick: (id: any) => void;
  showPreview: (e: React.MouseEvent<HTMLElement>) => void;
  isSelected: boolean;
  libraryResource: any; // TODO: make type when we have more info
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      position: "relative",
      width: 80,
      height: 80,
      cursor: "pointer",
      "& > * + *": {
        marginTop: theme.spacing(),
      },
    },
    cardHeader: {},
    ellipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    tooltip: {
      backgroundColor: theme.palette.background.paper,
    },
    thumbnailContainer: {
      position: "relative",
      width: "100%",
      "&::before": {
        content: "''",
        display: "block",
        paddingTop: "100%",
      },
    },
    thumbnailContent: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    cardFooter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    preview: {
      textDecoration: "underline",
    },
    floatingButtons: {
      position: "absolute",
      height: "100%",
      top: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: 0,
      padding: 1,
    },
    whiteIcon: {
      color: theme.palette.background.default,
    },
  });

type ClassKey =
  | "root"
  | "cardHeader"
  | "ellipsis"
  | "tooltip"
  | "thumbnailContainer"
  | "thumbnailContent"
  | "cardFooter"
  | "preview"
  | "floatingButtons"
  | "whiteIcon";
type PropsType = IProps & WithStyles<ClassKey>;

const LibraryCard: React.FC<PropsType> = (props) => {
  const { classes, onClick, libraryResource, isSelected, showPreview } = props;

  const imageExtensions = ["jpg", "jpeg", "png", "svg", "gif"];

  const isImage = () => imageExtensions.includes(libraryResource.extension);

  return (
    <div className={classes.root} onClick={showPreview}>
      <div className={classes.thumbnailContainer}>
        <div className={classes.thumbnailContent}>
          <LibraryResourceThumbnail
            isSelected={isSelected}
            libraryResource={libraryResource}
          />
        </div>
      </div>

      <div className={classes.cardFooter}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.ellipsis}
        >
          {libraryResource.resourcesName}
        </Typography>
      </div>
      <div className={classes.floatingButtons}>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={<LibraryCardTooltip libraryResource={libraryResource} />}
        >
          <IconButton size={"small"}>
            <InfoOutlinedIcon
              className={clsx(isImage() && classes.whiteIcon)}
            />
          </IconButton>
        </Tooltip>
        <Checkbox
          checked={isSelected}
          onClick={onClick}
          color="primary"
          className={clsx(isImage() && classes.whiteIcon)}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(LibraryCard);
