import React from "react";
import FlagIcon from "@material-ui/icons/Flag";

import i18n from "../../../../../i18n";

interface IColumn {
  label: any;
  dataKey: string | string[];
  altDataKey?: string | string[];
  width: number;
  isSortable?: boolean;
}

export const columns: IColumn[] = [
  {
    label: i18n.t("object"),
    dataKey: "parametervaluesObject.objectLongId",
    width: 0.16,
    isSortable: true,
  },
  {
    label: i18n.t("property"),
    dataKey: [
      "parametervaluesParameter.parametersParameterdef.parameterdefsName",
      "parametervaluesParameter.parametersElementcode",
    ],
    width: 0.32,
    isSortable: true,
  },
  {
    label: i18n.t("value"),
    dataKey: "parametervaluesValue",
    width: 0.16,
    isSortable: true,
  },
  {
    label: i18n.t("unit"),
    dataKey:
      "parametervaluesParameter.parametersParameterdef.parameterdefsUnit",
    width: 0.1,
  },
  {
    label: i18n.t("source"),
    dataKey: "parametervaluesSource.sourcesName",
    width: 0.16,
    isSortable: true,
  },
  {
    label: <FlagIcon color="primary" />,
    dataKey: "parametervaluesFlag",
    width: 0.05,
  },
  {
    label: i18n.t("qualifier"),
    dataKey: "parametervaluesQualifier",
    width: 0.05,
  },
];
