import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import Check from "@material-ui/icons/Check";

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      margin: "1px",
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: theme.spacing(2),
      margin: theme.spacing(1),
    },
    tickIcon: {
      color: "#4EC0EB",
      paddingRight: "10px",
      fontSize: theme.spacing(4),
    },
  });

type ClassKey = "root" | "text" | "tickIcon";
interface textIProps {
  content: string;
}
type TextPropsType = textIProps & WithStyles<ClassKey>;
const TextItem: React.FC<TextPropsType> = (props) => {
  const { classes, content } = props;
  return (
    <div className={classes.root}>
      <div className={classes.tickIcon}>
        <Check />
      </div>
      <Typography variant="caption" className={classes.text}>
        {content}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(TextItem);
