import { AppThunk, ITree } from "../interfaces";

export const treeObjectsActions = {
  resetTreeObjects: (): AppThunk => (dispatch) => {
    dispatch({ type: "RESET_TREE_OBJECTS" });
  },
  insertTreeObjects: (treeObjects: ITree): AppThunk => (dispatch) => {
    dispatch({ type: "INSERT_TREE_OBJECTS", payload: treeObjects });
  },
};
