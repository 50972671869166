import {
  Button,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles,
  CircularProgress,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import i18n from "../../../../i18n";
import { useQuery } from "@apollo/client";
import { RootState } from "../../../../store";
import { GET_RELATIVE_RESOURCE_TYPES } from "../../../../data/libraryQueries";
import SelectFilter from "../../../../modules/Core/Filter/SelectFilter";

interface IProps {
  selectedCategories: any[];
  setSelectedCategories: (list: any[]) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "15%",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    button: {
      marginLeft: theme.spacing(1),
      width: "55%",
      height: theme.spacing(2),
      "& .MuiInput-root": {
        width: "100%",
        border: "none",
        display: "contents",
        fontSize: theme.spacing(1.25),
        "&:hover": {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    progress: {
      marginLeft: theme.spacing(1),
    },
  });

type ClassKey = "root" | "flex" | "button" | "progress";
type PropsType = IProps & WithStyles<ClassKey>;

const FilterBy: React.FC<PropsType> = (props) => {
  const { classes, selectedCategories, setSelectedCategories } = props;

  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );

  const selectedSources = useSelector(
    (state: RootState) => state.selectedSources,
  );

  const { loading, data: resourceTypesData } = useQuery(
    GET_RELATIVE_RESOURCE_TYPES,
    {
      variables: {
        sources: selectedSources.map((s) => s.sourcesId),
        objects: selectedObjects.map((o) => o.objectId),
      },
    },
  );

  const extractedResourceTypes = resourceTypesData
    ? resourceTypesData.allResourceTypes.edges.map((rt) => {
        return { id: rt.node.resourcetypesId, name: rt.node.resourcetypesName };
      })
    : [];
  extractedResourceTypes.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className={clsx(classes.root)}>
      <Typography variant="caption" color="textPrimary">
        {i18n.t("filterBy")} :
      </Typography>
      <Button className={classes.button} variant="contained">
        {loading ? (
          <>
            <Typography>{i18n.t("libraryCardTooltip.category")} </Typography>
            <CircularProgress className={classes.progress} size={12} />
          </>
        ) : (
          <SelectFilter
            list={extractedResourceTypes}
            selectedItems={selectedCategories}
            setSelectedItems={setSelectedCategories}
            gridColumn={"1"}
            placeHolder={i18n.t("libraryCardTooltip.category")}
          />
        )}
      </Button>
    </div>
  );
};

export default withStyles(styles)(FilterBy);
