import { gql } from "@apollo/client";

export const GET_SCOPES_AND_PROJECTS = gql`
  query GET_SCOPES_AND_PROJECTS {
    allScopes(scopeOwner: "{Public}", scopeProject_ProjectOwner: "{Public}") {
      edges {
        node {
          scopeId
          scopeName
          scopeProject {
            projectId
            projectName
          }
        }
      }
    }
  }
`;

export const GET_SCOPE_PROJECT_GEOMS = gql`
  query GET_SCOPE_PROJECT_GEOMS($scopeIds: [ID], $projectIds: [ID]) {
    allScopes(
      scopesId_In: $scopeIds
      scopesProject_In: $projectIds
      scopeOwner: "{Public}"
      scopeProject_ProjectOwner: "{Public}"
    ) {
      edges {
        node {
          scopeId
          scopeName
          scopeProject {
            projectId
            projectName
          }
          scopeObjects {
            edges {
              node {
                objectId
                objectLongId
                objectName
                objectLayer {
                  objectlayerName
                }
                objectGeom
              }
            }
          }
        }
      }
    }
  }
`;
