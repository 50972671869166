import { combineReducers } from "redux";

import { userReducer } from "./user/reducer";
import { selectedObjectsReducer } from "./selectedObjects/reducer";
import { filteredObjectsReducer } from "./filteredObjects/reducer";
import { selectedSourcesReducer } from "./selectedSources/reducer";
import { rootObjectsReducer } from "./rootObjects/reducer";
import { treeObjectsReducer } from "./treeObjects/reducer";
import { modalReducer } from "./modal/reducer";
import { layerGroupsReducer } from "./layerGroups/reducer";
import { visibleLayerIdsReducer } from "./visibleLayerIds/reducer";
import { selectedProjectReducer } from "./selectedProject/reducer";

export const rootReducer = combineReducers({
  user: userReducer,
  layerGroups: layerGroupsReducer,
  visibleLayerIds: visibleLayerIdsReducer,
  rootObjects: rootObjectsReducer,
  treeObjects: treeObjectsReducer,
  selectedObjects: selectedObjectsReducer,
  filteredObjects: filteredObjectsReducer,
  selectedSources: selectedSourcesReducer,
  selectedProject: selectedProjectReducer,
  modal: modalReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
