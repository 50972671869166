import React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Divider,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import ReactMarkdown from "react-markdown";
import { useGetMdFiles } from "../utils/getMdFiles";

interface IProps {}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "185px",
      padding: theme.spacing(3.5),
    },
    content: {
      width: "48%",
      textAlign: "justify",
      textIndent: theme.spacing(3),
    },
    image: {
      width: "45%",
      marginTop: theme.spacing(2),
    },
  });

type ClassKey = "root" | "content" | "image";
type PropsType = IProps & WithStyles<ClassKey>;

const Introduction: React.FC<PropsType> = (props) => {
  const { classes } = props;

  const path = "introduction/introduction";

  const { content, contentLoading } = useGetMdFiles(path);

  return (
    <>
      {contentLoading ? (
        <div>
          <Skeleton width={500} height={50} />
          <Skeleton width={500} height={150} />
        </div>
      ) : (
        <>
          <div className={classes.root} id="introduction">
            <div className={classes.content}>
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
            <img
              src="https://placeholder.pics/svg/300"
              alt="Octo "
              className={classes.image}
            />
          </div>
          <Divider />
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Introduction);
