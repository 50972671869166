import React, { useState } from "react";
import {
  createStyles,
  MenuItem,
  Select,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";

import QueryPanel from "./Panels/QueryPanel";
import { IMapViewport } from "../Core";
import LocationPanel from "./Panels/LocationPanel";
import i18n from "../../../i18n";

interface IProps {
  updateViewport: (updatedViewport: IMapViewport) => void;
}

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {
      color: theme.palette.primary.main,
      flex: 1,
      borderLeft: "1px solid #bebebe",
    },
    container: {
      display: "flex",
      border: "1px solid #bebebe",
      marginTop: theme.spacing(1.5),
      height: theme.spacing(4),
    },
    selectInput: {
      width: "25%",
      textAlign: "center",
      color: "#1C1C1C",
      "& .MuiSelect-select": {
        paddingRight: 0,
      },
    },
    menuItem: {
      justifyContent: "center",
    },
  });

type ClassKey = "root" | "container" | "selectInput" | "menuItem";

type PropsType = IProps & WithStyles<ClassKey>;

const Search: React.FC<PropsType> = (props) => {
  const { classes, updateViewport } = props;
  const [searchType, setSeachType] = useState<"object" | "location">("object");

  return (
    <div className={classes.container}>
      <Select
        className={classes.selectInput}
        disableUnderline
        value={searchType}
        onChange={(event: any) => setSeachType(event.target.value)}
      >
        <MenuItem value={"object"} className={classes.menuItem}>
          {i18n.t("object")}
        </MenuItem>
        <MenuItem value={"location"} className={classes.menuItem}>
          {i18n.t("location")}
        </MenuItem>
      </Select>
      <form
        className={classes.root}
        onSubmit={(event) => event.preventDefault()}
      >
        {searchType === "object" ? (
          <QueryPanel />
        ) : (
          <LocationPanel updateViewport={updateViewport} />
        )}
      </form>
    </div>
  );
};

export default withStyles(styles)(Search);
