import React from "react";
import { LatLong } from "./CreateEntry";
import { Marker } from "react-map-gl";
import { ReactComponent as GeoMarker } from "../../../images/geo-alt-fill.svg";

interface IProps {
  markerCoords: LatLong;
}

const ContextMarker: React.FC<IProps> = (props) => {
  const { markerCoords } = props;

  return (
    <>
      {markerCoords && (
        <Marker
          latitude={markerCoords.lat}
          longitude={markerCoords.long}
          offsetLeft={-2}
          offsetTop={-4}
        >
          <svg>
            <GeoMarker />
          </svg>
        </Marker>
      )}
    </>
  );
};

export default ContextMarker;
