import React, { useState } from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Button,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { IModalContentProps } from "../../../../../common/components/Modal/ModalManager";
import ModalContent from "../../../../../common/components/Modal/ModalContent";
import FileDrop from "./FileDrop";

import ModalActions from "../../../../../common/components/Modal/ModalActions";
import {
  GET_RESOURCE_TYPES,
  GET_RESOURCE_STATUSES,
  getUpdateLibaryResourceMutation,
  getCreateLibaryResourceMutation,
} from "../../../../../data/libraryQueries";
import { GET_DYNAMIC_SOURCES } from "../../../../../data/sourceQueries";
import { RootState } from "../../../../../store";
import i18n from "../../../../../i18n";
import ModalHeader from "../../../../../common/components/Modal/ModalHeader";
import { IProjectConfig } from "../../../../../store/selectedProject/actions";

interface IProps {
  refetchLibraryResources: () => void;
  libraryResources?: any[];
  selectedLibraryResources?: any;
}

interface IUploadFormState {
  object: number;
  dataset: number;
  category: any;
  description: string;
  status: string;
}

const noValidFileSignatures = [
  "4D5A", // .exe, .dll
];

const styles = (theme: Theme) =>
  createStyles<ClassKey, {}>({
    root: {},

    formControl: {
      display: "flex",
      backgroundColor: theme.palette.primary.light,
      borderTopLeftRadius: 2,
      borderBottomLeftRadius: 2,
      alignItems: "center",
      "& > label": {
        margin: `0 ${theme.spacing(2)}px`,
      },
      "&:last-child": {
        flex: 2,
      },
    },
    select: {
      flex: 1,
      backgroundColor: theme.palette.primary.contrastText,
      "& > .MuiSelect-root": {
        paddingLeft: 6,
      },
    },
    textInput: {
      flex: 1,
      backgroundColor: theme.palette.primary.contrastText,
      "& .MuiInputBase-input": {
        paddingLeft: 6,
        paddingRight: 6,
      },
    },
    spinner: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: `${theme.spacing(5)}px 0`,
    },
    selectItem: {
      width: "100%",
    },
  });

type ClassKey =
  | "root"
  | "formControl"
  | "select"
  | "textInput"
  | "spinner"
  | "selectItem";
type PropsType = IProps & IModalContentProps & WithStyles<ClassKey>;

const EditAndUploadLibraryResources: React.FC<PropsType> = (props) => {
  const {
    classes,
    handleClose,
    refetchLibraryResources,
    libraryResources,
    selectedLibraryResources,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const selectedObjects = useSelector(
    (state: RootState) => state.selectedObjects,
  );
  const user = useSelector((state: any) => state.user);
  const layers = useSelector(
    (state: RootState) => state.layerGroups[0]?.objectlayers || [],
  );
  const projectConfig = useSelector(
    (state: RootState) => state.selectedProject,
  ) as IProjectConfig;

  const { data: sourcesData } = useQuery(GET_DYNAMIC_SOURCES);
  const { data: resourceTypesData } = useQuery(GET_RESOURCE_TYPES);
  const { data: resourceStatusData } = useQuery(GET_RESOURCE_STATUSES);

  const extractedResourceTypes = resourceTypesData
    ? resourceTypesData.allResourceTypes.edges.map((rt) => rt.node)
    : [];
  extractedResourceTypes.sort((a, b) =>
    a.resourcetypesName.localeCompare(b.resourcetypesName),
  );

  const extractedSources = sourcesData
    ? sourcesData.allSources.edges.map((s) => s.node)
    : [];
  extractedSources.sort((a, b) => a.sourcesName.localeCompare(b.sourcesName));

  const isEditMode =
    libraryResources?.length && Object.keys(selectedLibraryResources).length;

  const selectedResource = libraryResources?.find(
    (lr) => lr.resourcesId === Number(Object.keys(selectedLibraryResources)[0]),
  );

  const extractedStatuses = resourceStatusData
    ? resourceStatusData.allResourceStatus.edges.map((s) => s.node)
    : [];
  extractedStatuses.sort((a, b) => {
    return a.resourcestatusId === "Draft"
      ? -1
      : b.resourcestatusId === "Draft"
      ? 1
      : 0;
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [formValues, setFormValues] = useState<IUploadFormState>({
    object: selectedObjects[0].objectId as number,
    dataset: isEditMode
      ? selectedResource.resourcesSource.sourcesId
      : extractedSources[0]?.sourcesId || "",
    category: isEditMode
      ? selectedResource.resourcesResourcetype.resourcetypesId
      : extractedResourceTypes[0]?.resourcetypesId || "",
    description: isEditMode ? selectedResource.resourcesDescription : "",
    status: isEditMode
      ? selectedResource.resourcesStatus.resourcestatusId
      : extractedStatuses[0]?.resourcestatusId || "",
  });

  const isFileTypeValid = async (fileResult: File) => {
    let fileSignature: string = "";
    new Int8Array(await fileResult!.arrayBuffer())
      .slice(0, 2)
      .forEach(
        (decimal) => (fileSignature += decimal.toString(16).toUpperCase()),
      );
    return !noValidFileSignatures.includes(fileSignature);
  };

  const handleFileLoaded = async (fileResult) => {
    if (!(await isFileTypeValid(fileResult))) {
      enqueueSnackbar(i18n.t("noValidFileType"), { variant: "warning" });
      setFile(null);
      return;
    }
    setFile(fileResult);
  };

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user.hasWriteAccess && !user.isAdmin) {
      // TODO: consider snackbar here, needs i18n
      return;
    }
    setIsSubmitting(true);

    const formData = new FormData();
    const { object, description, category, dataset, status } = formValues;

    const currentDateTime = new Date();

    let vars = {
      // metadata: ,
      // uploaderId: ,
      description,
      name: file ? file.name : selectedResource.resourcesName,
      datecreated: currentDateTime,
      dateobtained: currentDateTime,
      object,
      source: dataset,
      resourcetype: category,
      resourcesId: null,
      status: status,
      resourcelocation: "1", // hardcoded to default value for now
    };

    if (isEditMode) {
      vars = { ...vars, resourcesId: selectedResource.resourcesId };
    }
    const variables = JSON.stringify({
      ...vars,
    });

    !isEditMode && file && formData.append(file.name, file, file.name);
    formData.append(
      "query",
      isEditMode
        ? getUpdateLibaryResourceMutation()
        : getCreateLibaryResourceMutation(),
    );
    formData.append("variables", variables);
    try {
      const response = await fetch(projectConfig?.graphql.url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer azuread-oauth2 ${localStorage.getItem(
            "idToken",
          )}`,
        },
      });

      const data = await response.json();
      if (data.errors) {
        data.errors.forEach((error) => {
          throw new Error(error.message);
        });
      } else {
        isEditMode
          ? enqueueSnackbar(i18n.t("success.fileEdit"), {
              variant: "success",
            })
          : enqueueSnackbar(i18n.t("success.fileUpload"), {
              variant: "success",
            });

        refetchLibraryResources();
        handleClose();
      }
    } catch (error) {
      console.log("EditAndUploadLibraryResources :: File upload error", error);
      setIsSubmitting(false);
      enqueueSnackbar(i18n.t("error.fileUploadGraphqlError"), {
        variant: "error",
      });
    }
  };
  const getObjectLabel = (object): string => {
    if (object.objectLayer?.objectlayerName === layers[2]?.objectlayerName)
      return object.objectName;

    return object.objectLongId;
  };

  const canSubmit =
    file !== null &&
    formValues.object &&
    formValues.dataset &&
    formValues.category &&
    formValues.status;

  return (
    <form onSubmit={handleSubmit}>
      <ModalContent>
        {isSubmitting ? (
          <div className={classes.spinner}>
            <CircularProgress size={64} />
          </div>
        ) : (
          <>
            <ModalHeader
              title={
                isEditMode
                  ? i18n.t("edit.modalHeader")
                  : i18n.t("uploadModalHeader")
              }
              handleClose={handleClose}
            />

            <div className={classes.formControl}>
              <label id="object">{i18n.t("object")} *</label>
              <Select
                aria-labelledby={i18n.t("object")}
                value={formValues.object}
                onChange={handleChange}
                className={classes.select}
                name="object"
                required
              >
                {selectedObjects &&
                  selectedObjects.map((object, i) => (
                    <MenuItem
                      key={object.objectId as number}
                      value={object.objectId as number}
                    >
                      {getObjectLabel(object)}
                    </MenuItem>
                  ))}
              </Select>
            </div>

            <div className={classes.formControl}>
              <label id="dataset">{i18n.t("dataset")} *</label>
              <Select
                aria-labelledby={i18n.t("dataset")}
                value={formValues.dataset}
                onChange={handleChange}
                className={classes.select}
                name="dataset"
                required
              >
                {extractedSources &&
                  extractedSources.map((source, i) => (
                    <MenuItem key={source.sourcesId} value={source.sourcesId}>
                      <Tooltip
                        title={
                          <Typography variant="body1">
                            {source.sourcesDescription}
                          </Typography>
                        }
                      >
                        <span className={classes.selectItem}>
                          {source.sourcesName}
                        </span>
                      </Tooltip>
                    </MenuItem>
                  ))}
              </Select>
            </div>

            <div className={classes.formControl}>
              <label id="category">{i18n.t("category")} *</label>
              <Select
                aria-labelledby={i18n.t("category")}
                value={formValues.category}
                onChange={handleChange}
                className={classes.select}
                name="category"
                required
              >
                {extractedResourceTypes.map((category) => (
                  <MenuItem
                    key={category.resourcetypesId}
                    value={category.resourcetypesId}
                  >
                    <Tooltip
                      title={
                        <Typography variant="body1">
                          {category.resourcetypesDescription}
                        </Typography>
                      }
                    >
                      <span className={classes.selectItem}>
                        {category.resourcetypesName}
                      </span>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className={classes.formControl}>
              <label id="description">{i18n.t("description")}</label>
              <TextField
                aria-labelledby={i18n.t("description")}
                value={formValues.description}
                onChange={handleChange}
                className={classes.textInput}
                name="description"
              />
            </div>

            <div className={classes.formControl}>
              <label id="status">{i18n.t("status")} *</label>
              <Select
                aria-labelledby={i18n.t("status")}
                value={formValues.status}
                onChange={handleChange}
                className={classes.select}
                name="status"
                required
              >
                {extractedStatuses.map((status) => (
                  <MenuItem
                    key={status.resourcestatusId}
                    value={status.resourcestatusId}
                  >
                    <Tooltip
                      title={
                        <Typography variant="body1">
                          {status.resourcestatusId}
                        </Typography>
                      }
                    >
                      <span className={classes.selectItem}>
                        {status.resourcestatusId}
                      </span>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </div>

            {!isEditMode && (
              <FileDrop
                handleFileLoaded={handleFileLoaded}
                selectedFile={file}
              />
            )}

            <ModalActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isEditMode ? false : !canSubmit}
              >
                {isEditMode ? i18n.t("edit.button") : i18n.t("upload")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                type="button"
              >
                {i18n.t("cancel")}
              </Button>
            </ModalActions>
          </>
        )}
      </ModalContent>
    </form>
  );
};

export default withStyles(styles)(EditAndUploadLibraryResources);
